import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { NextConsultationItem } from '@domain/hub/consultation.domain';
import { CustomerResponseItem } from '@domain/hub/customer.domain';
import { ClientTabTypes, RoutingPathMain } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { ImportService } from '@services/import-service/import.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { StorageService } from '@services/storage-service/storage.service';
import { color, libIcons } from 'bgzv-frontend-library';
import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment';
import 'moment/locale/de';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'screen-client-list',
  templateUrl: './screen-client-list.component.html',
  styleUrls: ['./screen-client-list.component.scss'],
})
export class ScreenClientListComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  private dialogRef = null;
  private dialogSub: Subscription;

  public tab = ClientTabTypes;
  public confirm = false;

  public searchControl: FormControl<string | null>;
  public searchQuery = '';

  public importInProgress = false;

  readonly color = color;
  readonly buttonIcon = libIcons;

  public origAllClients: CustomerResponseItem[];
  public allClients: CustomerResponseItem[];
  public origFavClients: CustomerResponseItem[];
  public favClients: CustomerResponseItem[];

  moment = moment;
  currentTab = this.tab.myClients;
  isFavourite = false;
  isExecutiveConsultant = false; // Executive Consultant

  constructor(
    private router: Router,
    private actionService: ActionService,
    private importService: ImportService,
    private clientService: ClientService,
    private queryService: QueryService,
    private dialogService: DialogService,
    private storageService: StorageService,
    private footerService: SharedFooterService
  ) {}

  ngOnInit(): void {
    moment.locale('de');
    this.storageService.remove('bgzvHubId');
    const actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'client-list') {
        if (action.action === 'update') {
          this.getCustomerListData();
        }
      }
    });

    const importSub = this.importService.importIsOngoing.subscribe(value => {
      this.importInProgress = value || false;
    });

    const refreshSub = this.importService.refreshRequest.subscribe(() => {
      this.getCustomerListData();
    });

    /// --- SEARCH FUNCTIONALITY --- ///
    this.searchControl = new FormControl<string | null>('');
    const searchSub = this.searchControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(query => {
        this.searchQuery = query;
        if (this.searchQuery) {
          this.allClients = this.origAllClients.filter(x =>
            x.corporationName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
          this.favClients = this.origFavClients.filter(x =>
            x.corporationName.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        } else {
          this.allClients = cloneDeep(this.origAllClients);
          this.favClients = cloneDeep(this.origFavClients);
        }
      });

    this.subscriptions.add(actionSub);
    this.subscriptions.add(refreshSub);
    this.subscriptions.add(searchSub);
    this.getCustomerListData();

    this.footerService.hideContents();

    if (this.clientService.fetchedTestStatus) {
      this.clientService.fetchedTestStatus = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  selectClientOption(clientData: CustomerResponseItem) {
    this.clientService.hasVatDiscount = clientData.vatDiscount || false;
    this.clientService.customerId = clientData.id;
    this.clientService.customerNumber = clientData.customerNumber;
    this.clientService.customerName = clientData.corporationName;
    this.router.navigate([RoutingPathMain.ClientConsultations]);
  }

  onCreateNewCustomer() {
    this.doAction('overlay-main', 'customer-new', { edit: false });
  }

  public getTestcafeId(id: number, name = '') {
    return `screenClientList-div-${id}-${name?.replace(/ /g, '')}`;
  }

  public getTestcafeButton(id: number, name = '') {
    return `screenClientList-div-${id}-${name?.replace(/ /g, '')}-more`;
  }
  // --------------------------------------------- //
  // -------------- HELPER FUNCTIONS ------------- //
  // --------------------------------------------- //

  public handleDateFormat(consultationData: NextConsultationItem) {
    if (consultationData.appointmentTime) {
      const time = consultationData.appointmentTime.split(':');
      return moment(consultationData.appointmentDate)
        .hour(parseInt(time[0], 10))
        .minute(parseInt(time[1], 10))
        .format('Do MMMM YYYY, HH:mm');
    } else {
      return moment(consultationData.appointmentDate).format('Do MMMM YYYY');
    }
  }

  // context menu methods for matMenu
  public setFav(setToFav: boolean, clientData) {
    let service = null;
    if (setToFav === true) {
      service = this.queryService.postCustomerToFavorite(clientData.id, this.clientService.bankConsultId);
    } else {
      service = this.queryService.deleteCustomerfromFavorite(clientData.id, this.clientService.bankConsultId);
    }

    service.subscribe(x => {
      this.getCustomerListData();
    });
  }

  public edit(clientData) {
    this.clientService.customerId = clientData.id;
    this.doAction('overlay-main', 'customer-new', { edit: true });
  }

  public deleteElement(clientData): void {
    this.queryService.deleteCustomerById(clientData.id).subscribe(x => {
      this.getCustomerListData();
    });
  }

  public confirmDeletion(clientData) {
    const data = {
      headingText: 'Kunden löschen',
      copyText: `Möchten Sie wirklich "${clientData.corporationName} (${clientData.customerNumber})" löschen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this.dialogRef = this.dialogService.openDialogConfirm(data);
    this.dialogSub = this.dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.deleteElement(clientData);
      }
      this.confirm = false;
      this.dialogRef = null;
    });
  }

  private getCustomerListData() {
    this.queryService.getAllCustomers().subscribe(data => {
      data.map(x => {
        if (x.logo) {
          x.logo = x.logo.includes('null') ? '' : x.logo;
        }
        return x;
      });

      this.origAllClients = data.sort((a, b) => {
        if (a.corporationName.toLowerCase() < b.corporationName.toLowerCase()) {
          return -1;
        }
        return 1;
      });
      this.allClients = cloneDeep(this.origAllClients);
      this.origFavClients = data.filter(x => x.isFavourite === true);
      this.favClients = cloneDeep(this.origFavClients);

      if (this.origFavClients.length === 0) {
        this.currentTab = this.tab.allClients;
      }
    });
  }

  onImportCustomer() {
    if (this.importInProgress) {
      return;
    }
    this.doAction('overlay-main', 'customer-import', { isNewUser: true, isFromClientList: true });
  }
}
