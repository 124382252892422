<aside #overlay class="position-relative d-flex h-100 w-100 bg-white" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <div *ngIf="displayMenuContent" class="d-flex flex-column mx-auto mt-4 overlay-search-container">
    <mat-progress-spinner *ngIf="!dataFetched" [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90">
    </mat-progress-spinner>

    <ng-container *ngIf="dataFetched">
      <h2>Suche</h2>
      <div class="mb-4">
        <mat-form-field>
          <mat-label>Suche nach Lösungen</mat-label>
          <input #searchInput type="search" [(ngModel)]="searchQuery" placeholder="Lösung" matInput />
          <ng-template #magnifier>
            <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
              <kf-icon name="ic_search_24"></kf-icon>
            </button>
          </ng-template>
          <button
            (click)="searchQuery = ''"
            *ngIf="searchQuery.length > 1; else magnifier"
            aria-label="Sucheingaben löschen"
            disableRipple
            mat-icon-button
            matSuffix
          >
            <kf-icon name="ic_clear_24"></kf-icon>
          </button>
        </mat-form-field>
      </div>
      <section class="d-flex flex-scroll search-result-lists">
        <search-question-world-column class="w-25" tabindex="-1"></search-question-world-column>
        <search-product-world-column class="w-25" tabindex="-1" *ngIf="!isInPrep"> </search-product-world-column>
        <search-task-world-column class="w-25" tabindex="-1" *ngIf="!isInPrep"> </search-task-world-column>
        <search-transition-world-column class="w-25" tabindex="-1" *ngIf="!isInPrep"> </search-transition-world-column>
      </section>
    </ng-container>
  </div>

  <button
    class="kf-popup-close"
    mat-icon-button
    aria-label="Dialog schließen"
    (click)="onClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>
