<div
  class="d-flex flex-column app-height align-items-center justify-content-start"
  (window:resize)="onResize($event)"
  [attr.data-html2canvas-ignore]="isScreenshotting || null"
>
  @if (!quickstartMode) {
    <marginals-header></marginals-header>

    <div class="d-flex position-relative w-100 h-100 content-container">
      @if (hasTopicData) {
        <div class="position-relative">
          <button
            [attr.data-html2canvas-ignore]="true"
            id="leftContentTrigger"
            type="button"
            class="panel-handle panel-handle--left left-content-trigger position-absolute"
            (click)="onAgendaPanelTrigger('toggle')"
            (keyup)="onAgendaPanelTrigger('toggle', $event)"
            [attr.data-testcafe]="'screenAgenda-div-leftContentTrigger'"
            [kfTooltip]="agendaPanelState === 'closed' ? 'Linkes Panel öffnen' : 'Linkes Panel schließen'"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            [kfTooltipHideOnInside]="true"
            [kfTooltipHideOnOutside]="true"
          >
            <span class="sr-only">
              {{ agendaPanelState === 'closed' ? 'Linkes Panel öffnen' : 'Linkes Panel schließen' }}
            </span>
            @if (agendaPanelState === 'closed') {
              <kf-icon name="ic_chevron_rechts_24"></kf-icon>
            } @else {
              <kf-icon name="ic_chevron_links_24"></kf-icon>
            }
          </button>

          <aside
            id="leftContent"
            class="d-flex position-relative left-content bg-white"
            [class.left-content-closed]="agendaPanelState === 'closed'"
          >
            <ng-template *ngTemplateOutlet="topicNavigation; context: { $implicit: agendaPanelState }"></ng-template>
          </aside>
        </div>
      }

      <div class="w-100 ml-5 mr-4 d-flex flex-column align-items-center topic-overview-screen">
        <h2
          class="d-flex py-3 my-0 w-100 flex-center text-center vr-color-brand-primary-alternative vr-headline-200 font-weight-bold topic-overview-title"
          [innerHTML]="titleText"
        ></h2>
        <div class="w-100 d-flex flex-center">
          <ng-template *ngTemplateOutlet="start"></ng-template>
        </div>
      </div>
    </div>
  }
</div>

<!-- All this should be part of config for the topic select -->
<ng-template #start>
  <!-- image in tile -->
  @if (!isSVG && tile) {
    <div class="boxed-content boxed-content-agenda">
      <kf-tile [interactive]="false">
        <div class="d-flex flex-center flex-column w-100 overflow-hidden">
          <div
            class="position-relative topic-select-image"
            [style.width.px]="imgSize?.width"
            [style.paddingBottom.px]="imgSize?.height"
            [style.marginBottom.px]="imgSize?.height * -1"
          >
            @for (posData of topicPosEnum | keyvalue; track posData.key) {
              @if (compareTopicPos(posData.value, topicPosEnum.logo)) {
                @if (compareTopicPos(posData.value, topicPosEnum.bank)) {
                  <ng-template *ngTemplateOutlet="position; context: { $implicit: posData.value }"></ng-template>
                } @else {
                  <ng-template *ngTemplateOutlet="bank"></ng-template>
                }
              } @else {
                <ng-template *ngTemplateOutlet="logo"></ng-template>
              }
            }
          </div>
          <img #image alt="" class="image-content" [src]="topicSelectImagePath" (load)="onImageLoaded($event)" />
        </div>
      </kf-tile>
    </div>
  }

  <!-- image without tile -->
  @if (!isSVG && !tile) {
    <div class="d-flex flex-center flex-column w-100 overflow-hidden">
      <div
        class="position-relative topic-select-image"
        [style.width.px]="imgSize?.width"
        [style.paddingBottom.px]="imgSize?.height"
        [style.marginBottom.px]="imgSize?.height * -1"
      >
        @for (posData of topicPosEnum | keyvalue; track posData.key) {
          @if (compareTopicPos(posData.value, topicPosEnum.logo)) {
            @if (compareTopicPos(posData.value, topicPosEnum.bank)) {
              <ng-template *ngTemplateOutlet="position; context: { $implicit: posData.value }"></ng-template>
            } @else {
              <ng-template *ngTemplateOutlet="bank"></ng-template>
            }
          } @else {
            <ng-template *ngTemplateOutlet="logo"></ng-template>
          }
        }
      </div>
      <img #image alt="" class="image-content" [src]="topicSelectImagePath" (load)="onImageLoaded($event)" />
    </div>
  }

  <!-- svg in tile -->
  @if (isSVG && !tile) {
    <div class="boxed-content boxed-content-agenda">
      <kf-tile [interactive]="false">
        <div class="d-flex flex-center flex-column w-100 overflow-hidden">
          <svg class="d-none">
            <style type="text/css">
              .svg-content svg {
                width: 100%;
                height: 100%;
              }
            </style>
            <style type="text/css" [innerHTML]="'.svg-content svg {max-height: ' + svgMaxHeight + 'px;}'"></style>
          </svg>

          <div class="svg-content" [innerHTML]="topicSelectImagePath | safeHtml" #svgContainer>
            <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
          </div>
        </div>
      </kf-tile>
    </div>
  }

  <!-- svg without tile -->
  @if (isSVG && !tile) {
    <svg class="d-none">
      <style type="text/css">
        .svg-content svg {
          width: 100%;
          height: 100%;
        }
      </style>
    </svg>

    <div class="svg-content" [innerHTML]="topicSelectImagePath | safeHtml" #svgContainer>
      <ng-template *ngTemplateOutlet="loadingTemplate"></ng-template>
    </div>
  }
</ng-template>

<ng-template #logo>
  <div
    class="d-flex position-absolute logo"
    id="logoContainer"
    [style.top.px]="getTopicPosTop(topicPosEnum.logo)"
    [style.left.px]="getTopicPosLeft(topicPosEnum.logo)"
  >
    @if (positionTopicMap.has(topicPosEnum.logo)) {
      <ng-template *ngTemplateOutlet="logoContent; context: { $implicit: positionTopicMap.get(topicPosEnum.logo) }">
      </ng-template>
    }
  </div>
</ng-template>

<ng-template #bank>
  <div
    class="d-flex position-absolute bank"
    id="bankContainer"
    [style.top.px]="getTopicPosTop(topicPosEnum.bank)"
    [style.left.px]="getTopicPosLeft(topicPosEnum.bank)"
  >
    @if (positionTopicMap.has(topicPosEnum.bank)) {
      <ng-template *ngTemplateOutlet="bankContent; context: { $implicit: positionTopicMap.get(topicPosEnum.bank) }">
      </ng-template>
    }
  </div>
</ng-template>

<ng-template #position let-position>
  @if (positionTopicMap.has(position)) {
    <div
      class="d-flex flex-center position-absolute topic"
      [style.top.px]="getTopicPosTop(position)"
      [style.left.px]="getTopicPosLeft(position)"
      [style.width.px]="getTopicWidth(position)"
      [style.height.px]="getTopicHeight(position)"
    >
      <ng-template *ngTemplateOutlet="topicContent; context: { $implicit: positionTopicMap.get(position) }">
      </ng-template>
    </div>
  }
</ng-template>

<ng-template #logoContent let-logoData>
  <button
    #content
    class="d-flex flex-column flex-center topic-select-box p-2 kf-bg cursor-pointer w-100 h-100"
    (click)="openTopicSelectMenu(logoData.id)"
    tabindex="1"
    kfTooltip="{{ customerName }} ({{ customerNumber }})"
    kfTooltipPosition="below"
    kfTooltipShow="hover_focus"
  >
    <img
      class="customer-logo"
      [style.height.px]="computeLogoHeight(topicPosEnum.logo)"
      [style.max-width.px]="computeLogoMaxWidth(topicPosEnum.logo)"
      [src]="customerLogo"
      alt="Logo der {{ customerName }}"
    />
  </button>
</ng-template>

<ng-template #bankContent let-bankData>
  <button
    #content
    class="d-flex flex-column flex-center topic-select-box p-2 kf-bg cursor-pointer w-100 h-100"
    (click)="openTopicSelectMenu(bankData.id)"
    tabindex="2"
    kfTooltip="{{ bankData.title }}"
    kfTooltipPosition="below"
    kfTooltipShow="hover_focus"
  >
    <img
      class="bank-logo-vr"
      [style.height.px]="computeLogoHeight(topicPosEnum.bank)"
      [style.max-width.px]="computeLogoMaxWidth(topicPosEnum.bank)"
      [src]="bankVrLogo"
      alt="Logo der {{ bankData.title }}"
    />
  </button>
</ng-template>

<ng-template #topicContent let-topicData>
  <button #content mat-stroked-button color="primary" (click)="openTopicSelectMenu(topicData.id)">
    <span>{{ topicData.title }}</span>
  </button>
</ng-template>

<ng-template #topicNavigation let-agendaPanelState>
  <agenda-navigation
    class="agenda-navigation"
    [class.side-footer-margin]="useMargin"
    [agendaPanelState]="agendaPanelState"
  ></agenda-navigation>
</ng-template>

<ng-template #loadingTemplate>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
