import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KfThemeModule } from '@app/kf-theme_module';
import { MaterialModule } from '@app/material_module';

import { BgzvComponentsModule } from '@components/components.module';
import { AgendaNavigationComponent } from '@marginals/agenda-navigation/agenda-navigation.component';
import { MarginalsHeaderComponent } from '@marginals/marginals-header/marginals-header.component';
import { SideAgendaEditComponent } from '@marginals/side-agenda-edit/side-agenda-edit.component';
import { SideChatComponent } from '@marginals/side-chat/side-chat.component';
import { SideCheckoutComponent } from '@marginals/side-checkout/side-checkout.component';
import { SideConsultationToggleComponent } from '@marginals/side-consultation-toggle/side-consultation-toggle.component';
import { SideNavigationComponent } from '@marginals/side-navigation/side-navigation.component';
import { SideNotesMenuComponent } from '@marginals/side-notes/side-notes-menu/side-notes-menu.component';
import { SideNotesComponent } from '@marginals/side-notes/side-notes.component';
import { SideProductDetailsComponent } from '@marginals/side-product-details/side-product-details.component';
import { SideProductExtrasComponent } from '@marginals/side-product-extras/side-product-extras.component';
import { ProfileDataFieldGroupListComponent } from '@marginals/side-profile/profile-data-field-group-list/profile-data-field-group-list.component';
import { ProfileDataFieldGroupPlainComponent } from '@marginals/side-profile/profile-data-field-group-plain/profile-data-field-group-plain.component';
import { ProfileDataFieldGroupTableComponent } from '@marginals/side-profile/profile-data-field-group-table/profile-data-field-group-table.component';
import { ProfileGroupNamePipe } from '@marginals/side-profile/profile-group-name.pipe';
import { SideProfileEditorComponent } from '@marginals/side-profile/side-profile-editor/side-profile-editor.component';
import { SideProfileComponent } from '@marginals/side-profile/side-profile.component';
import { SideTaskIndivComponent } from '@marginals/side-task-indiv/side-task-indiv.component';
import { SideTaskComponent } from '@marginals/side-task/side-task.component';
import { SideTransitionIndivComponent } from '@marginals/side-transition-indiv/side-transition-indiv.component';
import { SideTransitionComponent } from '@marginals/side-transition/side-transition.component';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SideRecommendationsDetailsComponent } from './side-recommendations-details/side-recommendations-details.component';
import { SideRecommendationsComponent } from './side-recommendations/side-recommendations.component';

@NgModule({
  declarations: [
    MarginalsHeaderComponent,
    ProfileDataFieldGroupListComponent,
    ProfileDataFieldGroupPlainComponent,
    ProfileDataFieldGroupTableComponent,
    ProfileGroupNamePipe,
    SideConsultationToggleComponent,
    SideNavigationComponent,
    SideProductDetailsComponent,
    SideProductExtrasComponent,
    SideProfileComponent,
    SideProfileEditorComponent,
    SideTaskComponent,
    SideTaskIndivComponent,
    SideTransitionComponent,
    SideCheckoutComponent,
    SideTransitionIndivComponent,
    SideChatComponent,
    SideNotesComponent,
    SideNotesMenuComponent,
    SideAgendaEditComponent,
    AgendaNavigationComponent,
    SideRecommendationsComponent,
    SideRecommendationsDetailsComponent,
  ],
  exports: [
    MarginalsHeaderComponent,
    SideConsultationToggleComponent,
    SideProductDetailsComponent,
    SideProductExtrasComponent,
    SideProfileComponent,
    SideProfileEditorComponent,
    SideTaskComponent,
    SideTaskIndivComponent,
    SideTransitionComponent,
    SideTransitionIndivComponent,
    SideCheckoutComponent,
    SideChatComponent,
    SideNotesComponent,
    SideAgendaEditComponent,
    AgendaNavigationComponent,
    SideRecommendationsComponent,
    SideRecommendationsDetailsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    KfThemeModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule.setOpts('de-DE'),

    BgzvLibraryModule,
    BgzvComponentsModule,
    BgzvUtilsModule,
  ],
  providers: [],
})
export class BgzvMarginalsModule {}
