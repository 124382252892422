<aside class="d-flex flex-column app-height align-items-center bg-white product-details-container-width">
  <header class="d-flex w-100 justify-content-between side-header">
    <div class="d-flex flex-grow-1 align-items-center">
      <div class="d-flex align-items-center">
        <button
          *ngIf="productInfo && !productDetails"
          [attr.data-testcafe]="'sideProductDetails-button-back'"
          (click)="onDataCancelled()"
          aria-label="Ansicht schließen"
          kfTooltip="Ansicht schließen"
          kfTooltipPosition="before"
          kfTooltipShow="hover_focus"
          class="mr-2"
          mat-icon-button
        >
          <kf-icon name="ic_chevron_links_24"></kf-icon>
        </button>

        <div class="d-flex flex-column">
          <ng-container *ngIf="dataFieldData?.compositionName">
            <h2
              class="text-truncate vr-headline-200 mb-0 side-heading"
              [kfTooltip]="dataFieldData?.compositionName.length > 50 ? dataFieldData.compositionName : null"
              kfTooltipPosition="below"
              kfTooltipShow="hover_focus"
            >
              {{ dataFieldData?.compositionName }}
            </h2>
            <ng-container *ngIf="productData?.name !== dataFieldData?.compositionName">
              <p class="text-truncate vr-text-regular mb-0">{{ productData?.name }}</p>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="!dataFieldData?.compositionName">
            <h2
              class="text-truncate vr-headline-200 mb-0 side-heading"
              [kfTooltip]="productData?.name.length > 50 ? productData.name : null"
              kfTooltipPosition="below"
              kfTooltipShow="hover_focus"
            >
              {{ productData?.name }}
            </h2>
          </ng-container>
        </div>
      </div>
    </div>
  </header>

  <ng-container *ngIf="productData; else loading">
    <ng-container *ngIf="state === productState.default">
      <ng-template *ngTemplateOutlet="default"></ng-template>
    </ng-container>
    <ng-container *ngIf="state === productState.customer">
      <ng-template *ngTemplateOutlet="customer"></ng-template>
    </ng-container>
  </ng-container>
  <button
    *ngIf="productDetails"
    [attr.data-testcafe]="'sideProductDetails-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="handleClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<!-- --------------------------------------- -->
<!-- -------------- TEMPLATES -------------- -->
<!-- --------------------------------------- -->

<!---HEADER TEMPLATE-->
<ng-template #productHeader let-mediaData>
  <ng-container *ngIf="documentCount > 0 || image.url">
    <div class="d-flex w-100">
      <div class="d-flex justify-content-end mr-5 side-container-left">
        <ng-container *ngIf="image">
          <div class="d-flex flex-grow-1 align-items-start justify-content-start">
            <bgzv-image
              class="d-flex detail-logo image-content"
              [alt]="image.altText"
              [src]="image.url"
              [width]="'80px'"
            ></bgzv-image>
          </div>
        </ng-container>
        <div *ngIf="!image" class="d-flex detail-logo image-content"></div>
      </div>
      <div class="d-flex side-container-right" *ngIf="documentCount > 0">
        <div class="mb-5">
          <h3 class="vr-headline-200 mb-1">Dokumente</h3>
          <div class="d-flex w-100">
            <div class="d-flex">
              <button
                *ngIf="documentCount > 0"
                [disabled]="isLoading"
                [matMenuTriggerFor]="menu"
                mat-stroked-button
                color="primary"
                [matBadge]="documentCount"
                matBadgeOverlap="true"
                matBadgePosition="above after"
                matBadgeColor="primary"
              >
                <kf-icon name="ic_datei_24"></kf-icon>
                <span>Dokumente</span>
              </button>
              <mat-menu #menu="matMenu">
                <ng-container class="d-flex flex-column" *ngFor="let document of documents; let last = last">
                  <button mat-menu-item (click)="showDocument(document, $event)">
                    {{ document.name + ' (' + document.type + ')' }}
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- -------- QUANTITY TEMPLATE -------- -->
<ng-template #quantityConfig>
  <ng-container *ngIf="productData.quantityVisibility">
    <div class="d-flex divider"></div>
    <div class="d-flex flex-column">
      <h4 class="vr-text-regular mb-2">{{ productData.quantityDescription || 'Anzahl' }}</h4>

      <ng-container>
        <vr-answer-number-input
          #productNum
          [defaultNumber]="
            !productData.selected ? (productRemoved ? 0 : productData.quantityDefaultValue) : productData.quantity
          "
          [testcafeData]="getTestcafeId(productData.name)"
          [minValue]="0"
          [maxValue]="productData.quantityMaxAmount ? productData.quantityMaxAmount : 100"
          [stepValue]="1"
          [keyboardInput]="false"
          [disabled]="isLoading"
          [productDetail]="true"
          size="56px"
          iconColor="#616161"
          [showRemoveIcon]="productData.selected"
          (currentNumberChanged)="setProductQuantity($event)"
        ></vr-answer-number-input>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- PRICING TEMPLATE -->
<ng-template #pricing>
  <ng-container *ngIf="productData.runningCost || productData.fixCost">
    <div class="d-flex divider"></div>
    <ng-container *ngIf="!productData.multiplePrices && productData.fixCost">
      <ng-template *ngTemplateOutlet="fixCost"></ng-template>
    </ng-container>
    <ng-container *ngIf="!productData.multiplePrices && productData.runningCost">
      <ng-template *ngTemplateOutlet="runningCost"></ng-template>
    </ng-container>
    <ng-container *ngIf="productData.multiplePrices">
      <ng-template *ngTemplateOutlet="multiplePrices"></ng-template>
    </ng-container>
  </ng-container>
</ng-template>

<!--MULTIPLE PRICES TEMPLATE-->
<ng-template #multiplePrices>
  <div class="d-flex w-100">
    <ng-template *ngTemplateOutlet="runningCost"></ng-template>
    <ng-template *ngTemplateOutlet="fixCost"></ng-template>
  </div>
</ng-template>

<!--SINGLE PRICE TEMPLATE-->
<ng-template #fixCost>
  <div class="d-flex flex-column w-100" [ngStyle]="{ width: productData.multiplePrices ? '50%' : '100%' }">
    <h4 class="d-flex mb-0 vr-text-regular">
      {{
        productData.fixCost?.paymentInterval === costTypeEnum.customOffer
          ? ''
          : (productData.fixCost?.paymentInterval | payInterval)
      }}
    </h4>
    <ng-container
      *ngIf="
        productData.fixCost?.netPrice &&
        productData.fixCost?.netPrice !== 0 &&
        productData.fixCost?.paymentInterval !== costTypeEnum.customOffer
      "
    >
      <div class="d-flex">
        <div class="d-flex vr-headline-300" markNegativePrice>
          {{ productData.fixCost?.netPrice.toFixed(2) | currency: 'EUR' : '' : '1.2-2' : 'de' }}
          <div class="vr-text-large ml-1">€</div>
        </div>
        <div
          class="d-flex vr-text-extrasmall ml-1"
          *ngIf="!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)"
        >
          1)
        </div>
        <div
          class="d-flex vr-text-extrasmall ml-1"
          *ngIf="productData.multiplePrices && productData.runningCost?.footnote && productData.fixCost?.footnote"
        >
          2)
        </div>
      </div>
      <div class="d-flex vr-text-extrasmall">{{ getTaxes(productData.fixCost?.vat) }}</div>
    </ng-container>
    <ng-container
      *ngIf="
        !productData.fixCost?.netPrice ||
        productData.fixCost?.netPrice === 0 ||
        productData.fixCost?.paymentInterval === costTypeEnum.customOffer
      "
    >
      <div class="d-flex">
        <div class="d-flex vr-headline-300">
          {{ productData.fixCost?.paymentInterval === costTypeEnum.customOffer ? 'Individuelles Angebot' : '0,00' }}
        </div>
        <div class="d-flex vr-text-large ml-1">
          {{ productData.fixCost?.paymentInterval === costTypeEnum.customOffer ? '' : '€' }}
        </div>
        <div
          class="d-flex vr-text-extrasmall ml-1"
          *ngIf="!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)"
        >
          1)
        </div>
        <div
          class="d-flex vr-text-extrasmall ml-1"
          *ngIf="productData.multiplePrices && productData.runningCost?.footnote && productData.fixCost?.footnote"
        >
          2)
        </div>
      </div>
      <div class="d-flex vr-text-extrasmall"></div>
    </ng-container>
  </div>
</ng-template>

<!--RUNNING COST-->
<ng-template #runningCost>
  <ng-container *ngIf="productData.fixCost?.paymentInterval !== costTypeEnum.customOffer">
    <div class="d-flex flex-column w-100" [ngStyle]="{ width: productData.multiplePrices ? '50%' : '100%' }">
      <h4 class="d-flex mb-0 vr-text-regular">
        {{ productData.runningCost?.paymentInterval | payInterval }}
      </h4>
      <ng-container>
        <div class="d-flex">
          <div class="d-flex vr-headline-300" markNegativePrice>
            {{
              !productData.runningCost
                ? '0,00'
                : (productData.runningCost.netPrice.toFixed(2) | currency: 'EUR' : '' : '1.2-2' : 'de')
            }}
            <div class="vr-text-large ml-1">€</div>
          </div>
          <div class="d-flex vr-text-extrasmall ml-1" *ngIf="productData.runningCost?.footnote">1)</div>
        </div>
        <div class="d-flex vr-text-extrasmall">{{ getTaxes(productData.runningCost?.vat) }}</div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- --- SOLUTION BASKET BUTTON TEMPLATE --- -->
<ng-template #solutionBasketButton>
  <div *ngIf="!inputValues.hideButton">
    <ng-container *ngIf="!productData.selected">
      <ng-template *ngTemplateOutlet="addToBasket"></ng-template>
    </ng-container>
    <ng-container *ngIf="productData.selected">
      <ng-template *ngTemplateOutlet="isInBasket"></ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #loadingButton>
  <mat-progress-spinner
    *ngIf="requestInProgress"
    color="color.find(color.name.BrandPrimary)"
    mode="indeterminate"
    class="m-auto"
    [diameter]="24"
  ></mat-progress-spinner>
</ng-template>

<!-- BASKET BUTTON COMPONENT TEMPLATES -->
<ng-template #addToBasket>
  <ng-container *ngTemplateOutlet="loadingButton"></ng-container>
  <button
    *ngIf="!requestInProgress"
    [disabled]="(currentQuantity === 0 && productData.quantityVisibility ? true : false) || isLoading"
    mat-flat-button
    color="primary"
    (click)="emitProductQuantity()"
  >
    <span>In den Lösungskorb legen</span>
  </button>
</ng-template>

<ng-template #isInBasket>
  <ng-container *ngTemplateOutlet="loadingButton"></ng-container>
  <ng-container *ngIf="!requestInProgress">
    <button [disabled]="isLoading" mat-flat-button color="primary" (click)="toggleContextMode()">
      <kf-icon name="ic_loeschen_24"></kf-icon>
      <span>Aus dem Lösungskorb entfernen</span>
    </button>
  </ng-container>
</ng-template>

<!--BENEFIT LIST-->
<ng-template #benefitsList>
  <div class="d-flex flex-column flex-grow-1 align-items-start justify-content-start side-container-right">
    <h3 class="vr-headline-200 mb-1">Vorteile</h3>
    <ng-container *ngIf="productData.benefits.length > 0">
      <div *ngFor="let benefit of productData.benefits">
        <article class="d-flex w-100 align-items-start justify-content-center mt-4">
          <div class="d-flex flex-column">
            <div
              class="vr-icon-benefit-{{ benefit.type.toLowerCase() }} icon-force-size-28px"
              [style.backgroundColor]="color.find(color.name.BrandPrimary)"
            ></div>
          </div>
          <div class="d-flex flex-column justify-content-center ml-3">
            <h4 class="vr-text-regular font-weight-bold">{{ benefit.name }}</h4>
            <div class="vr-text-regular" [innerHTML]="benefit.description | safeHtml"></div>
          </div>
        </article>
      </div>
    </ng-container>
  </div>
</ng-template>

<!--ANNOTATION LIST TEMPLATE-->
<ng-template #annotationList>
  <div class="d-flex flex-column w-100 py-4 align-items-start justify-content-start vr-text-extrasmall">
    <div class="d-flex" *ngIf="!productData.multiplePrices && productData.fixCost && productData.fixCost.footnote">
      <div class="mr-1">1)</div>
      <div [innerHTML]="productData.fixCost?.footnote | safeHtml"></div>
    </div>
    <div
      class="d-flex"
      *ngIf="!productData.multiplePrices && productData.runningCost && productData.runningCost.footnote"
    >
      <div class="mr-1">1)</div>
      <div [innerHTML]="productData.runningCost?.footnote | safeHtml"></div>
    </div>
    <div
      class="d-flex"
      *ngIf="
        productData.multiplePrices &&
        productData.fixCost?.paymentInterval !== costTypeEnum.customOffer &&
        productData.runningCost?.footnote
      "
    >
      <div class="d-flex vr-text-extrasmall mr-1" *ngIf="productData.multiplePrices">1)</div>
      <div class="d-flex vr-text-extrasmall mr-1" *ngIf="!productData.multiplePrices">1)</div>
      <div [innerHTML]="productData.runningCost?.footnote | safeHtml"></div>
    </div>
    <div
      class="d-flex"
      *ngIf="
        productData.multiplePrices &&
        productData.fixCost &&
        productData.fixCost?.paymentInterval !== costTypeEnum.customOffer &&
        productData.fixCost?.footnote
      "
    >
      <div
        class="d-flex vr-text-extrasmall mr-1"
        *ngIf="productData.multiplePrices && productData.runningCost?.footnote"
      >
        2)
      </div>
      <div
        class="d-flex vr-text-extrasmall mr-1"
        *ngIf="!productData.multiplePrices || (productData.multiplePrices && !productData.runningCost?.footnote)"
      >
        1)
      </div>
      <div [innerHTML]="productData.fixCost?.footnote | safeHtml"></div>
    </div>
  </div>
</ng-template>

<!-- -------------------------------------------- -->
<!-- ------------- DEFAULT TEMPLATES ------------ -->
<!-- -------------------------------------------- -->
<ng-template #default>
  <div class="d-flex flex-column w-100 side-container">
    <ng-template *ngTemplateOutlet="productHeader; context: { $implicit: image }"></ng-template>
    <div class="d-flex w-100">
      <!--LEFT CONTENT-->
      <div class="d-flex flex-column align-items-baseline justify-content-start mr-5 side-container-left">
        <h3 class="vr-headline-200 mb-1 sr-only">Produkt-Details</h3>
        <div class="mb-3 detail-description" [innerHTML]="productData.description | safeHtml"></div>

        <ng-container *ngIf="productData.selected">
          <div class="tag-info tag-positive">
            <div class="tag-info-text">Im Lösungskorb</div>
          </div>
        </ng-container>

        <!-- QUANTITY SELECTION -->
        <ng-template *ngTemplateOutlet="quantityConfig"></ng-template>

        <!--REVIEW OF COST-->
        <ng-template *ngTemplateOutlet="pricing"></ng-template>

        <!--ANNOTATIONS-->
        <ng-template *ngTemplateOutlet="annotationList"></ng-template>
      </div>

      <!--RIGHT CONTENT-->
      <ng-container *ngIf="productData.benefits.length > 0">
        <ng-template *ngTemplateOutlet="benefitsList"></ng-template>
      </ng-container>
    </div>
  </div>

  <footer
    class="w-100 border-top align-items-center side-footer"
    [class.side-footer-margin]="useMargin"
    *ngIf="!inputValues.hideButton || productData.selected"
  >
    <div class="h-100 button-row">
      <div class="button-row-content">
        <!--ADD CUSTOMER DATA-->
        <ng-container *ngIf="productData.selected && hasDataFieldData">
          <item-side-datafield-button
            [dataFieldData]="dataFieldData"
            (click)="onCustomerDataSelected()"
          ></item-side-datafield-button>
        </ng-container>

        <!--SOLUTION BASKET BUTTON-->
        <ng-template *ngTemplateOutlet="solutionBasketButton"></ng-template>
      </div>
    </div>
  </footer>
</ng-template>

<!-- -------------------------------------------- -->
<!-- ------------ CUSTOMER TEMPLATES ------------ -->
<!-- -------------------------------------------- -->

<ng-template #customer>
  <div class="d-flex flex-column w-100 side-container">
    <item-datafield-form
      #formItem
      [dataFieldData]="dataFieldData"
      [disableFields]="requestInProgress"
      [productData]="productData"
      [dataFieldElementType]="dataFieldElementTypeEnum.products"
      (valueChanged)="handleDataFormValueChange($event)"
      [showHeader]="false"
    ></item-datafield-form>
  </div>
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideProductDetails-button-completeData'"
          [disabled]="isLoading"
          mat-flat-button
          color="primary"
          (click)="onDataCancelled()"
        >
          <span>Daten ergänzen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
