import { AnswerOptionItem, OtherAnswerItem, OtherAnswers, UpdateAnswerItem } from '@domain/app/answers.domain';
import { QuestionGroupResponse, SyncedAnswers } from '@domain/app/question.domain';
import { RecommendationItem } from '@domain/app/recommendation.domain';
import { GivenAnswerEnum, QuestionEnum } from '@enums';
import { sortCriteria } from './screen-consultation.interfaces';

export const answerNullObject = (filter = GivenAnswerEnum.none) => [
  {
    id: '00000000-0000-0000-0000-000000000000',
    note: null,
    value: null,
    answeredBy: filter,
  },
];

// --------------------------------------------- //
// ----------- HANDLE QUESTIONS TYPES ---------- //
// --------------------------------------------- //
export function handleOtherAnswers(questionData: QuestionGroupResponse, map: Map<string, OtherAnswers>): void {
  map.clear();
  const questions = questionData.questions.slice();

  const initial = questions.map(x => {
    return {
      id: x.id,
      label: x.label,
      type: x.type,
      ans: x.answers?.filter(y => y.givenAnswers?.length > 0) || [],
    };
  });

  const initSync = questions
    .map(x => {
      return { id: x.id, label: x.label, type: x.type, ans: x.syncedAnswers };
    })
    .filter(y => y.ans?.length > 0);

  for (const q of initial) {
    const answers: OtherAnswers = {
      [GivenAnswerEnum.none]: [],
      [GivenAnswerEnum.customer]: [],
      [GivenAnswerEnum.consultant]: [],
      [GivenAnswerEnum.consultation]: [],
      [GivenAnswerEnum.history]: [],
    };

    for (const a of q.ans) {
      if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.customer)) {
        const value = otherAnswerTextFactory(q.type, GivenAnswerEnum.customer, a);
        answers[GivenAnswerEnum.customer].push(value);
      }
      if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.consultant)) {
        const value = otherAnswerTextFactory(q.type, GivenAnswerEnum.consultant, a);
        answers[GivenAnswerEnum.consultant].push(value);
      }
      if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.consultation)) {
        const value = otherAnswerTextFactory(q.type, GivenAnswerEnum.consultation, a);
        answers[GivenAnswerEnum.consultation].push(value);
      }
      if (a.givenAnswers.some(x => x.answeredBy === GivenAnswerEnum.history)) {
        const value = otherAnswerTextFactory(q.type, GivenAnswerEnum.history, a);
        answers[GivenAnswerEnum.history].push(value);
      }
    }

    if (q.ans.length > 0) {
      map.set(q.id, answers);
    }
  }
}

// --------------------------------------------- //
// ------------ FACTORIES / HELPERS ------------ //
// --------------------------------------------- //

export function answerObjectFactory(
  answerObj: AnswerOptionItem[],
  filter: GivenAnswerEnum | null = null
): UpdateAnswerItem[] {
  const sendObj = [];

  for (const answer of answerObj) {
    const thisAnswerId = answer.id;
    if (answer.givenAnswers.length > 0) {
      for (const givenAnswer of answer.givenAnswers) {
        const ansObj = {
          id: thisAnswerId,
          note: givenAnswer.note || null,
          value: givenAnswer.value || null,
          answeredBy: givenAnswer.answeredBy || GivenAnswerEnum.none,
        };
        sendObj.push(ansObj);
      }
    } else {
      const ansObj = {
        id: thisAnswerId,
        note: null,
        value: null,
        answeredBy: GivenAnswerEnum.none,
      };
      sendObj.push(ansObj);
    }
  }

  if (filter !== null) {
    const filtered = sendObj.filter(x => x.answeredBy === filter);
    if (filtered.length === 0) {
      return answerNullObject(filter);
    } else {
      return filtered;
    }
  }

  return sendObj;
}

export function otherAnswerTextFactory(
  type: QuestionEnum,
  answeredBy: GivenAnswerEnum,
  question: AnswerOptionItem
): OtherAnswerItem {
  if (
    type === QuestionEnum.slider ||
    type === QuestionEnum.expSlider ||
    type === QuestionEnum.conSlider ||
    type === QuestionEnum.number ||
    type === QuestionEnum.text
  ) {
    // filter must have a value, otherwise wou wouldn't be here
    const value = question.givenAnswers.filter(x => x.answeredBy === answeredBy)[0].value;
    return { id: question.id, label: question.label, value: value } as OtherAnswerItem;
  }

  if (question.hasTextField) {
    const note = question.givenAnswers.filter(x => x.answeredBy === answeredBy)[0].note;
    if (note) {
      return { id: question.id, label: question.label, note: note } as OtherAnswerItem;
    }
  }

  return { id: question.id, label: question.label } as OtherAnswerItem;
}

export function syncedAnswerTextFactory(a: SyncedAnswers): string[] {
  return a.values.map(x => {
    const note = x.note ? `: [${x.note}]` : '';
    return `${x.value}${note}`;
  });
}

export function sortRecommendationData(data: RecommendationItem[]): void {
  const cmp = (a: any, b: any) => {
    return a > b ? +1 : a < b ? -1 : 0;
  };

  data.sort((a, b) => {
    return cmp(a.compositionId, b.compositionId) || cmp(sortCriteria[a.type], sortCriteria[b.type]);
  });
}
