<div class="d-flex flex-column h-100 align-items-center bg-white">
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100 flex-center">
    <!-- ------- -->
    <!-- CONTENT -->
    <!-- ------- -->
    <div class="d-flex flex-center">
      <img
        style="min-width: 30rem"
        src="{{ assetPath }}/images/logo-bg2-standard.svg"
        alt="BankingGuide 2.0 - Digitale Beratungsmodule"
      />
    </div>
    <div *ngIf="loginHeader" class="d-flex flex-center vr-headline-300">{{ loginHeader }}</div>
    <div *ngIf="loginMessage" class="d-flex flex-center vr-text-regular">
      {{ loginMessage }}
    </div>
    <div class="my-3"></div>
    <ng-container *ngIf="loginState === 'defaultLogin'">
      <ng-template *ngTemplateOutlet="loginFormTemplate; context: { form: loginForm }"></ng-template>
    </ng-container>
    <ng-container *ngIf="loginState === 'requireNewPassword'">
      <ng-template *ngTemplateOutlet="passwordChangeFormTemplate; context: { form: passwordChangeForm }"></ng-template>
    </ng-container>
    <ng-container *ngIf="loginState === 'requireResetPassword'">
      <ng-template *ngTemplateOutlet="passwordResetFormTemplate; context: { form: passwordResetForm }"></ng-template>
    </ng-container>
    <div class="vr-text-extrasmall contact-description-box">
      Bei Interesse an einer Demonstration des BankingGuides wenden Sie sich an Ihre Ansprechpartner der DZ BANK,
      Fiducia GAD IT AG, BMS Group oder an
      <a href="mailto:kontakt@bankingguide.de?subject=Demonstration des BankingGuide">kontakt&#64;bankingguide.de</a>
    </div>
    <!-- ------- -->
    <!-- ------- -->
    <!-- ------- -->
  </div>
</div>

<!-- -------------------- LOGIN TEMPLATE -------------------- -->

<ng-template #loginFormTemplate let-form="form">
  <form [formGroup]="form" class="form-width" (ngSubmit)="handleLogin()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" formControlName="username" />
        <mat-error *ngIf="submitted && !!formUsername.errors">
          <kf-input-error *ngIf="!!formUsername.errors.required" [message]="'Benutzername ist notwendig'">
          </kf-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Passwort</mat-label>
        <input [type]="showPassword ? 'text' : 'password'" matInput minlength="8" formControlName="password" />
        <mat-error *ngIf="submitted && !!formPassword.errors">
          <kf-input-error *ngIf="!!formPassword.errors.required" [message]="'Passwort ist notwendig'"> </kf-input-error>
          <kf-input-error
            *ngIf="!!formPassword.errors.minlength"
            [message]="'Passwort muss mindestens 8 Zeichen enthalten'"
          >
          </kf-input-error>
        </mat-error>
        <ng-container>
          <button
            (click)="togglePassword()"
            *ngIf="!!formPassword.value && showPassword"
            class="infield-button"
            aria-label="Passwort verbergen"
            kfTooltip="Passwort verbergen"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            mat-icon-button
          >
            <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
          </button>

          <button
            (click)="togglePassword()"
            *ngIf="(!!formPassword.value && !showPassword) || (formPassword.value === '' && !showPassword)"
            class="infield-button"
            iconName="ic_auge_geschlossen_24"
            aria-label="Passwort anzeigen"
            kfTooltip="Passwort anzeigen"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            mat-icon-button
          >
            <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
          </button>
        </ng-container>
      </mat-form-field>
    </div>

    <!-- error message -->
    <div *ngIf="error" class="w-100 alert alert-danger mb-3">{{ error }}</div>

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [attr.data-testcafe]="'loginscreen-button-login'" [disabled]="loading" mat-flat-button color="primary">
        <mat-spinner [diameter]="24" color="primary" *ngIf="loading"></mat-spinner>
        <kf-icon name="ic_anmelden_24" *ngIf="!loading"></kf-icon>
        <span>Anmelden</span>
      </button>
    </div>
    <div class="w-100 d-flex justify-content-end mt-3">
      <button mat-button color="primary" (click)="loginState = 'requireResetPassword'; loginMessage = ''">
        <span>Passwort zurücksetzen</span>
      </button>
    </div>
  </form>
</ng-template>

<!-- -------------------- PASSWORD SET TEMPLATE -------------------- -->

<ng-template #passwordChangeFormTemplate let-form="form">
  <div class="vr-text-regular">Bitte ändern Sie ihr temporäres Passwort</div>
  <div class="vr-text-extrasmall mb-4">(mindestens 8 Zeichen)</div>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordChange()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" formControlName="username" />
        <mat-error *ngIf="submitted && !!formUsername.errors">
          <kf-input-error *ngIf="!!formUsername.errors.required" [message]="'Benutzername ist notwendig'">
          </kf-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Neues Passwort</mat-label>
        <input [type]="showPassword ? 'text' : 'password'" matInput minlength="8" formControlName="password" />
        <mat-error *ngIf="submitted && !!formChangePassword.errors">
          <kf-input-error *ngIf="!!formChangePassword.errors.required" [message]="'Passwort ist notwendig'">
          </kf-input-error>
          <kf-input-error
            *ngIf="!!formChangePassword.errors.minlength"
            [message]="'Passwort muss mindestens 8 Zeichen enthalten'"
          >
          </kf-input-error>
        </mat-error>
        <ng-container *ngIf="!!formChangePassword.errors">
          <button
            (click)="togglePassword()"
            *ngIf="!!formChangePassword.value && showPassword"
            class="infield-button"
            aria-label="Passwort verbergen"
            kfTooltip="Passwort verbergen"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            mat-icon-button
          >
            <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
          </button>
          <button
            (click)="togglePassword()"
            class="infield-button"
            *ngIf="(!!formChangePassword.value && !showPassword) || (formChangePassword.value === '' && !showPassword)"
            aria-label="Passwort anzeigen"
            kfTooltip="Passwort anzeigen"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            mat-icon-button
          >
            <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
          </button>
        </ng-container>
      </mat-form-field>
    </div>

    <!-- error message -->
    <div *ngIf="error" class="w-100 alert alert-danger mb-3">{{ error }}</div>

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [disabled]="loading" mat-flat-button color="primary">
        <mat-spinner *ngIf="loading" [diameter]="24" color="primary"></mat-spinner>
        <span>Passwort ändern</span>
      </button>
    </div>
  </form>
</ng-template>

<!-- -------------------- PASSWORD CHANGE TEMPLATE -------------------- -->

<ng-template #passwordResetFormTemplate let-form="form">
  <div class="vr-text-regular mb-2 mt-n4">
    Sollten Sie Ihr Passwort vergessen haben wenden sie sich an das BG ZV Team<br />um Ihr Passwort zurückzusetzen -
    <a href="mailto:pilotierung.bankingguide@bms-consulting.de?subject=Bitte Passwort zurücksetzen"
      >pilotierung.bankingguide&#64;bms-consulting.de</a
    >
  </div>
  <div class="vr-text-regular">Falls sie schon einen Verifizierungscode erhalten haben</div>
  <div class="vr-text-regular">geben Sie den Verifizierungscode und Ihr neues Passwort ein.</div>
  <div class="vr-text-extrasmall mb-4">(mindestens 8 Zeichen)</div>
  <form [formGroup]="form" class="form-width" (ngSubmit)="handlePasswordReset()">
    <!-- username -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>E-Mail-Adresse</mat-label>
        <input type="email" matInput minlength="8" formControlName="username" />
        <mat-error *ngIf="submitted && !!formResetUsername.errors">
          <kf-input-error *ngIf="!!formResetUsername.errors.required" [message]="'Benutzername ist notwendig'">
          </kf-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- code -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Verifizierungscode</mat-label>
        <input type="text" matInput formControlName="code" />
        <mat-error *ngIf="submitted && !!formCode.errors">
          <kf-input-error *ngIf="!!formCode.errors.required" [message]="'Code ist notwendig'"> </kf-input-error>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- password -->
    <div class="form-group">
      <mat-form-field class="notranslate">
        <mat-label>Passwort</mat-label>
        <input
          [type]="showPassword ? 'text' : 'password'"
          autocomplete="off"
          matInput
          minlength="8"
          formControlName="password"
        />
        <mat-error *ngIf="submitted && !!formResetPassword.errors">
          <kf-input-error *ngIf="!!formResetPassword.errors.required" [message]="'Passwort ist notwendig'">
          </kf-input-error>
          <kf-input-error
            *ngIf="!!formResetPassword.errors.minlength"
            [message]="'Passwort muss mindestens 8 Zeichen enthalten'"
          >
          </kf-input-error>
        </mat-error>
      </mat-form-field>

      <button
        (click)="togglePassword()"
        *ngIf="!!formResetPassword.value && showPassword"
        class="infield-button"
        aria-label="Passwort verbergen"
        kfTooltip="Passwort verbergen"
        kfTooltipPosition="after"
        kfTooltipShow="hover_focus"
        mat-icon-button
      >
        <kf-icon name="ic_auge_geoeffnet_24"></kf-icon>
      </button>

      <button
        (click)="togglePassword()"
        *ngIf="(!!formResetPassword.value && !showPassword) || (formResetPassword.value === '' && !showPassword)"
        class="infield-button"
        aria-label="Passwort anzeigen"
        kfTooltip="Passwort anzeigen"
        kfTooltipPosition="after"
        kfTooltipShow="hover_focus"
        mat-icon-button
      >
        <kf-icon name="ic_auge_geschlossen_24"></kf-icon>
      </button>

      <div *ngIf="submitted && !!formResetPassword.errors" class="invalid-feedback">
        <div *ngIf="!!formResetPassword.errors.required">Passwort ist notwending</div>
        <div *ngIf="!!formResetPassword.errors.minlength">Passwort muss mindestens 8 Zeichen enthalten</div>
      </div>
    </div>

    <!-- error message -->
    <div *ngIf="error" class="w-100 alert alert-danger mb-3">{{ error }}</div>

    <!-- buttons -->
    <div class="w-100 d-flex justify-content-end">
      <button [disabled]="loading" mat-flat-button color="primary">
        <mat-spinner [diameter]="24" color="primary" *ngIf="loading"></mat-spinner>
        <span>Passwort erneuern</span>
      </button>
    </div>
    <div class="w-100 d-flex justify-content-end mt-3">
      <button mat-button color="primary" (click)="loginState = 'defaultLogin'; loginMessage = ''">
        <span>Zum Login</span>
      </button>
    </div>
  </form>
</ng-template>
