import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import {
  SubtopicOverviewItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { ConsultationStatusEnum, PanelStates, RoutingPathMain, RoutingPathPrep, TopicStatusEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { SearchService } from '@services/search-service/search.service';
import { TopicService } from '@services/topic-service/topic-service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject, Subscription, lastValueFrom, timer } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

interface SelectedTopicIds {
  consultant: string[];
  customer: string[];
  consultation: string[];
}
@Component({
  selector: 'agenda-navigation',
  templateUrl: './agenda-navigation.component.html',
  styleUrls: ['./agenda-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgendaNavigationComponent implements OnInit, AfterViewChecked, OnChanges {
  @ViewChild('agendaNavigationComponent') agendaNavigationComponent: ElementRef;
  @Input() agendaPanelState: PanelStates;
  @Input() subtopicId: number;
  @Input() selectedSubtopicIds: SelectedTopicIds = {
    consultant: [],
    customer: [],
    consultation: [],
  };

  readonly consultationStatusEnum = ConsultationStatusEnum;
  readonly topicStatusEnum = TopicStatusEnum;
  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly scrollOffsetPx = 30;

  private _dialogSub: Subscription;
  private _dialogRef = null;
  private subAlive: Subject<void> = new Subject<void>();
  private topicData: TopicOverviewItem[];
  private scroll = true;
  private selectedSubtopics = {
    consultant: [],
    customer: [],
    consultation: [],
  };

  public loading = true;
  public intersectingId = 0;
  public showPrelimSent = false;

  public panelStates = PanelStates;

  public consultationSubtopics: TopicSelectionResponse;
  public consultantSubtopics: TopicSelectionResponse;
  public customerSubtopics: TopicSelectionResponse;

  public scrollTop = false;
  public scrollBottom = false;

  public mediaIcons: Map<string, string> = new Map<string, string>();

  constructor(
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly actionService: ActionService,
    private readonly searchService: SearchService,
    private queryService: QueryService,
    private clientService: ClientService,
    private dialogService: DialogService,
    private topicService: TopicService,
    private mediaService: MediaService,
    private chg: ChangeDetectorRef,
    private configService: ConfigService
  ) {}

  async ngOnInit() {
    // subscriptions NEED to be added BEFORE any await calls
    this.actionService.action.pipe(takeUntil(this.subAlive)).subscribe(async action => {
      if (action && action.target === 'agenda') {
        if (action.action === 'subtopics') {
          this.selectedSubtopics.consultant = action.options.consultantSubtopics;
          this.selectedSubtopics.consultation = action.options.consultationSubtopics;
          this.selectedSubtopics.customer = action.options.customerSubtopics;

          const originalStructure = {
            available: [],
            selected: [
              ...this.selectedSubtopics.consultant,
              ...this.selectedSubtopics.consultation,
              ...this.selectedSubtopics.customer,
            ],
          };
          await this.mapSubtopicIcons(originalStructure);

          if (
            !this.selectedSubtopics.consultation.find(x => x.id === this.subtopicId) &&
            !this.selectedSubtopics.consultant.find(x => x.id === this.subtopicId) &&
            !this.selectedSubtopics.customer.find(x => x.id === this.subtopicId) &&
            this.subtopicId !== undefined
          ) {
            if (this.consultationStatus !== this.consultationStatusEnum.mainConsultation) {
              this.router.navigate([`${RoutingPathPrep.TopicOverview}/${this.clientService.bankHubId}`]);
            } else {
              this.router.navigate([`${RoutingPathMain.TopicOverview}/${this.clientService.bankHubId}`]);
            }
          }

          this.chg.detectChanges();
        } else if (action.action === 'prelim-id') {
          this.selectedSubtopics.customer.filter(x => x.id === action.options.subtopicId)[0].selectedForPreliminary =
            action.options.selected;
        } else if (action.action === 'prelim-question') {
          this.selectedSubtopics.customer.find(
            x => x.questionGroups[0].id === action.options.questionGroupId
          ).selectedForPreliminary = action.options.selected;
        } else if (action.action === 'prelim-finish') {
          elem.scrollTop += 1;
          this.showPrelimSent = true;
        } else if (action.action === 'callInit') {
          action.action = null;
          this.ngOnInit();
        } else if (action.action === 'refresh') {
          this.requestSubtopicData();
          this.topicService.selectedTopicsData = this.topicData.filter(x => x.selected);
        }

        this.scrollButtonHandler(elem);
      }
    });

    //if we're here, this would be a created consultation
    if (this.clientService.consultationStatus === ConsultationStatusEnum.created) {
      console.log(`%c [bgzv-frontend-main] - consultation status still [CREATED]`, 'color: #0066cc');
      await this.setConsultationStatus(ConsultationStatusEnum.inPreparation);
    }

    await this.requestSubtopicData();
    this.topicData = await lastValueFrom(this.searchService.fetchData());
    this.topicService.selectedTopicsData = this.topicData.filter(x => x.selected);

    if (this.consultationStatus === this.consultationStatusEnum.preparationSent) {
      this.showPrelimSent = true;
    }

    const elem = this.agendaNavigationComponent?.nativeElement;
    elem.scrollTop = 0;

    this.scrollButtonHandler(elem);
    this.chg.detectChanges();
  }

  ngAfterViewChecked() {
    if (this.scroll) {
      this.scroll = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.subtopicId) {
      this.subtopicId = changes.subtopicId.currentValue;
      this.handleTopicJump(this.subtopicId);
    }
  }

  ngOnDestroy(): void {
    this.subAlive.next();
    this.subAlive.complete();
    this._dialogSub?.unsubscribe();
    this.contextService.agendaPanelState = PanelStates.open;
  }

  public scrollButtonHandler(target) {
    const t = timer(50).subscribe(() => {
      const oHeight = target.offsetHeight;
      const pos = target.scrollTop + oHeight;
      const max = target.scrollHeight;
      const thd = target.offsetHeight / max < 1;

      this.scrollBottom = thd && max > pos;
      this.scrollTop = thd && pos > max;

      this.chg.detectChanges();
      t.unsubscribe();
    });
  }

  public async toggleContextMode(ev: Event): Promise<void> {
    if (this.selectedSubtopics.consultation.length === 0 && !this.clientService.prepDataSent) {
      const data = {
        copyText: 'Bitte Beratungsthemen auswählen',
        denyText: 'Schließen',
        target: ev.target,
        context: 'noTopic',
      } as DialogConfirmData;

      this._dialogRef = this.dialogService.openDialogConfirm(data);

      this._dialogSub = this._dialogRef.afterClosed().subscribe(result => {
        this._dialogRef = null;
      });
    } else if (
      ((this.clientService.consultationStatus === ConsultationStatusEnum.created ||
        this.clientService.consultationStatus === ConsultationStatusEnum.inPreparation) &&
        !this.clientService.prepDataSent) ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
    ) {
      const copyText =
        this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent
          ? 'Hiermit wird die Vorbefragung beendet. Beratung starten?'
          : 'Ohne Vorbefragung fortfahren?';
      const data = {
        copyText: copyText,
        confirmText: ' Ja ',
        denyText: 'Nein',
        target: ev.target,
      } as DialogConfirmData;

      this._dialogRef = this.dialogService.openDialogConfirm(data);
      this._dialogSub = this._dialogRef.afterClosed().subscribe(async result => {
        if (result?.confirmed === true) {
          await this.setConsultationStatus(ConsultationStatusEnum.mainConsultation);
          this.contextService.lastQuestionGroupId.next('-1');
          this.contextService.prepToggleMode = 'main';
          if (this.startingPointMainConsultation) {
            this.router.navigate([RoutingPathMain.Consultation]);
          }

          setTimeout(() => {
            this.handleClose();
          }, 350);
        }
        this._dialogRef = null;
      });
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    let pos = event.target.scrollTop + event.target.offsetHeight;
    const max = event.target.scrollHeight;

    if (pos + this.scrollOffsetPx < max) {
      this.scrollBottom = true;
    } else {
      this.scrollBottom = false;
    }

    if (event.target.scrollTop > this.scrollOffsetPx) {
      this.scrollTop = true;
    } else {
      this.scrollTop = false;
    }
  }

  public onScrollToBottom(): void {
    const elm = this.agendaNavigationComponent?.nativeElement;
    const max = elm.scrollHeight;
    elm.scroll({ top: max, behavior: 'smooth' });
  }

  public onScrollToTop(): void {
    const elm = this.agendaNavigationComponent?.nativeElement;
    elm.scroll({ top: 0, behavior: 'smooth' });
  }

  public doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public getTestcafeId(id = '') {
    return `${id?.replace(/ /g, '')}`;
  }

  public jumpToQuestionGroupSearch(item: SubtopicOverviewItem) {
    const validId = item.questionGroups.find(x => !x.hidden).id;
    if (item?.questionGroups.length > 0) {
      this.actionService
        .setAction({ target: 'overlay-main', action: 'close' })
        .then(() => {
          if (
            this.contextService.currentMode === 'prep' &&
            this.contextService.currentMainContext !== RoutingPathPrep.Consultation
          ) {
            return this.router.navigate([RoutingPathPrep.Consultation], {
              state: { questionGroupId: validId },
            });
          } else if (
            this.contextService.currentMode === 'main' &&
            this.contextService.currentMainContext !== RoutingPathMain.Consultation
          ) {
            return this.router.navigate([RoutingPathMain.Consultation], {
              state: { questionGroupId: validId },
            });
          } else {
            return this.actionService.setAction({
              target: 'consultation',
              action: 'jump',
              options: { questionGroupId: validId },
            });
          }
        })
        .then(() => {
          this.scroll = true;
          this.handleTopicJump(item.id);
          this.contextService.agendaPanelState = PanelStates.open;
        });
    }
  }

  public getMediaIcon(id: string): string {
    return this.mediaIcons?.get(id) || '';
  }

  private handleTopicJump(subtopicId) {
    let element,
      counter = 0;

    const interval = setInterval(() => {
      if (counter < 10 && !element) {
        element = document.querySelector(`#main_${subtopicId}`) as HTMLElement;
        counter++;
      } else {
        window.clearInterval(interval);

        if (!!element) {
          const { top } = this.agendaNavigationComponent!.nativeElement!.getBoundingClientRect();
          const { y } = element!.getBoundingClientRect();
          this.agendaNavigationComponent.nativeElement.scrollBy(0, y - top);
        }
      }
    }, 350);
  }

  private async requestSubtopicData(): Promise<void> {
    const subtopics = await lastValueFrom(
      this.queryService.getSubtopicDataByConsultationId(this.clientService.consultationId)
    );

    await this.mapSubtopicIcons(subtopics);

    this.consultationSubtopics = this.topicService.consultationSubtopics(subtopics);
    this.selectedSubtopics.consultation = this.consultationSubtopics.selected;
    this.consultantSubtopics = this.topicService.consultantSubtopics(subtopics);
    this.selectedSubtopics.consultant = this.consultantSubtopics.selected;
    this.customerSubtopics = this.topicService.customerSubtopics(subtopics);
    this.selectedSubtopics.customer = this.customerSubtopics.selected;

    this.selectedSubtopicIds.consultation = this.topicService.consultationSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.consultant = this.topicService.consultantSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.customer = this.topicService.customerSelectedSubtopicIds(subtopics);
    this.chg.detectChanges();
  }

  private async setConsultationStatus(status: ConsultationStatusEnum): Promise<void> {
    await lastValueFrom(
      this.queryService.putConsultationStatus(this.clientService.consultationId, { consultationStatus: status })
    );
    this.clientService.consultationStatus = status;
  }

  private async mapSubtopicIcons(subtopic: SubtopicSelectionResponse): Promise<void> {
    subtopic.selected?.forEach(async x => {
      if (x.icon?.id) {
        this.mediaIcons.set(x.icon?.id, await this.mediaService.getMediaContent(x.icon?.url));
      }
    });
  }

  private handleClose(): void {
    if (this.contextService.currentMode !== this.contextService.prepToggleMode) {
      this.router.navigate([`${RoutingPathMain.TopicOverview}/${this.clientService.bankHubId}`]);
    }
  }

  ///////////////////////////////////////////////////
  //***********************************************//
  //********************GETTER*********************//
  //***********************************************//
  ///////////////////////////////////////////////////

  get consultationStatus() {
    return this.clientService.consultationStatus;
  }

  get hasConsultantTopic() {
    return this.selectedSubtopics.consultant;
  }

  get hasCustomerTopic() {
    return this.selectedSubtopics.customer;
  }

  get hasConsultationTopic() {
    return this.selectedSubtopics.consultation;
  }

  get rootDiv() {
    return document.querySelector(`#agendaNavigationComponent`) as HTMLElement;
  }

  get startingPointMainConsultation(): boolean {
    return this.configService.startingPointMainConsultation;
  }

  get showEditButton(): boolean {
    if (
      this.startingPointMainConsultation &&
      this.consultationStatus === this.consultationStatusEnum.mainConsultation
    ) {
      return false;
    }
    return true;
  }
}
