import { Injectable } from '@angular/core';
import { CustomerContactResponseItem } from '@domain/app/contact.domain';
import { ConsultationStatusEnum } from '@enums';
import { QueryService } from '@services/query-service/query.service';
import { StorageService } from '@services/storage-service/storage.service';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(
    private queryService: QueryService,
    private storageService: StorageService
  ) {}

  private _instanceId: string;
  private _instanceVersion: string;

  public instanceIdChanged = new ReplaySubject<string>();
  public numInCart = new Subject<number>();
  public vatDiscountChanged = new Subject<boolean>();
  private _customerId: number;
  private _customerName: string;
  private _customerLogo: string;
  private _customerNumber: string;
  private _consultationName: string;
  private _consultationDate: string;
  private _consultationTime: string;

  private _consultationId: string;
  private _consultationStatus: ConsultationStatusEnum;
  private _appointmentUUID: string;
  private _bankConsultId: number;
  private _bankExternalId: string;
  private _bankHubId: string;
  private _bankConsultEmail: string;
  private _numSolutions = -1;
  private _prepDataSent = false;
  private _hasVatDiscount = false;
  public isTestConsultation = false;
  public fetchedTestStatus = false;

  private _sessionContacts: CustomerContactResponseItem[] = [];

  public get instanceId(): string {
    return this._instanceId;
  }
  public set instanceId(value: string) {
    if (this._instanceId !== value) {
      this._instanceId = value;
      this.instanceIdChanged.next(value);
    }
  }

  public get instanceVersion(): string {
    return this._instanceVersion;
  }
  public set instanceVersion(value: string) {
    this._instanceVersion = value;
  }

  public get customerId() {
    return this._customerId;
  }
  public set customerId(value) {
    this._customerId = value;
  }

  public get appointmentUUID(): string {
    return this._appointmentUUID;
  }
  public set appointmentUUID(value: string) {
    this._appointmentUUID = value;
  }

  public get customerName() {
    return this._customerName;
  }
  public set customerName(value) {
    this._customerName = value;
  }

  public get customerLogo(): string {
    return this._customerLogo;
  }
  public set customerLogo(value: string) {
    this._customerLogo = value;
  }

  public get customerNumber(): string {
    return this._customerNumber;
  }
  public set customerNumber(value: string) {
    this._customerNumber = value;
  }

  public get consultationName(): string {
    return this._consultationName;
  }
  public set consultationName(value: string) {
    const componentItem = sessionStorage.getItem('component');
    const componentObject = JSON.parse(componentItem);
    const title = componentObject?.data['kbm-gespraech-durchfuehrung']?.gespraechstermin?.titel || null;

    this._consultationName = title ?? value;
  }

  public get consultationDate(): string {
    return this._consultationDate;
  }
  public set consultationDate(value: string) {
    this._consultationDate = value;
  }

  public get consultationTime(): string {
    return this._consultationTime;
  }
  public set consultationTime(value: string) {
    this._consultationTime = value;
  }

  public get consultationId(): string {
    return this._consultationId;
  }
  public set consultationId(value: string) {
    this._consultationId = value;
  }

  public get consultationStatus(): ConsultationStatusEnum {
    return this._consultationStatus;
  }
  public set consultationStatus(value: ConsultationStatusEnum) {
    this.prepDataSent = value !== ConsultationStatusEnum.created && value !== ConsultationStatusEnum.inPreparation;
    this._consultationStatus = value;
  }

  public get bankConsultId(): number {
    return this._bankConsultId;
  }
  public set bankConsultId(value: number) {
    this._bankConsultId = value;
  }

  public get bankHubId(): string {
    return this._bankHubId;
  }
  public set bankHubId(value: string) {
    this.storageService.set('bgzvHubId', value);
    this._bankHubId = value;
  }

  public get bankExternalId(): string {
    return this._bankExternalId;
  }
  public set bankExternalId(value: string) {
    this._bankExternalId = value;
  }

  public get bankConsultEmail(): string {
    return this._bankConsultEmail;
  }
  public set bankConsultEmail(value: string) {
    this._bankConsultEmail = value;
  }

  public get numSolutions(): number {
    return this._numSolutions;
  }
  public set numSolutions(value: number) {
    this._numSolutions = value;
    this.numInCart.next(value);
  }

  public get prepDataSent(): boolean {
    return this._prepDataSent;
  }
  public set prepDataSent(value: boolean) {
    this._prepDataSent = value;
  }

  public get hasVatDiscount() {
    return this._hasVatDiscount;
  }
  public set hasVatDiscount(value) {
    const emitSubject = this._hasVatDiscount !== value;
    this._hasVatDiscount = value;
    emitSubject && this.vatDiscountChanged.next(value);
  }

  public get sessionContacts(): CustomerContactResponseItem[] {
    return this._sessionContacts;
  }

  public set sessionContacts(value: CustomerContactResponseItem[]) {
    this._sessionContacts = value;
  }

  public addSessionContact(data: CustomerContactResponseItem) {
    const result = this.sessionContacts.find(x => x.firstName === data.firstName && x.lastName === data.lastName);

    if (!result) {
      this.sessionContacts.push(data);
    } else {
      Object.keys(data).map(x => {
        result[x] = data[x];
      });
    }
  }

  public removeSessionContact(data: CustomerContactResponseItem) {
    this.sessionContacts = this.sessionContacts.filter(
      x => x.firstName !== data.firstName && x.lastName !== data.lastName
    );
  }

  public get consultationStatusType() {
    if (
      this.consultationStatus === ConsultationStatusEnum.created ||
      this.consultationStatus === ConsultationStatusEnum.inPreparation ||
      this.consultationStatus === ConsultationStatusEnum.preparationSent ||
      this.consultationStatus === ConsultationStatusEnum.preparationDone
    ) {
      return 'prep';
    } else if (this.consultationStatus === ConsultationStatusEnum.mainConsultation) {
      return 'main';
    } else {
      return 'done';
    }
  }

  public setSolutionBasketCount() {
    this.queryService.getSelectedItemsCount(this.consultationId).subscribe(data => {
      this.numSolutions = data?.count || 0;
    });
  }
}
