import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckoutDataFieldCompositionItem } from '@domain/app/checkout.domain';
import { DataFieldStatusEnum } from '@enums';
import { color, libIcons } from 'bgzv-frontend-library';

@Component({
  selector: 'item-side-datafield-button',
  templateUrl: './item-side-datafield-button.component.html',
  styleUrls: ['./item-side-datafield-button.component.scss'],
})
export class ItemSideDatafieldButtonComponent implements OnInit {
  @Input() dataFieldData: CheckoutDataFieldCompositionItem;
  @Output() click = new EventEmitter<Event>();

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor() {}

  ngOnInit(): void {}

  public onClick(ev: Event): void {
    this.click.emit(ev);
  }

  get formComplete(): boolean {
    return this.dataFieldData.dataFieldStatus === DataFieldStatusEnum.complete;
  }

  get formMandatoryDone(): boolean {
    return this.dataFieldData.dataFieldStatus === DataFieldStatusEnum.mandatoryDone;
  }

  get formNotDone(): boolean {
    return this.dataFieldData.dataFieldStatus === DataFieldStatusEnum.notComplete;
  }
}
