<div class="d-flex flex-column menu-product-world-column">
  <h3 *ngIf="productWorldData" class="vr-text-regular font-weight-bold mt-5 mb-4">
    {{ searchQuery ? 'Expertengespräch' : 'Favoriten Expertengespräche' }}
  </h3>
  <ng-container *ngIf="productWorldData && productWorldData.length > 0">
    <button
      class="mb-2 vr-text-regular questionBorder border-shadow"
      [style.backgroundColor]="color.find(color.name.White)"
      [style.color]="color.find(color.name.BrandPrimary)"
      *ngFor="let compositionElement of productWorldData"
      (click)="compositionElementClicked(compositionElement)"
    >
      <div class="recommendation-container mb-3">
        {{ handleProductName(compositionElement) }}
      </div>
    </button>
  </ng-container>
  <p *ngIf="productWorldData && productWorldData.length === 0" class="mt-3">Keine passende Lösung gefunden.</p>
</div>
