<marginals-header></marginals-header>

<div class="boxed-content w-header-w-footer d-flex flex-column flex-scroll flex-grow-1">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mt-4">
          <button mat-button color="primary" (click)="backToSubtopic()">
            <kf-icon name="ic_pfeil_links_24"></kf-icon>
            <span>{{ lastTopic }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row mb-4 mt-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <div class="d-flex">
            <h2 class="flex-shrink-0">Thema abgeschlossen!</h2>
            <ng-template *ngTemplateOutlet="functions"></ng-template>
          </div>
          <div class="d-flex w-100">
            <div class="w-100 d-flex flex-column pt-3">
              <item-cart
                *ngIf="!isLastConsultantTopic && prepDividerType === 'none'; else lastConsultantTopic"
                class="d-flex flex-column flex-scroll"
                [cartType]="'summary'"
                [subtopicId]="subtopicSummaryId"
                (scrolled)="cartScrolled = $event"
              ></item-cart>
            </div>
          </div>
        </kf-tile>
      </div>
    </div>
  </div>
</div>

<ng-template #lastConsultantTopic>
  <div class="vr-headline-200 font-weight-bold mr-3 mb-4">
    {{ lastConsultantTopicText }}
  </div>
  <div class="finish-preparation-icon mb-4"></div>
  <div class="d-flex justify-content-end">
    <button class="mt-4" mat-flat-button color="primary" (click)="onBackToOverviewClick()">
      <span>Zurück zur Themenauswahl</span>
    </button>
  </div>
</ng-template>

<ng-template #functions>
  <div class="w-100 d-flex justify-content-end">
    <button
      [attr.data-testcafe]="'summary-button-individualTasks'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTask()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Maßnahme</span>
    </button>

    <button
      class="ml-3"
      [attr.data-testcafe]="'summary-button-individualTransitions'"
      mat-stroked-button
      color="primary"
      (click)="createIndivTransition()"
    >
      <kf-icon name="ic_pluszeichen_24"></kf-icon>
      <span>Expertengespräch</span>
    </button>

    <button
      mat-stroked-button
      class="ml-3"
      [attr.data-testcafe]="'summary-button-allRecommendations'"
      (click)="selectAllRecommended($event)"
      *ngIf="!allRecommendedChecked"
    >
      <kf-icon name="ic_haken_24"></kf-icon>
      <span>Alle Empfehlungen auswählen</span>
    </button>
  </div>
</ng-template>
