import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment';
import { ActionService } from '@services/action-service/action.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { StorageService } from '@services/storage-service/storage.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RequestInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private snackBarOpen = false;

  private overlayClosedDueToError = false;
  private _reqUrl: string = '';

  constructor(
    private actionService: ActionService,
    private contextService: ContextService,
    private storageService: StorageService,
    private loadingService: LoadingService,
    private snackbarService: SnackBarTemplatesService,
    private rightSidenavService: RightSidenavService,
    private router: Router
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._reqUrl = request.url;

    if (this._reqUrl === '/ping') {
      return next.handle(request);
    }

    if (this.activeRequests === 0) {
      this.loadingService.startLoading();
    }

    this.activeRequests++;

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          setTimeout(() => {
            // delay the stopLoading to prevent flickering
            this.loadingService.stopLoading();
          }, 50);
        }
      }),
      catchError((err: HttpErrorResponse, caught: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> => {
        const result = this.exceptions(err);
        if (!!result) {
          return result;
        }

        console.log(`%c [bgzv-frontend-main] - intercepted error [${err.status} ${err.statusText}]`, 'color: #0066cc');

        const handleError = err => {
          this.closeOverlay(err);
          if (!this.overlayClosedDueToError) {
            this.handleErrorCodes(err);
          }
          this.overlayClosedDueToError = false;
        };

        // Unfortunately this need to be this complicated due to not being able to use 'await'
        if (err.status >= 400) {
          if (!this.isValidError(err)) {
            return of(null);
          }

          if (this.rightSidenavService?.instance?.opened) {
            this.rightSidenavService.close().then(() => {
              handleError(err);
            });
          } else {
            handleError(err);
          }
        }

        return throwError(err);
      })
    );
  }

  private handleErrorCodes(err: HttpErrorResponse): void {
    switch (err.status) {
      case 400:
        this.handleErrorSnackbar(err, 'Es ist ein Fehler aufgetreten.', 3000);
        this.redirect();
        break;
      case 401:
        this.actionService.setAction({ target: 'main', action: 'error-redirect' });
        break;
      case 403:
        this.actionService.setAction({ target: 'main', action: 'error-redirect' });
        break;
      case 404:
        this.handleErrorSnackbar(err, 'Es ist ein Fehler aufgetreten.', 3000);
        this.redirect();
        break;
      case 500:
        this.actionService.setAction({ target: 'main', action: 'error-redirect' });
        break;
      case 501:
        this.actionService.setAction({ target: 'main', action: 'error-redirect' });
        break;
      case 502:
        this.actionService.setAction({ target: 'main', action: 'error-redirect' });
        break;
      case 503:
        this.actionService.setAction({ target: 'main', action: 'error503' });
        break;
    }
  }

  private closeOverlay(err: HttpErrorResponse): void {
    if (this.contextService.currentOverlayContext !== null) {
      this.overlayClosedDueToError = true;
      this.handleErrorSnackbar(err, 'Das Overlay musste geschlossen werden');
      this.actionService.setAction({ target: 'overlay-main', action: 'close' });
    }
  }

  private redirect(): void {
    const hubId = this.storageService.get('bgzvHubId');
    const context = this.contextService.currentUrl;

    if (hubId) {
      const route =
        this.contextService.currentMode === 'main' ? RoutingPathMain.TopicOverview : RoutingPathPrep.TopicOverview;
      this.router.navigate([`${route}/${hubId}`]);
    } else if (environment.platform === 'aws' && (context === 'login' || context === '')) {
      this.router.navigate([RoutingPathMain.Login]);
    } else {
      // @ToDo Jan: find a better solution for this annoying redirect (especially on AWS)
      this.router.navigate([RoutingPathMain.Error], { state: { reason: 'noHubId' } });
    }
  }

  private handleErrorSnackbar(err: HttpErrorResponse, customMessage: string = '', duration: number = 5000): void {
    if (this.snackBarOpen) {
      return;
    }

    const errorCode = err.status;
    const errorStatus = err.statusText;

    const msg = `[${errorCode} ${errorStatus}]`.trim();

    this.snackbarService.openSnackBar({ type: SnackbarType.ERROR, message: `${customMessage} ${msg}`.trim() });

    setTimeout(() => {
      this.snackBarOpen = false;
    }, duration);
    this.snackBarOpen = true;
  }

  private isValidError(err: HttpErrorResponse): boolean {
    if (environment.platform === 'vp') {
      if (err.url.includes('assets/i18n')) {
        return false;
      }
      if (
        err.status === 401 &&
        err.error.path === '/rest/de.bankenit.ksc.contact.widgets.api.v2.SettingsApi/settings'
      ) {
        return false;
      }
      if (err.status === 404 && err.url.includes('settingsV2')) {
        return false;
      }
    }
    if (err.status === 404 && err.error.path.includes('/content/medias')) {
      return false;
    }
    return true;
  }

  private exceptions(err: HttpErrorResponse): Observable<HttpEvent<any>> | null {
    // [VSS-3958] terrible hack to ignore all 401
    if (err.status === 401 && environment.platform === 'vp') {
      return of(null);
    }

    if (err.status === 400) {
      if (err.error?.path === '/api/v1/consultations/current-consultant') {
        return of(null);
      }
      if (
        (err.error?.path && err.error.path.includes('/pdf/notes-and-documents')) ||
        this._reqUrl.includes('/pdf/notes-and-documents')
      ) {
        return throwError(new HttpResponse({ body: { message: 'file unavailable' }, status: 404 }));
      }
    }
    if (err.status === 500 && err.error.path.includes('/summary')) {
      let id = err.error.path?.substring(err.error.path.lastIndexOf('/consultations/') + 15);
      id = id.split('/')[0];
      const y = new HttpResponse({ body: { message: 'use-pdf', id: id }, status: 518 });
      return throwError(y);
    }

    return null;
  }
}
