<div
  class="d-flex no-select w-100 h-100 justify-content-center align-items-center"
  dragAndDropUpload
  (fileDropped)="onFileDrop($event)"
>
  <label for="file-input" class="sr-only">Datei</label>
  <input
    id="file-input"
    #input
    type="file"
    (click)="resetPath($event)"
    (change)="onFileSelect($event)"
    [accept]="fileAccept"
    multiple
    tabindex="-1"
  />
  <div class="d-flex flex-column align-items-center" style="margin: 12rem" [style.text-align]="'center'">
    <img class="mb-4 upload-icon" src="{{ assetPath }}/images/document-upload.svg" alt="" />
    <h3 class="mb-4 font-weight-bold">Dokumente hinzufügen</h3>
    <div class="mb-2" style="text-align: center">
      Dokumente können auch per Drag & Drop auf diese Fläche hinzugefügt werden.
    </div>
    <div class="vr-text-small mb-4">
      Unterstützte Dateiformate: {{ fileAccept }} / Max. Größe: {{ maxFileSize / 1000000 }}MB
    </div>

    <button
      [attr.data-testcafe]="'overlayNotes-button-uploadDocument'"
      mat-flat-button
      color="primary"
      (click)="input.click()"
    >
      <span>Dokument hinzufügen</span>
    </button>

    <div *ngIf="errorMessage" class="d-flex mt-4 vr-text-small vr-color-error w-75">
      {{ errorMessage }}
    </div>
  </div>
</div>
