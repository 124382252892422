<div>
  <mat-tab-group (selectedTabChange)="onTabButtonSelected($event.index)" #tabs>
    <mat-tab [label]="'Produkte (' + productCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        <ng-container *ngIf="productCompositionCount > 0; else noItemSummary">
          <ng-container *ngFor="let cartItem of cartData; let last = last">
            <item-composition-main
              *ngIf="showProductsInCart(cartItem)"
              [class.composition-divider]="!last"
              [class.pb-5]="!last"
              intersectionObserver
              [intersectionRoot]="rootDiv"
              [intersectionRootMargin]="'-40% 0px -50%'"
              [intersectionThreshold]="0"
              (visibilityChange)="onVisibilityChanged(cartItem, $event)"
              id="main_{{ cartItem.mainProduct.id }}"
              [compositionMedia]="cartItem.media"
              [mainData]="cartItem.mainProduct || {}"
              [benefitData]="cartItem.benefitsTypes || []"
              [compositionId]="cartItem.id"
              (selected)="setProductSelectedState(cartItem.id, $event)"
              (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
              (showExtraProducts)="showExtraProductDetails($event)"
              (showDataFields)="showDataFields(cartItem.id, $event)"
              (quantitySet)="setProductQuantity(cartItem.id, $event)"
            >
            </item-composition-main>
          </ng-container>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab [label]="'Maßnahmen (' + taskCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        <ng-container *ngIf="taskCompositionCount > 0; else noItemSummary">
          <ng-container *ngFor="let cartItem of cartData; let index = index; let first = first; let last = last">
            <ng-container *ngIf="cartItem.mainProduct && cartItem.tasks && cartItem.tasks.length > 0">
              <div id="main_{{ cartItem.id }}" [class.pt-4]="!first" class="main-section">
                <ng-template *ngTemplateOutlet="task; context: { data: cartItem }"></ng-template>
              </div>
            </ng-container>
            <ng-container *ngIf="!cartItem.mainProduct">
              <div class="sub-section">
                <ng-template *ngTemplateOutlet="task; context: { data: cartItem }"></ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #task let-cartItem="data">
          <ng-container *ngFor="let cartTask of cartItem.tasks; let first = first; let last = last">
            <item-composition-task
              [mainData]="cartItem.mainProduct || null"
              [compositionId]="cartItem.id"
              [compositionMedia]="cartItem.media"
              [taskData]="cartTask"
              (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
              (showTask)="showTask(cartItem.id, cartItem.name, $event)"
              (taskAssigned)="assignedTask(cartItem.id, $event)"
              [showMainDetails]="first"
            >
            </item-composition-task>
          </ng-container>
        </ng-template>

        <ng-container *ngIf="individualTasks && individualTasks.length > 0">
          <div id="main_individualTasks" class="individual-section">
            <ng-container *ngFor="let cartTask of individualTasks; let first = first; let last = last">
              <item-composition-task
                [mainData]="null"
                [compositionId]="'-1'"
                [taskData]="cartTask"
                (showDetails)="(null)"
                (showTask)="showTaskIndiv($event.elementId)"
                [isIndividualItem]="true"
                [showIndividualGroupHeading]="first"
              >
              </item-composition-task>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-tab>
    <mat-tab [label]="'Expertengespräche (' + transitionCount + ')'">
      <div
        id="container_{{ cartType }}"
        class="position-relative d-flex flex-column flex-scroll flex-grow-1 no-scrollbar w-100 pt-4 content-container"
        (scroll)="onScroll($event)"
      >
        <ng-container *ngIf="transitionCompositionCount > 0; else noItemSummary">
          <ng-container *ngFor="let cartItem of cartData; let first = first; let last = last">
            <div
              *ngIf="cartItem.mainProduct && cartItem.transitions && cartItem.transitions.length > 0"
              id="main_{{ cartItem.id }}"
              class="main-section"
            >
              <ng-template *ngTemplateOutlet="transition; context: { data: cartItem }"></ng-template>
            </div>
            <ng-container *ngIf="!cartItem.mainProduct">
              <div class="sub-section">
                <ng-template *ngTemplateOutlet="transition; context: { data: cartItem }"></ng-template>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #transition let-cartItem="data">
          <ng-container *ngFor="let cartTransition of cartItem.transitions; let first = first">
            <item-composition-transition
              [mainData]="cartItem.mainProduct || null"
              [compositionId]="cartItem.id"
              [compositionMedia]="cartItem.media"
              [transitionData]="cartTransition"
              (showDetails)="showProductDetails(cartItem.id, $event, cartItem)"
              (showTransition)="showTransition(cartItem.id, cartItem.name, $event)"
              (transitionAssigned)="assignedTransition(cartItem.id, $event)"
              id="main_{{ cartItem.mainProduct?.id }}"
              [showMainDetails]="first"
            >
            </item-composition-transition>
          </ng-container>
        </ng-template>

        <ng-container *ngIf="individualTransitions && individualTransitions.length > 0">
          <div id="main_individualTransitions" class="individual-section">
            <ng-container *ngFor="let cartTransition of individualTransitions; let first = first; let last = last">
              <item-composition-transition
                [mainData]="null"
                [compositionId]="'-1'"
                [transitionData]="cartTransition"
                (showDetails)="(null)"
                (showTransition)="showTransitionIndiv($event.elementId)"
                [class.pt-4]="!first"
                [isIndividualItem]="true"
                [showIndividualGroupHeading]="first"
              >
              </item-composition-transition>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #noItemSummary>
  <ng-container
    *ngIf="
      (currentTab === tab.tasks && individualTasks?.length === 0 && taskCount === 0) ||
      (currentTab === tab.products && productCount === 0) ||
      (currentTab === tab.transitions && individualTransitions?.length === 0 && transitionCount === 0)
    "
  >
    <div class="empty-recommendation-icon mb-4"></div>
    <div class="vr-headline-200" style="width: 50%">Für diesen Bereich wurden keine Empfehlungen ausgesprochen.</div>
  </ng-container>
</ng-template>

<ng-template #noSummaryData>
  <div class="empty-recommendation-icon mb-4"></div>
  <div class="vr-headline-200" style="width: 50%">Für dieses Thema wurden keine Empfehlungen ausgesprochen.</div>
</ng-template>
