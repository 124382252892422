<aside class="d-flex flex-column w-100 app-height justify-content-start overflow-hidden agenda-edit">
  <!-- header -->
  <header class="pt-3 border-bottom agenda-edit-header">
    <h2 class="vr-headline-200 m-0">Verfügbare Themen</h2>
    <div class="d-flex">
      <div class="d-flex search-header-container">
        <div class="w-100 search-box-container">
          <mat-form-field>
            <mat-label>Suche nach verfügbaren Themen</mat-label>
            <input
              id="sideAgenda-input-search"
              [attr.data-testcafe]="'SideAgenda-input-search'"
              type="search"
              [placeholder]="'Sucheingaben'"
              [(ngModel)]="searchQuery"
              (input)="handleSearchData()"
              matInput
            />
            <ng-template #magnifier>
              <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
                <kf-icon name="ic_search_24"></kf-icon>
              </button>
            </ng-template>
            <button
              (click)="searchQuery = ''"
              *ngIf="searchQuery.length > 0; else magnifier"
              aria-label="Sucheingaben löschen"
              disableRipple
              mat-icon-button
              matSuffix
            >
              <kf-icon name="ic_clear_24"></kf-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="search-header-container-right"></div>
    </div>
  </header>

  <!-- content -->
  <div class="d-flex agenda-edit-container">
    <!-- available topics -->
    <section class="available-side">
      <div class="available-topics-container" tabindex="-1">
        <div class="line-left">
          <div *ngFor="let topic of searchedData?.matching; let last = last">
            <item-topic-available
              [topicItem]="topic"
              [isTopicExpanded]="!!searchTopicId && getIsExpandedTopic(searchTopicId)"
              [display]="'available'"
              (subtopicSelected)="onSubtopicSelected(topic, $event)"
              (topicSelected)="onTopicSelected($event)"
              (subtopicDeselected)="onSubtopicDeselected($event)"
              (topicDeselect)="onTopicDeselect($event)"
              (topicExpanded)="onTopicExpanded($event)"
              [isLast]="last"
            ></item-topic-available>
          </div>
          <div *ngIf="searchedData?.matching?.length === 0 || !isGrayed">
            <item-topic-available
              *ngFor="let topic of availableConsultationSubtopics; let last = last"
              [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
              [topicItem]="topic"
              [display]="'available'"
              [grayed]="isGrayed"
              (subtopicSelected)="onSubtopicSelected(topic, $event)"
              (topicSelected)="onTopicSelected($event)"
              (subtopicDeselected)="onSubtopicDeselected($event)"
              (topicDeselect)="onTopicDeselect($event)"
              (topicExpanded)="onTopicExpanded($event)"
              [isLast]="last"
            ></item-topic-available>
            <hr *ngIf="showDivider && availableConsultantSubtopics?.length !== 0" class="my-4 horizontal-line" />
            <item-topic-available
              *ngFor="let topic of availableConsultantSubtopics; let last = last"
              [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
              [topicItem]="topic"
              [display]="'available'"
              [grayed]="isGrayed"
              (subtopicSelected)="onSubtopicSelected(topic, $event)"
              (topicSelected)="onTopicSelected($event)"
              (subtopicDeselected)="onSubtopicDeselected($event)"
              (topicDeselect)="onTopicDeselect($event)"
              (topicExpanded)="onTopicExpanded($event)"
              [isLast]="last"
            ></item-topic-available>
            <div *ngIf="consultationStatus === consultationStatusEnum.inPreparation">
              <hr *ngIf="showDivider && availableCustomerSubtopics?.length !== 0" class="my-4 horizontal-line" />
              <item-topic-available
                *ngFor="let topic of availableCustomerSubtopics; let last = last"
                [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
                [topicItem]="topic"
                [display]="'available'"
                [grayed]="isGrayed"
                (subtopicSelected)="onSubtopicSelected(topic, $event)"
                (topicSelected)="onTopicSelected($event)"
                (subtopicDeselected)="onSubtopicDeselected($event)"
                (topicDeselect)="onTopicDeselect($event)"
                (topicExpanded)="onTopicExpanded($event)"
                [isLast]="last"
              ></item-topic-available>
            </div>
          </div>
          <div *ngIf="searchedData?.matching?.length !== 0">
            <hr *ngIf="isGrayed" class="my-4 horizontal-line" />
            <item-topic-available
              *ngFor="let topic of searchedData.remainder; let last = last"
              [attr.data-testcafe]="'SideAgenda-item-' + getTestcafeId(topic.title)"
              [topicItem]="topic"
              [display]="'available'"
              [grayed]="isGrayed"
              (subtopicSelected)="onSubtopicSelected(topic, $event)"
              (topicSelected)="onTopicSelected($event)"
              (subtopicDeselected)="onSubtopicDeselected($event)"
              (topicDeselect)="onTopicDeselect($event)"
              (topicExpanded)="onTopicExpanded($event)"
              [isLast]="last"
            ></item-topic-available>
          </div>
        </div>
      </div>
    </section>

    <!-- selected topics -->
    <section class="selected-side">
      <ng-container
        *ngIf="
          selectedConsultationSubtopics?.length < 1 &&
          selectedConsultantSubtopics?.length < 1 &&
          selectedCustomerSubtopics?.length < 1
        "
      >
        <p class="d-flex align-items-center">
          <kf-icon name="ic_infozeichen_mit_kreis_24" aria-hidden="true" class="mr-2"></kf-icon>
          <span class="align-left">Bitte wählen Sie aus den verfügbaren Themen aus!</span>
        </p>
      </ng-container>

      <ng-container *ngIf="selectedConsultationSubtopics?.length > 0">
        <div class="selected-topics-container">
          <div
            cdkDropList
            [cdkDropListData]="selectedConsultationSubtopics"
            (cdkDropListDropped)="drop($event, 'consultation')"
          >
            <div *ngFor="let subtopic of selectedConsultationSubtopics; let i = index">
              <div *ngIf="i === 0" class="d-flex justify-content-start">
                <h2 class="mb-3 mr-2 vr-headline-200">Beratungsthemen</h2>
                <div>
                  <div class="tag-info tag-neutral mt-1 ml-3">
                    <div class="tag-info-text">
                      {{ selectedConsultationSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                    </div>
                  </div>
                </div>
              </div>
              <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
              </item-subtopic-selected>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="
          consultationStatus === consultationStatusEnum.inPreparation ||
          (consultationStatus === consultationStatusEnum.preparationSent && selectedConsultantSubtopics?.length > 0)
        "
      >
        <div class="selected-topics-container">
          <div
            cdkDropList
            class="pl-4"
            [cdkDropListData]="selectedConsultantSubtopics"
            (cdkDropListDropped)="drop($event, 'consultant')"
          >
            <div *ngFor="let subtopic of selectedConsultantSubtopics; let i = index">
              <div *ngIf="i === 0" class="d-flex justify-content-start">
                <h2 class="mb-3 mr-2 vr-headline-200">Vorbereitung Berater</h2>
                <div>
                  <div class="tag-info tag-neutral mt-1 ml-3">
                    <div class="tag-info-text">
                      {{ selectedConsultantSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                    </div>
                  </div>
                </div>
              </div>
              <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
              </item-subtopic-selected>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container
        *ngIf="consultationStatus === consultationStatusEnum.inPreparation && selectedCustomerSubtopics?.length > 0"
      >
        <div class="selected-topics-container">
          <div
            cdkDropList
            class="pl-4"
            [cdkDropListData]="selectedCustomerSubtopics"
            (cdkDropListDropped)="drop($event, 'customer')"
          >
            <div *ngFor="let subtopic of selectedCustomerSubtopics; let i = index">
              <div *ngIf="i === 0" class="d-flex justify-content-start mb-3">
                <h2 class="mb-0 mr-2 vr-headline-200">Vorbefragung Kunde</h2>
                <div>
                  <div class="tag-info tag-neutral mt-1 ml-3">
                    <div class="tag-info-text">
                      {{ selectedCustomerSubtopics?.length }}<span class="sr-only">Unterthemen</span>
                    </div>
                  </div>
                </div>
              </div>
              <item-subtopic-selected [subtopicItem]="subtopic" (subtopicDeselected)="onSubtopicDeselected($event)">
              </item-subtopic-selected>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
  </div>

  <!-- footer -->
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content">
        <button [attr.data-testcafe]="'sideAgenda-button-abort'" mat-stroked-button color="primary" (click)="onAbort()">
          <span>Abbrechen</span>
        </button>
        <button [attr.data-testcafe]="'sideAgenda-button-close'" mat-flat-button color="primary" (click)="onClose()">
          <span>Speichern</span>
        </button>
      </div>
    </div>
  </footer>

  <button
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
    (click)="onAbort()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>
