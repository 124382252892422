<div class="d-flex">
  <div class="profile-side">
    <short-profile [profileData]="profileData" [updateInProgress]="updateInProgress"></short-profile>
  </div>
  <section class="available-side d-flex flex-column">
    <div class="search-header-container">
      <ng-container *ngIf="headline">
        <div class="headline-header-container">
          <h2 class="header-headline">{{ headline }}</h2>
          <div class="step-bubble-container">
            <ng-container *ngFor="let item of stepsBubbles">
              <ng-container *ngIf="item < stepsBubbles.length; else halfbubble">
                <ng-container [ngTemplateOutlet]="fullbubble"></ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let _ of emptyStepBubbles">
              <ng-container [ngTemplateOutlet]="emptyBubble"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <h3 class="vr-text-regular font-weight-bold search-label">Verfügbare Themen</h3>
      <div class="w-100">
        <mat-form-field>
          <mat-label>Suche nach verfügbaren Themen</mat-label>
          <input
            #searchInput
            [attr.data-testcafe]="'screenTopic-input-search'"
            type="search"
            placeholder="Thema"
            matInput
            [(ngModel)]="searchQuery"
            id="screenTopic-input-search"
          />
          <ng-template #magnifier>
            <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
              <kf-icon name="ic_search_24"></kf-icon>
            </button>
          </ng-template>
          <button
            (click)="searchQuery = ''"
            *ngIf="searchQuery.length > 0; else magnifier"
            aria-label="Sucheingaben löschen"
            disableRipple
            mat-icon-button
            matSuffix
          >
            <kf-icon name="ic_clear_24"></kf-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex h-100 flex-scroll" tabindex="-1" [class.side-footer-margin]="useMargin">
      <div class="available-topic-wrapper">
        <item-topic-available
          *ngFor="let topic of searchedData.matching; let last = last"
          [topicItem]="topic"
          [isTopicExpanded]="getIsExpandedTopic(topic.id)"
          [display]="'available'"
          (subtopicSelected)="onSubtopicSelected(topic, $event)"
          (topicSelected)="onTopicSelected($event)"
          (subtopicDeselected)="onSubtopicDeselected($event)"
          (topicDeselect)="onTopicDeselect($event)"
          (topicExpanded)="onTopicExpanded($event)"
          [isLast]="last"
        ></item-topic-available>
        <hr *ngIf="showDivider" class="horizontal-line my-4" />
        <item-topic-available
          *ngFor="let topic of searchedData.remainder; let last = last"
          [topicItem]="topic"
          [display]="'available'"
          [isTopicExpanded]="getIsExpandedTopic(topic.id)"
          [grayed]="true"
          (subtopicSelected)="onSubtopicSelected(topic, $event)"
          (topicSelected)="onTopicSelected($event)"
          (subtopicDeselected)="onSubtopicDeselected($event)"
          (topicDeselect)="onTopicDeselect($event)"
          [isLast]="last"
        ></item-topic-available>
      </div>
    </div>
  </section>
</div>
<section class="selected-side">
  <div class="selected-topics-number-container" [class.has-selected-topics]="selectedTopicsAmount > 0">
    <h3 class="vr-text-regular font-weight-bold">Ihre Auswahl</h3>
    <div *ngIf="selectedTopicsAmount > 0" class="tag-info tag-neutral ml-3 mt-n3">
      <div class="tag-info-text">{{ selectedTopicsAmount }}<span class="sr-only">Themen gewählt</span></div>
    </div>
  </div>
  <div class="selected-topic-container">
    <ng-container *ngIf="selectedTopicsAmount < 1">
      <p class="pl-4 d-flex align-items-center">
        <kf-icon name="ic_infozeichen_mit_kreis_24" class="mr-2" aria-hidden="true"></kf-icon>
        <span class="align-left">Bitte wählen Sie aus den verfügbaren Themen aus!</span>
      </p>
    </ng-container>

    <div cdkDropList class="pl-4 selected-topic" (cdkDropListDropped)="drop($event)">
      <item-subtopic-selected
        *ngFor="let subtopic of data?.selected"
        [subtopicItem]="subtopic"
        (subtopicDeselected)="onSubtopicDeselected($event)"
      >
      </item-subtopic-selected>
    </div>
  </div>
</section>

<ng-template #fullbubble>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find(color.name.BrandPrimary)"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #halfbubble>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    transform="rotate(180 0 0)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99967 13.4163V6.58301C8.1127 6.58301 6.58301 8.1127 6.58301 9.99967C6.58301 11.8866 8.1127 13.4163 9.99967 13.4163ZM15.4163 9.99967C15.4163 12.9912 12.9912 15.4163 9.99967 15.4163C7.00813 15.4163 4.58301 12.9912 4.58301 9.99967C4.58301 7.00813 7.00813 4.58301 9.99967 4.58301C12.9912 4.58301 15.4163 7.00813 15.4163 9.99967Z"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #emptyBubble>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.99967" cy="9.99967" r="4.41667" stroke-width="2" [attr.stroke]="color.find(color.name.Neutral500)" />
  </svg>
</ng-template>
