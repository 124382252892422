import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UpdateAnswerItem, UpdateAnswerResponse } from '@domain/app/answers.domain';
import { CheckoutDataFieldUpdateRequest, CheckoutDataItem } from '@domain/app/checkout.domain';
import { CompositionRecommendationResponse } from '@domain/app/composition.domain';
import { ConfigurationResponse } from '@domain/app/configuration.domain';
import {
  ContractPreviewsResponseItem,
  ContractStatusResponseItem,
  GetEMailTemplateItem,
  PutFinishConsultationResponseItem,
  PutUpdateStatusResponseItem,
  SendPreliminaryRequestItem,
  SendPreliminaryResponseItem,
  SingleContractStatusRequestItem,
  UpdateConsultationStatusRequestItem,
} from '@domain/app/consultation.domain';
import { CustomerContactRequestItem, CustomerContactResponseItem } from '@domain/app/contact.domain';
import { PutNewTaskDataFieldGroupRequest } from '@domain/app/datafield.domain';
import { GetInstanceResponse } from '@domain/app/instances.domain';
import { ConsultationCheckoutDocumentsResponse, ConsultationDocumentsResponse } from '@domain/app/media.domain';
import { GetMessagesResponseItem, PostMessagesRequestItem } from '@domain/app/messages.domain';
import { DeselectProductItem, ProductResponse, SelectProductItem } from '@domain/app/product.domain';
import { Profile } from '@domain/app/profile';
import { QuestionGroupResponse } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse, SelectedItemsCountResponse } from '@domain/app/recommendation.domain';
import { SearchOverlay } from '@domain/app/search-overlay';
import {
  AssignIndividualTaskItem,
  AssignTaskItem,
  DeselectTaskItem,
  SelectTaskItem,
  TaskResponse,
} from '@domain/app/task.domain';
import {
  SelectedTopicItem,
  SubtopicSelectionResponse,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import {
  AssignIndividualTransitionRequest,
  AssignTransitionRequest,
  DeselectTransitionItem,
  SelectTransitionItem,
  TransitionResponse,
} from '@domain/app/transition.domain';
import { Notes } from '@domain/notes';
import { VariantCalculator } from '@domain/variant-calculator';
import { DataFieldElementTypeEnum } from '@enums';
import { CacheService } from '@services/cache-service/cache.service';
import { NoteIdentifier } from '@services/note-service/note';
import { Observable, of } from 'rxjs';
import { QueryHub } from './query.hub.service';

import GetCompositionSearchResponse = SearchOverlay.GetCompositionSearchResponse;
import GetProfileResponse = Profile.GetProfileResponse;
import ProfileGroupDTO = Profile.ProfileGroupDTO;
import ProfileUpdateInProgress = Profile.ProfileUpdateInProgress;

@Injectable({
  providedIn: 'root',
})
export class QueryService extends QueryHub {
  constructor(
    protected override http: HttpClient,
    protected override cacheService: CacheService
  ) {
    super(http, cacheService);
  }

  // --------------------------------------------- //
  // --------------- API GET METHODS ------------- //
  // --------------------------------------------- //

  getAppConfiguration(renew = false): Observable<ConfigurationResponse> {
    // this data can be cached as it will not change during the runtime of the consultation
    return this.cacheService.fetch(
      'config',
      () => this.http.get<ConfigurationResponse>(`${this.api}/configuration`),
      renew
    );
  }

  getCustomerContacts(consultationId: string): Observable<CustomerContactResponseItem[]> {
    return this.http.get<CustomerContactResponseItem[]>(
      `${this.api}/consultations/${consultationId}/customer-contacts`
    );
  }

  getCustomerContactsForTask(consultationId: string, taskId: string): Observable<CustomerContactResponseItem[]> {
    return this.http.get<CustomerContactResponseItem[]>(
      `${this.api}/consultations/${consultationId}/customer-contacts-for-task?taskId=${taskId}`
    );
  }

  getTopicDataByConsultationId(consultationId: string): Observable<TopicSelectionResponse> {
    return this.http.get<TopicSelectionResponse>(`${this.api}/consultations/${consultationId}/topics`);
  }

  getSubtopicDataByConsultationId(consultationId: string): Observable<SubtopicSelectionResponse> {
    return this.http.get<SubtopicSelectionResponse>(`${this.api}/consultations/${consultationId}/subtopics`);
  }

  getQuestionCollectionById(consultationId: string, questionCollectionId): Observable<QuestionGroupResponse> {
    return this.http.get<QuestionGroupResponse>(
      `${this.api}/consultations/${consultationId}/question-groups/${questionCollectionId}`
    );
  }

  getQuestionDataFields(consultationId: string, questionId: string): Observable<any> {
    return this.http.get<any>(`${this.api}/consultations/${consultationId}/questions/${questionId}/data-fields`);
  }

  // PRELIM ENDPOINTS

  getAvailablePrelimTopics(consultationId: string): Observable<any> {
    return this.http.get<any>(`${this.api}/consultations/${consultationId}/preliminary/available`);
  }

  getRecommendedCompositions(consultationId: string, subtopicId = null): Observable<CompositionRecommendationResponse> {
    const subtopicQuery = subtopicId ? `?subtopicId=${subtopicId}` : '';

    if (!consultationId) {
      return of(null);
    }

    return this.http.get<CompositionRecommendationResponse>(
      `${this.api}/consultations/${consultationId}/recommendations/list${subtopicQuery}`
    );
  }

  getRecommendedProducts(
    consultationId: string,
    questionGroupId: string
  ): Observable<QuestionGroupRecommendationResponse> {
    return this.http.get<QuestionGroupRecommendationResponse>(
      `${this.api}/consultations/${consultationId}/recommendations/question-groups/${questionGroupId}`
    );
  }

  getProductDetailsById(consultationId: string, compositionId: string, productId: string): Observable<ProductResponse> {
    return this.http.get<ProductResponse>(
      `${this.api}/consultations/${consultationId}/compositions/${compositionId}/products/${productId}`
    );
  }

  getTaskById(consultationId: string, compositionId: string, taskId: string): Observable<TaskResponse> {
    return this.http.get<TaskResponse>(
      `${this.api}/consultations/${consultationId}/compositions/${compositionId}/tasks/${taskId}`
    );
  }

  getIndividualTaskById(consultationId: string, indivTaskId: string): Observable<TaskResponse> {
    return this.http.get<TaskResponse>(`${this.api}/consultations/${consultationId}/individual-tasks/${indivTaskId}`);
  }

  getTransitionById(
    consultationId: string,
    compositionId: string,
    transitionId: string
  ): Observable<TransitionResponse> {
    return this.http.get<TransitionResponse>(
      `${this.api}/consultations/${consultationId}/compositions/${compositionId}/transitions/${transitionId}`
    );
  }

  getTransitionExperts(consultationId: string, transitionId: string = null): Observable<any> {
    const query = transitionId ? `?transitionId=${transitionId}` : '';
    return this.http.get<any>(`${this.api}/consultations/${consultationId}/transition-contacts${query}`);
  }

  getIndividualTransitionById(consultationId: string, transitionId: string): Observable<TransitionResponse> {
    return this.http.get<TransitionResponse>(
      `${this.api}/consultations/${consultationId}/individual-transitions/${transitionId}`
    );
  }

  getIndividualTransitionExperts(consultationId: string): Observable<any> {
    return this.http.get<any>(`${this.api}/consultations/${consultationId}/transition-contacts`);
  }

  getSelectedItemsCount(consultationId: string): Observable<SelectedItemsCountResponse> {
    return this.http.get<SelectedItemsCountResponse>(
      `${this.api}/consultations/${consultationId}/selected-items-count`
    );
  }

  getProfileUpdateInProgress(consultationId: string): Observable<ProfileUpdateInProgress> {
    return this.http.get<ProfileUpdateInProgress>(`${this.api}/consultations/${consultationId}/profile/update-status`);
  }

  getConsultationResultEMailTemplate(consultationId: string): Observable<GetEMailTemplateItem> {
    return this.http.get<GetEMailTemplateItem>(`${this.api}/consultations/${consultationId}/result/send`);
  }

  /// --- CHECKOUT ENDPOINTS --- ///

  getCheckoutDocuments(consultationId: string): Observable<ConsultationCheckoutDocumentsResponse[]> {
    return this.http.get<ConsultationCheckoutDocumentsResponse[]>(
      `${this.api}/consultations/${consultationId}/checkout/documents`
    );
  }

  getConsultationDocuments(consultationId: string): Observable<ConsultationDocumentsResponse[]> {
    return this.http.get<ConsultationDocumentsResponse[]>(`${this.api}/consultations/${consultationId}/documents`);
  }

  getCheckoutData(consultationId: string): Observable<CheckoutDataItem> {
    return this.http.get<CheckoutDataItem>(`${this.api}/consultations/${consultationId}/checkout/data`);
  }

  getCheckoutDataFieldData(
    consultationId: string,
    type: DataFieldElementTypeEnum,
    compositionId: string = null,
    elementId: string = null
  ): Observable<any> {
    const endpointType = `${type}`;
    const endpointParams = `compositionId=${compositionId || 0}&elementId=${elementId || 0}`;
    const endpoint = type !== 'general' ? `${endpointType}?${endpointParams}` : `${endpointType}`;
    return this.http.get<any[]>(`${this.api}/consultations/${consultationId}/checkout/data-fields/${endpoint}`);
  }

  /// --- DATA FIELD ENDPOINTS --- ///
  getVariantCalculator(consultationId: string, type: VariantCalculator.CalculatorName) {
    return this.http.get<VariantCalculator.GetVariantCalculatorResponse>(
      `${this.api}/consultations/${consultationId}/variant-calculator/${type}`
    );
  }

  getNotes(consultationId: string): Observable<Notes.GetAllNotesResponse> {
    return this.http.get<Notes.GetAllNotesResponse>(`${this.api}/consultations/${consultationId}/notes`);
  }

  getNote(consultationId: string, noteId: string) {
    return this.http.get<Notes.GetNoteResponse>(`${this.api}/consultations/${consultationId}/notes/${noteId}`);
  }

  getProfile(consultationId: string, showConfidentialGroups: boolean): Observable<GetProfileResponse> {
    return this.http.get<any>(
      `${this.api}/consultations/${consultationId}/profile?showConfidential=${showConfidentialGroups}`
    );
  }

  getInstances(): Observable<GetInstanceResponse[]> {
    return this.http.get<any>(`${this.hub}/instances`);
  }

  getMessages(consultationId: string): Observable<GetMessagesResponseItem[]> {
    return this.http.get<GetMessagesResponseItem[]>(`${this.api}/consultations/${consultationId}/messages`);
  }

  getSearchOverlayCompositions(consultationId: string) {
    return this.http.get<GetCompositionSearchResponse>(
      `${this.api}/consultations/${consultationId}/search-overlay/compositions`
    );
  }

  getSearchOverlayTopics(consultationId: string): Observable<TopicOverviewItem[]> {
    return this.http.get<TopicOverviewItem[]>(`${this.api}/consultations/${consultationId}/search-overlay/topics`);
  }

  getIsTestConsultation(consultationId: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.api}/consultations/${consultationId}/is-test`);
  }

  /// --- CONTRACTS GET ENDPOINTS --- ///
  getSingleProductStatus(
    consultationId: string,
    data: SingleContractStatusRequestItem
  ): Observable<ContractStatusResponseItem> {
    return this.http.get<ContractStatusResponseItem>(
      `${this.api}/consultations/${consultationId}/compositions/${
        data.compositionId
      }/${data.elementType.toLowerCase()}/${data.elementId}/ordinal/${data.ordinal}/contracts/status`
    );
  }

  getContractStatus(consultationId: string): Observable<ContractStatusResponseItem[]> {
    return this.http.get<ContractStatusResponseItem[]>(`${this.api}/consultations/${consultationId}/contracts/status`);
  }

  //get media content via the URL of the media
  getMediaContent(mediaUrl: string, renew = false): Observable<any> {
    const options = { responseType: 'blob' as 'json' };
    const params = `?url=${mediaUrl}`;
    return this.cacheService.fetch(
      `mediaContent_${mediaUrl}`,
      () => this.http.get(`${this.api}/content/medias/download${params}`, options),
      renew
    );
  }

  // --------------------------------------------- //
  // --------------- API PUT METHODS ------------- //
  // --------------------------------------------- //

  putConsultationStatus(
    consultationId: string,
    data: UpdateConsultationStatusRequestItem
  ): Observable<PutUpdateStatusResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.put<PutUpdateStatusResponseItem>(
      `${this.api}/consultations/${consultationId}/update-status`,
      sendData,
      this.options
    );
  }

  putFinishConsultation(consultationId: string): Observable<PutFinishConsultationResponseItem> {
    const base64Url = btoa(`${window.location.origin}/bws/bgzv-frontend-main/#/main/result/summary/${consultationId}`);
    const _headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .append('X-Referer', window.location.origin)
      .append('X-Summary-Url', base64Url);

    const options = { headers: _headers };

    return this.http.put<PutFinishConsultationResponseItem>(
      `${this.api}/consultations/${consultationId}/finish-checkout`,
      null,
      options
    );
  }

  addQuestionToPreliminary(consultationId: string, questionId: string): Observable<any> {
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/preliminary/questions/${questionId}`,
      this.options
    );
  }

  removeQuestionFromPreliminary(consultationId: string, questionId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.api}/consultations/${consultationId}/preliminary/questions/${questionId}`,
      this.options
    );
  }

  addQuestionGroupToPreliminary(consultationId: string, questionGroupId: string): Observable<any> {
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/preliminary/question-groups/${questionGroupId}`,
      this.options
    );
  }

  removeQuestionGroupFromPreliminary(consultationId: string, questionGroupId: string): Observable<any> {
    return this.http.delete<any>(
      `${this.api}/consultations/${consultationId}/preliminary/question-groups/${questionGroupId}`
    );
  }

  addSubtopicToPreliminary(consultationId: string, subtopicId: string): Observable<any> {
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/preliminary/subtopics/${subtopicId}`,
      this.options
    );
  }

  removeSubtopicFromPreliminary(consultationId: string, subtopicId: string): Observable<any> {
    return this.http.delete<any>(`${this.api}/consultations/${consultationId}/preliminary/subtopics/${subtopicId}`);
  }

  putSendFinishPreliminary(
    consultationId: string,
    data: SendPreliminaryRequestItem
  ): Observable<SendPreliminaryResponseItem> {
    const sendData = JSON.stringify(data);
    return this.http.put<SendPreliminaryResponseItem>(
      `${this.api}/consultations/${consultationId}/preliminary/send`,
      sendData,
      this.options
    );
  }

  putTopicDataByConsultationId(consultationId: string, data: SelectedTopicItem[]): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/topics`, sendData, this.options);
  }

  putSubtopicDataByConsultationId(consultationId: string, data: SelectedTopicItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/subtopics`, sendData, this.options);
  }

  putSubtopicComplete(consultationId: string, subtopicId: string): Observable<any> {
    const sendData = JSON.stringify(subtopicId);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/subtopics/${subtopicId}/complete`,
      sendData,
      this.options
    );
  }

  putAnswerFromGroup(
    consultationId: string,
    questionId: string,
    answerObject: UpdateAnswerItem[]
  ): Observable<UpdateAnswerResponse> {
    const sendData = JSON.stringify(answerObject);
    // return this.http.post<any>('/mock/sendAnswersFromGroup', sendData);
    return this.http.put<UpdateAnswerResponse>(
      `${this.api}/consultations/${consultationId}/questions/${questionId}/answers`,
      sendData,
      this.options
    );
  }

  // ======== PRODUCT SELECTION ======== //
  putSelectProduct(consultationId: string, data: SelectProductItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/select-product`, sendData, this.options);
  }

  putDeselectProduct(consultationId: string, data: DeselectProductItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/deselect-product`, sendData, this.options);
  }

  // ======== TASK SELECTION ======== //
  putAssignTask(consultationId, data: AssignTaskItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/assign-task`, sendData, this.options);
  }

  putAssignIndividualTask(consultationId: string, data: AssignIndividualTaskItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/assign-individual-task`,
      sendData,
      this.options
    );
  }

  putSelectTask(consultationId: string, data: SelectTaskItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/select-task`, sendData, this.options);
  }

  putDeselectTask(consultationId: string, data: DeselectTaskItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/deselect-task`, sendData, this.options);
  }

  // ======== TRANSITION SELECTION ======== //
  putAssignTransition(consultationId: string, data: AssignTransitionRequest): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/assign-transition`, sendData, this.options);
  }

  putAssignIndividualTransition(consultationId: string, data: AssignIndividualTransitionRequest): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<TransitionResponse>(
      `${this.api}/consultations/${consultationId}/assign-individual-transition`,
      sendData,
      this.options
    );
  }

  putSelectTransition(consultationId: string, data: SelectTransitionItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/select-transition`, sendData, this.options);
  }

  putDeselectTransition(consultationId: string, data: DeselectTransitionItem): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/deselect-transition`,
      sendData,
      this.options
    );
  }

  putSelectIndividualTask(consultationId: string, taskId: string): Observable<any> {
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/individual-tasks/${taskId}/select`, {});
  }

  putDeselectIndividualTask(consultationId: string, taskId: string): Observable<any> {
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/individual-tasks/${taskId}/deselect`, {});
  }

  putSelectIndividualTransition(consultationId: string, transitionId: string): Observable<any> {
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/individual-transitions/${transitionId}/select`,
      {}
    );
  }

  putDeselectIndividualTransition(consultationId: string, transitionId: string): Observable<any> {
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/individual-transitions/${transitionId}/deselect`,
      {}
    );
  }

  putAddNewTaskDataFieldGroup(consultationId: string, sendObj: PutNewTaskDataFieldGroupRequest): Observable<any> {
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/data-field-groups/add-for-task`, sendObj);
  }

  putRemoveTaskDataFieldGroup(consultationId: string, sendObj: PutNewTaskDataFieldGroupRequest): Observable<any> {
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/data-field-groups/remove-for-task`, sendObj);
  }

  putUpdateProfileData(consultationId: string): Observable<any> {
    return this.http.put<any>(`${this.api}/consultations/${consultationId}/profile/update`, null);
  }

  // ======== CHECKOUT DATAFIELDS ======== //

  putCheckoutDataFieldData(
    consultationId: string,
    data: CheckoutDataFieldUpdateRequest,
    type: DataFieldElementTypeEnum | string
  ): Observable<any> {
    const sendData = JSON.stringify(data);
    return this.http.put<any>(
      `${this.api}/consultations/${consultationId}/checkout/data-fields/${type}`,
      sendData,
      this.options
    );
  }

  putPrepQuestionsToCustomer(consultationId: string, questionData) {
    const sendData = JSON.stringify(questionData);
    return this.http.put<any>(`${this.api}/preparation/${consultationId}/questions`, sendData, this.options);
  }

  // ======== CONTRACTS PUT / POST ENDPOINTS ======== //

  postGenerateContractPreview(
    consultationId: string,
    data: SingleContractStatusRequestItem
  ): Observable<ContractPreviewsResponseItem> {
    const sendData = ''; // needed since the put method expects a request body
    return this.http.post<ContractPreviewsResponseItem>(
      `${this.api}/consultations/${consultationId}/compositions/${
        data.compositionId
      }/${data.elementType.toLowerCase()}/${data.elementId}/ordinal/${data.ordinal}/contracts/preview`,
      sendData,
      this.options
    );
  }

  putIncludeContract(
    consultationId: string,
    data: SingleContractStatusRequestItem
  ): Observable<ContractStatusResponseItem> {
    const sendData = ''; // needed since the put method expects a request body
    return this.http.put<ContractStatusResponseItem>(
      `${this.api}/consultations/${consultationId}/compositions/${
        data.compositionId
      }/${data.elementType.toLowerCase()}/${data.elementId}/ordinal/${data.ordinal}/contracts/include`,
      sendData,
      this.options
    );
  }

  putExcludeContract(
    consultationId: string,
    data: SingleContractStatusRequestItem
  ): Observable<ContractStatusResponseItem> {
    const sendData = ''; // needed since the put method expects a request body
    return this.http.put<ContractStatusResponseItem>(
      `${this.api}/consultations/${consultationId}/compositions/${
        data.compositionId
      }/${data.elementType.toLowerCase()}/${data.elementId}/ordinal/${data.ordinal}/contracts/exclude`,
      sendData,
      this.options
    );
  }

  // --------------------------------------------- //
  // -------------- API PATCH METHODS ------------ //
  // --------------------------------------------- //

  postCreateNote(consultationId: string, request: Notes.CreateNoteRequest) {
    return this.http.post<Notes.GetNoteResponse>(
      `${this.api}/consultations/${consultationId}/notes`,
      request,
      this.options
    );
  }

  postSendDocuments(
    consultationId: string,
    customerContact: CustomerContactRequestItem | CustomerContactResponseItem,
    mediaIds: number[]
  ) {
    const sendData = JSON.stringify({ customerContact, mediaIds });

    return this.http.post(
      ` ${this.api}/consultations/${consultationId}/checkout/send-documents`,
      sendData,
      this.options
    );
  }

  postMessage(consultationId: string, messageData: PostMessagesRequestItem): Observable<GetMessagesResponseItem> {
    const sendData = JSON.stringify(messageData);
    return this.http.post<GetMessagesResponseItem>(
      `${this.api}/consultations/${consultationId}/messages`,
      sendData,
      this.options
    );
  }

  postSelectDocumentForConsultation(consultationId: string, gshInfo: ConsultationDocumentsResponse): Observable<any> {
    return this.http.post<any>(` ${this.api}/consultations/${consultationId}/documents`, gshInfo);
  }

  patchUpdateNote(consultationId: string, noteId: string, request: Notes.UpdateNoteRequest): Observable<any> {
    return this.http.patch(`${this.api}/consultations/${consultationId}/notes/${noteId}`, request);
  }

  patchUpdateProfile(consultationId: string, request: Profile.ProfileUpdateRequest): Observable<GetProfileResponse> {
    return this.http.patch<GetProfileResponse>(`${this.api}/consultations/${consultationId}/profile`, request);
  }

  patchIncludeNote(consultationId: string, noteId: number): Observable<any> {
    return this.http.patch(`${this.api}/consultations/${consultationId}/notes/${noteId}/include`, {});
  }

  patchExcludeNote(consultationId: string, noteId: number): Observable<any> {
    return this.http.patch(`${this.api}/consultations/${consultationId}/notes/${noteId}/exclude`, {});
  }

  patchIncludeDocument(consultationId: string, documentId: number): Observable<any> {
    return this.http.patch(`${this.api}/consultations/${consultationId}/documents/${documentId}/include`, {});
  }

  patchExcludeDocument(consultationId: string, documentId: number): Observable<any> {
    return this.http.patch(`${this.api}/consultations/${consultationId}/documents/${documentId}/exclude`, {});
  }

  // --------------------------------------------- //
  // ------------- API DELETE METHODS ------------ //
  // --------------------------------------------- //
  deleteNote(consultationId: string, note: NoteIdentifier): Observable<any> {
    return this.http.delete<any>(`${this.api}/consultations/${consultationId}/notes/${note}`);
  }

  deleteSelectDocumentsForConsultation(consultationId: string, gshId: number): Observable<any> {
    return this.http.delete<any>(` ${this.api}/consultations/${consultationId}/documents/${gshId}`);
  }

  deleteSelectDocumentsForCustomer(customerId: number, gshId: number): Observable<any> {
    return this.http.delete<any>(` ${this.hub}/customers/${customerId}/documents/${gshId} `);
  }
}
