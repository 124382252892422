import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { FooterAction, FooterConfig, FooterSectionType } from '@de.fiduciagad.kbm/shared-footer-lib';
import { EnvironmentLookupService } from '@de.fiduciagad.kundenportal/environment-lookup';
import {
  ContractStatusContractItem,
  GetConsultationResponseItem,
  GetSummaryConsultationResponseItem,
  PutFinishConsultationResponseItem,
} from '@domain/app/consultation.domain';
import { ConsultationCheckoutDocumentsResponse } from '@domain/app/media.domain';
import { ConsultationStatusEnum, MediaTypeEnum, RoutingPathMain } from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { ContractService } from '@services/contract-service/contract.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { IpadPdfViewerService } from '@services/pdf-viewer-service/ipad-pdf-viewer-service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { baseConfig, buttonFinishConsultation } from '@utils/footer-config';
import { color, libIcons } from 'bgzv-frontend-library';
import * as fileSaver from 'file-saver';
import { cloneDeep } from 'lodash-es';
import { Subject, forkJoin } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

/**
 * The result page exists to give the consultant a summary
 * about the current consultation
 *
 * The panel including the products is open per default
 */
@Component({
  selector: 'screen-result',
  templateUrl: './screen-result.component.html',
  styleUrls: ['./screen-result.component.scss'],
})
export class ScreenResultComponent implements OnInit, OnDestroy {
  private destroySubs = new Subject<void>();
  private consultationSummaryId: string = '';
  private origin: RoutingPathMain | string;
  private checkoutFinished = false;
  private footerConfig: FooterConfig = baseConfig;

  public documentData: ConsultationCheckoutDocumentsResponse[];
  public contractStatus: ContractStatusContractItem[];
  public data: PutFinishConsultationResponseItem;
  public summaryData: GetSummaryConsultationResponseItem;
  public error: string = '';
  public context: string = null;
  public isLoading: boolean = false;

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    private chg: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private contextService: ContextService,
    private clientService: ClientService,
    private configService: ConfigService,
    private contractService: ContractService,
    private queryService: QueryService,
    private actionService: ActionService,
    private footerService: SharedFooterService,
    private ipadViewerService: IpadPdfViewerService,
    private environmentLookupService: EnvironmentLookupService,
    private location: Location,
    private loadingService: LoadingService,
    private snackBarService: SnackBarTemplatesService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      const state = this.router.getCurrentNavigation().extras.state;
      if (state && state?.origin) {
        this.origin = state.origin;
      }
    } else if ((location.getState() as any)?.origin) {
      this.origin = (location.getState() as any)?.origin;
    }
  }

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(isLoading => {
      this.isLoading = isLoading;
      this.footerButtonsFactory();
      this.chg.detectChanges();
    });

    this.route.params.subscribe((params: Params) => {
      this.consultationSummaryId = params['id'];
    });

    this.contextService.toggleStickyMode('normal');

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action.action === 'is-checkout-finished') {
        this.checkoutFinished = action.options.finished;
      }

      if (action?.target === 'checkout') {
        if (action.action === 'finish-order') {
          this.jumpToFinishScreen();
        }
      }
      if (action?.target === 'result') {
        if (action.action === 'pdf') {
          this.openConfirmationDocument();
        }
      }
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    if (this.contextService.previousUrl === RoutingPathMain.Checkout) {
      /**
       * a consultation that has just been finished
       */

      this.queryService.putFinishConsultation(this.clientService.consultationId).subscribe(data => {
        this.data = data;
        const hasContracts = data.consultationSummary.products.find(element => element.contractDocumentId);

        forkJoin([
          this.queryService.getCheckoutDocuments(this.clientService.consultationId),
          this.queryService.getConsultationById(this.clientService.bankHubId),
        ])
          .pipe(takeUntil(this.destroySubs))
          .subscribe(([documentData, consultation]: [any, GetConsultationResponseItem]) => {
            this.documentData = documentData.filter(x => x.type !== MediaTypeEnum.image);
            this.clientService.consultationStatus = consultation.status;

            this.chg.markForCheck();
          });

        if (hasContracts) {
          this.contractService.startSummaryContractStatusPolling(
            data.consultationSummary.products,
            this.clientService.bankHubId
          );
          this.contractService.pollingSummaryResult.subscribe(contractData => {
            this.contractStatus = contractData;
          });
        }
      });
    } else if (
      this.contextService.previousUrl === RoutingPathMain.ClientConsultations ||
      this.contextService.previousUrl === RoutingPathMain.Welcome ||
      this.contextService.previousUrl === RoutingPathMain.Init ||
      this.origin === 'init' ||
      this.origin === 'module'
    ) {
      /**
       * a previously finished consultation that has not been archived
       */
      this.queryService.getConsultationSummary(this.consultationSummaryId).subscribe(
        data => {
          this.summaryData = data;
          const hasContracts = !!data.consultationSummaryDTO.products.find(element => element.contractDocumentId);

          if (hasContracts) {
            this.contractService.startSummaryContractStatusPolling(
              data.consultationSummaryDTO.products,
              this.consultationSummaryId
            );
            this.contractService.pollingSummaryResult.subscribe(contractData => {
              this.contractStatus = contractData;
            });
          }
        },
        error => {
          if (error.status === 518) {
            const id = error.body.id;
            this.queryService.getArchivedPDF(this.clientService.bankHubId || id).subscribe(data => {
              const blob = new Blob([data], { type: 'application/pdf' });
              const setting = this.configService.getSettingsValue('downloadPDF');

              if (setting && setting === '1') {
                fileSaver.saveAs(blob, `Beratungsergebnis_${new Date().getTime()}`);
              } else {
                const isIPad = this.environmentLookupService.isInsideNativeShell();

                if (environment.platform === 'vp' && isIPad) {
                  this.ipadViewerService.showIpadPdfFromData(blob, 'Beratungsergebnis');
                } else {
                  const ref = window.open('about:blank', '_blank');
                  ref!.location.href = window.URL.createObjectURL(blob);
                }
              }
            });
            window.history.back();
          }
        }
      );
    } else {
      this.router.navigate([RoutingPathMain.Error], { state: { reason: 'checkoutNotFinished' } });
    }

    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (context !== null && context !== undefined) {
        this.context = context;
        this.chg.detectChanges();
      }
    });

    this.footerButtonsFactory();
  }

  ngOnDestroy(): void {
    this.contractService._stopPolling();
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public openConfirmationDocument(): void {
    if (
      this.clientService.consultationStatus === ConsultationStatusEnum.checkoutDone ||
      this.clientService.consultationStatus === ConsultationStatusEnum.archived ||
      this.consultationSummaryId
    ) {
      this.error = '';
      this.queryService.getArchivedPDF(this.clientService.bankHubId || this.consultationSummaryId).subscribe(data => {
        const blob = new Blob([data], { type: 'application/pdf' });
        const setting = this.configService.getSettingsValue('downloadPDF');

        if (setting && setting === '1') {
          fileSaver.saveAs(blob, `Beratungsergebnis_${new Date().getTime()}`);
        } else {
          const isIPad = this.environmentLookupService.isInsideNativeShell();

          if (environment.platform === 'vp' && isIPad) {
            this.ipadViewerService.showIpadPdfFromData(blob, 'Beratungsergebnis');
          } else {
            const ref = window.open('about:blank', '_blank');
            ref!.location.href = window.URL.createObjectURL(blob);
          }
        }
      });
    } else {
      this.error = 'Beratung wurde noch nicht beendet!';
    }
  }

  public openNotesAndDocumentsDocument(): void {
    if (
      this.clientService.consultationStatus === ConsultationStatusEnum.checkoutDone ||
      this.clientService.consultationStatus === ConsultationStatusEnum.archived ||
      this.consultationSummaryId
    ) {
      this.error = '';
      this.queryService.getNotesAndDocumentsPDF(this.clientService.bankHubId || this.consultationSummaryId).subscribe(
        data => {
          const blob = new Blob([data], { type: 'application/pdf' });
          const setting = this.configService.getSettingsValue('downloadPDF');

          if (setting && setting === '1') {
            fileSaver.saveAs(blob, `Notizen-und-Dokumente_${new Date().getTime()}`);
          } else {
            const isIPad = this.environmentLookupService.isInsideNativeShell();

            if (environment.platform === 'vp' && isIPad) {
              this.ipadViewerService.showIpadPdfFromData(blob, 'Notizen und Dokumente');
            } else {
              const ref = window.open('about:blank', '_blank');
              ref!.location.href = window.URL.createObjectURL(blob);
            }
          }
        },
        error => {
          this.snackBarService.openSnackBar({
            type: SnackbarType.ERROR,
            message: 'Für diese Beratung wurden keine Dokumente und Notizen gefunden.',
          });
        }
      );
    } else {
      this.error = 'Beratung wurde noch nicht beendet!';
    }
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }

  public goBackFromSummary(): void {
    if (environment.platform === 'vp') {
      if (this.contextService.isStickyMode) {
        this.contextService.toggleStickyMode('normal');
      }

      const kbmPath = `/kbm-agenda-appointments-overview/#`;
      const slug = `gespraechvorbereitung/${this.clientService.appointmentUUID}`;
      const host = `${window.location.protocol}//${window.location.host}`;
      const path = `${kbmPath}/${slug}`;
      window.location.href = `${host}/${path}`;
    } else {
      if (this.contextService.previousUrl === RoutingPathMain.ClientConsultations) {
        this.goToClientList();
      } else if (this.contextService.previousUrl === RoutingPathMain.Welcome) {
        this.goToWelcome();
      } else {
        // if everything fails
        window.history.go(-1);
      }
    }
  }
  public goToClientList(): void {
    this.router.navigate([RoutingPathMain.ClientList]);
  }

  public goToWelcome(): void {
    this.router.navigate([RoutingPathMain.Welcome]);
  }

  public onFinishCheckout(event: Event): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.queryService
      .postArchiveConsultation(this.clientService.bankHubId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(() => {
        this.doAction('checkout', 'finish-order');
      });
  }

  private jumpToFinishScreen(): void {
    this.router.navigate([RoutingPathMain.Finish]);
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (this.isLoading) {
        return;
      }

      if (event.id === 'result-finish-consultation') {
        this.onFinishCheckout(null);
      }
    }
  }

  private footerButtonsFactory(): void {
    if (!this.footerConfig) {
      return;
    }

    const currentConfig = cloneDeep(this.footerConfig);
    currentConfig.right.elements = [];
    currentConfig.left = { elements: [], type: FooterSectionType.HIDDEN, isHidden: true };
    currentConfig.centre = { elements: [], type: FooterSectionType.HIDDEN, isHidden: true };
    if (this.showCheckoutFinishOptions || this.showResult) {
      const currentFinish = cloneDeep(buttonFinishConsultation);
      currentFinish.inputs.config.disabled = this.isLoading;
      currentConfig.right.elements.push(currentFinish);
    }
    this.footerService.submitFooterConfig(currentConfig);
  }

  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get showStickyButton(): boolean {
    return this.contextService.showStickyButton;
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }

  /**
   * completed but not finished consultation
   * user has still to finish the consultation with a button on the result page
   */
  get showResult() {
    return this?.context && this.context === RoutingPathMain.Result;
  }

  /**
   * completed and finished consultation
   * * no button to finish the consultation with shown on the result page
   */
  get showResultSummary() {
    return this?.context && this.context.search(RoutingPathMain.ResultSummary) !== -1;
  }

  get showCheckoutFinishOptions() {
    return this?.context && this.context === RoutingPathMain.Checkout && this.checkoutFinished;
  }
}
