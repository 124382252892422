import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Profile } from '@domain/app/profile';
import { DataFieldTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import moment from 'moment';

interface ProfileElement {
  label: string;
  value: string;
  type?: DataFieldTypeEnum;
}

@Component({
  selector: 'short-profile',
  templateUrl: './short-profile.component.html',
  styleUrls: ['./short-profile.component.scss'],
})
export class ShortProfileComponent implements OnChanges {
  constructor(
    private clientService: ClientService,
    private contextService: ContextService
  ) {}

  @Input() profileData: Profile.GetProfileResponse;
  @Input() updateInProgress: boolean;

  public baseData: ProfileElement[] = [];
  public extendedData: ProfileElement[] = [];
  public logoReference: string = '';

  public dataFieldTypeEnum = DataFieldTypeEnum;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.profileData?.currentValue) {
      this.handleBaseData();
      this.handleExtendedData();
    }
  }

  public getTestcafeId(name = '') {
    return `profileElements-div-${name?.replace(/ /g, '')}`;
  }

  private handleBaseData(): void {
    const newBaseData: ProfileElement[] = [];
    newBaseData.push({ label: 'Firma', value: this.profileData.corporationName });
    newBaseData.push({ label: 'Titel', value: this.clientService.consultationName });
    newBaseData.push({ label: 'Version', value: this.clientService.instanceVersion });

    const date = moment(this.clientService.consultationDate).format('DD.MM.YYYY');
    const time = this.clientService.consultationTime ? `, ${this.clientService.consultationTime} Uhr` : '';

    newBaseData.push({
      label: 'Datum',
      value: `${date}${time}`.trim(),
    });
    this.baseData = [...newBaseData];
  }

  private handleExtendedData(): void {
    this.logoReference = this.profileData.logoReference;
    const dataFieldGroups = this.profileData.profileGroups.find(dfg => dfg.category === 'GENERIC')?.dataFieldGroups;

    if (!dataFieldGroups) {
      this.setVatDiscount();
      return;
    }

    const filteredDataFields = dataFieldGroups
      .filter(dfg => dfg.template === 'PLAIN')
      ?.flatMap(dfgs => dfgs.dataFields.filter(dfg => dfg.type !== 'PRICE'));

    if (!filteredDataFields || filteredDataFields.length === 0) {
      this.setVatDiscount();
      return;
    }

    this.extendedData = filteredDataFields.map(df => ({ label: df.name, value: df.values[0].value, type: df.type }));
    this.setVatDiscount();
  }

  private setVatDiscount(): void {
    if (this.profileData.hasOwnProperty('vatDiscount')) {
      this.extendedData.push({
        label: 'Vorsteuerabzugsberechtigt',
        value: this.profileData.vatDiscount ? 'Ja' : 'Nein',
      });
    }
  }

  get corporationName(): string {
    return this.profileData.corporationName || '';
  }

  get useMargin(): boolean {
    return this.contextService.isStickyMode || !this.contextService.isManagedFooter;
  }
}
