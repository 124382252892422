<div *ngIf="consultants.length > 0" class="d-flex flex-column flex-scroll w-100 h-100 align-items-center bg-white">
  <div class="d-flex flex-column mb-3 position-relative" style="margin-top: 7.5rem; width: 500px">
    <div class="d-flex justify-content-between w-100">
      <div *ngIf="!editMode" class="vr-headline-300 mb-5 mr-5">
        Neue Beratung {{ clientService.customerName ? 'bei ' + clientService.customerName : '' }}
      </div>
      <div *ngIf="editMode" class="vr-headline-300 mb-5 mr-5">
        Beratung {{ clientService.customerName ? 'bei ' + clientService.customerName : '' }} bearbeiten
      </div>
    </div>
    <ng-container *ngIf="newConsultForm">
      <div [formGroup]="newConsultForm" class="w-100">
        <div class="d-flex justify-content-between">
          <mat-form-field class="d-flex flex-column" [formGroup]="newConsultForm">
            <mat-label>Bitte wählen Sie ein Datum</mat-label>
            <input
              #dateField
              formControlName="date"
              [matDatepicker]="picker"
              [min]="currentDate"
              matInput
              readonly
              tabindex="-1"
              color="primary"
              placeholder="dd.mm.yyyy"
              aria-describedby="date-format-hint"
            />
            <mat-error>
              <kf-input-error
                *ngIf="newConsultForm.controls['date'].invalid"
                [message]="'Datum darf nicht in der Vergangenheit liegen'"
              >
              </kf-input-error>
            </mat-error>
            <mat-datepicker-toggle [for]="picker" matSuffix aria-label="Datum wählen">
              <kf-icon name="ic_calendar_24" matDatepickerToggleIcon></kf-icon>
            </mat-datepicker-toggle>
            <mat-datepicker kfDatepickerPositioning #picker></mat-datepicker>
          </mat-form-field>
          <div class="d-flex-flex-column">
            <mat-form-field class="notranslate">
              <mat-label>Uhrzeit</mat-label>
              <input
                [attr.data-testcafe]="'datepicker-input-endTime'"
                [format]="24"
                [ngxTimepicker]="startTime"
                [value]="''"
                matInput
                placeholder="SS:MM"
                readonly
                type="text"
                formControlName="time"
              />
              <mat-error>
                <kf-input-error
                  *ngIf="newConsultFormControls.time.invalid"
                  [message]="getFieldErrorMessage(newConsultFormControls.time, 'time')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>

            <ngx-material-timepicker
              #startTime
              [theme]="bg2Theme"
              [minutesGap]="5"
              [format]="24"
              [confirmBtnTmpl]="timeConfirmButton"
              [cancelBtnTmpl]="timeCancelButton"
            ></ngx-material-timepicker>
          </div>
        </div>

        <mat-form-field required>
          <mat-label>Name des Beraters</mat-label>
          <mat-select
            #selectUserId
            ngDefaultControl
            kfSelectMdc
            formControlName="userId"
            placeholder="Name des Beraters auswählen"
          >
            <mat-option *ngFor="let option of consultants" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="newConsultFormControls.userId.invalid"
              [message]="getFieldErrorMessage(newConsultFormControls.userId, 'userId')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="notranslate">
          <mat-label>Titel der Beratung</mat-label>
          <input
            [attr.data-testcafe]="'overlayConsultationNew-textfield-titelOfConsultation'"
            ngDefaultControl
            formControlName="title"
            matInput
            type="text"
          />
          <mat-error>
            <kf-input-error
              *ngIf="newConsultFormControls.title.invalid"
              [message]="getFieldErrorMessage(newConsultFormControls.title, 'title')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field required *ngIf="!editMode; else fixedInstance">
          <mat-label>Art der Beratung</mat-label>
          <mat-select
            #selectInstanceId
            kfSelectMdc
            ngDefaultControl
            formControlName="instanceId"
            (selectionChange)="setInstanceId($event)"
            placeholder="Bitte auswählen"
          >
            <mat-option *ngFor="let option of instanceIds" [value]="option.value">{{ option.label }}</mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="newConsultFormControls.instanceId.invalid"
              [message]="getFieldErrorMessage(newConsultFormControls.instanceId, 'title')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <div class="pb-5"></div>
        <button
          [attr.data-testcafe]="'overlayConsultationNew-button-chooseTopics'"
          class="mb-5"
          mat-flat-button
          color="primary"
          (click)="selectTopicCompile()"
        >
          <span>{{ 'Beratungsthemen ' + (editMode ? 'anpassen' : 'wählen') }}</span>
          <kf-icon name="ic_pfeil-rechts_24"></kf-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<button
  class="kf-popup-close"
  mat-icon-button
  aria-label="Abbrechen"
  (click)="onCancel()"
  kfTooltip="Klicken oder ESC drücken, um zu schließen"
  kfTooltipPosition="before"
  kfTooltipShow="hover_focus"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>

<!-- TEMPLATES -->
<ng-template #fixedInstance>
  <mat-form-field class="notranslate" *ngIf="instanceId">
    <mat-label>Art der Beratung</mat-label>
    <input ngDefaultControl formControlName="title" matInput type="text" [value]="instanceId.name" />
    <mat-error>
      <kf-input-error *ngIf="newConsultFormControls.title.invalid" [message]="'Fehler'"> </kf-input-error>
    </mat-error>
    <mat-error>
      <kf-input-error
        *ngIf="newConsultFormControls.title.invalid"
        [message]="getFieldErrorMessage(newConsultFormControls.title, 'title')"
      >
      </kf-input-error>
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #errorTemplate let-controlElement>
  <div
    *ngIf="submitted && !newConsultForm.valid && !!controlElement.errors"
    class="vr-text-extrasmall vr-color-error"
    style="min-height: 1.25rem"
  >
    <div *ngIf="!!controlElement.errors.required">Pflichtfeld</div>
    <div *ngIf="!!controlElement.errors.email || !!controlElement.errors.pattern">Ungültige E-Mail</div>
  </div>
</ng-template>

<ng-template #timeCancelButton>
  <button class="time-button">Abbrechen</button>
</ng-template>
<ng-template #timeConfirmButton>
  <button class="time-button">OK</button>
</ng-template>
