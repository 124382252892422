import { AnimationEvent } from '@angular/animations';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { Profile } from '@domain/app/profile';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { QueryService } from '@services/query-service/query.service';
import { RightSidenavService } from '@services/side-service/sidenav.service';
import { color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import { Subject, interval, lastValueFrom } from 'rxjs';
import { filter, mergeMap, startWith, take, takeUntil } from 'rxjs/operators';

import GetProfileResponse = Profile.GetProfileResponse;
import ProfileGroupDTO = Profile.ProfileGroupDTO;
import ProfileUpdateInProgress = Profile.ProfileUpdateInProgress;

export const getValue = (data: Profile.ProfileDataFieldItem, idx?: number): string => {
  if (!idx) idx = 0;
  if (data.values.length > 0) {
    if (data.values[idx]?.value === 'true') return 'Ja';
    if (data.values[idx]?.value === 'false') return 'Nein';
    return data.values[idx]?.value;
  } else {
    return '';
  }
};
@Component({
  selector: 'side-profile',
  templateUrl: './side-profile.component.html',
  styleUrls: ['./side-profile.component.scss'],
})
export class SideProfileComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() inputValues: any;

  private destroySubs = new Subject<void>();
  private _userProfile: GetProfileResponse | undefined;
  private ival = null;

  public customerLogo = `${this.assetPath}/images/icon-factory.svg`;
  public editModeActive = false;
  public formValid = true;
  public lastUpdated: string;
  public updateInProgress = false;
  public useQuestionDataFields = false;
  public questionDataFields: any;
  public panelOpen: Array<any> = [];

  public getValue = getValue;
  readonly color = color;
  readonly buttonIcon = libIcons;

  @ViewChild('profileEditor') profileEditor;
  @ViewChildren('profileGroupPanel') profileGroupPanels;
  @ViewChild('answeredQuestionsPanel') answeredQuestionsPanel;

  constructor(
    private rightSidenavService: RightSidenavService,
    private queryService: QueryService,
    private contextService: ContextService,
    private clientService: ClientService,
    private snackBarService: SnackBarTemplatesService
  ) {}

  public ngOnInit(): void {
    this.useQuestionDataFields = this.inputValues?.isQuestionDataField || false;

    if (this.useQuestionDataFields) {
      const itemId = this.inputValues?.itemId;
      this.queryService.getQuestionDataFields(this.clientService.consultationId, itemId).subscribe(data => {
        this.questionDataFields = data.questionDataFieldGroups.flatMap(x => x.dataFieldGroups);
      });
    } else {
      this.rightSidenavService.instance._animationStarted
        .pipe(
          takeUntil(this.destroySubs),
          filter((animation: AnimationEvent) => animation.fromState === 'void' && animation.toState === 'open'),
          mergeMap(() => this.queryService.getProfile(this.clientService.consultationId, this.showConfidentialGroups()))
        )
        .subscribe(data => {
          this.userProfile = Object.assign(data, { website: this.addHttpPrefix(data.website) } as Pick<
            GetProfileResponse,
            'website'
          >);

          // if there is no data, it should still render a Stammdaten tab for the vorsteuerabzugsberechtigt box
          this.setProfileDefaultFields();
          this.checkUpdateStatus();
        });
    }

    this.rightSidenavService.instance._animationStarted
      .pipe(
        takeUntil(this.destroySubs),
        filter((animation: AnimationEvent) => animation.fromState === 'open' && animation.toState === 'void')
      )
      .subscribe(() => (this.editModeActive = false));
  }

  ngAfterViewInit(): void {
    if (this.profileGroupPanels) {
      this.setPanelOpen('profileGroup0');
    }
  }

  public ngOnDestroy(): void {
    this.ival?.unsubscribe();
    this.ival = null;
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public onSave() {
    this.profileEditor.saveData();
  }

  public onClose() {
    this.rightSidenavService.close().then();
  }

  public onCloseEditor(event = null) {
    if (event) {
      this.assignProfileData(event);
      this.setProfileDefaultFields();
    }
    this.editModeActive = false;
    this.formValid = true;
  }

  public getBooleanText(value: boolean): string {
    return value ? 'Ja' : 'Nein';
  }

  public setFormValid(newValue: boolean) {
    this.formValid = newValue;
  }

  public displayableDataFieldGroups(profileGroup: ProfileGroupDTO) {
    return profileGroup.dataFieldGroups.filter(group => group);
  }

  public startUpdate() {
    this.updateProfileData();
    if (!this.updateInProgress) {
      this.updateInProgress = true;
      this.snackBarService.openSnackBar({ type: SnackbarType.SUCCESS, message: 'Datenaktualisierung gestartet.' });

      this.checkUpdateStatus();
    } else {
      this.snackBarService.openSnackBar({ type: SnackbarType.ALERT, message: 'Datenaktualisierung läuft gerade...' });
    }
  }

  public getTestcafeId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  public scrollIntoView(id: string): void {
    const elem = document.querySelector(id);

    if (elem) {
      elem.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  public setPanelOpen(id) {
    if (!this.isPanelOpen[id]) {
      this.panelOpen[id] = true;
      this.scrollIntoView(`#${id}`);
    }
  }

  public isPanelOpen(id) {
    return this.panelOpen[id];
  }

  private checkUpdateStatus() {
    this.ival = interval(20000)
      .pipe(startWith(0), take(3))
      .subscribe(async () => {
        const result: ProfileUpdateInProgress = await lastValueFrom(
          this.queryService.getProfileUpdateInProgress(this.clientService.consultationId)
        );
        this.updateInProgress = result.updateInProgress;
        if (!this.clientService.isTestConsultation) {
          this.lastUpdated = moment(result.lastUpdated).format('DD.MM.YYYY HH:mm');
        }
        if (this.updateInProgress === false) {
          this.refetchUserProfile();
          this.ival?.unsubscribe();
        }
      });
  }

  get userProfile(): Profile.GetProfileResponse | undefined {
    return this._userProfile;
  }

  set userProfile(profile: Profile.GetProfileResponse | undefined) {
    this.clientService.hasVatDiscount = profile.vatDiscount;
    this._userProfile = profile;
  }

  // May be used again
  get hasEditableFields(): boolean {
    if (!this.userProfile.profileGroups) {
      return false;
    }
    return (
      this.userProfile.profileGroups?.filter(profileData =>
        profileData.dataFieldGroups?.find(dataFieldGroups =>
          dataFieldGroups.dataFields?.find(dataField => dataField.editable)
        )
      ).length > 0
    );
  }

  get isTestConsultation(): boolean {
    return this.clientService.isTestConsultation;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }

  get isQuestionField() {
    return this.inputValues?.isQuestionField || false;
  }

  private updateProfileData() {
    this.queryService.putUpdateProfileData(this.clientService.consultationId).subscribe();
  }

  private showConfidentialGroups(): boolean {
    return !(this.clientService.consultationStatusType === 'main');
  }

  private refetchUserProfile(): void {
    this.queryService.getProfile(this.clientService.consultationId, this.showConfidentialGroups()).subscribe(data => {
      this.assignProfileData(data);
      this.setProfileDefaultFields();
    });
  }

  private assignProfileData(data: GetProfileResponse): void {
    this.userProfile = Object.assign(data, { website: this.addHttpPrefix(data.website) } as Pick<
      GetProfileResponse,
      'website'
    >);
  }

  private setProfileDefaultFields(): void {
    if (this.userProfile.profileGroups.every(x => x.category !== 'GENERIC')) {
      this.userProfile.profileGroups.unshift({
        category: 'GENERIC',
        dataFieldGroups: [],
      } as Profile.ProfileGroupDTO);
    }
  }

  private addHttpPrefix(url: string): string {
    if (!!url && !/^http[s]?:\/\//.test(url)) {
      return `http://${url}`;
    }
    return url;
  }

  get useMargin(): boolean {
    return this.contextService.isStickyMode || !this.contextService.isManagedFooter;
  }
}
