<div class="d-flex flex-column flex-scroll w-100 h-100 align-items-center bg-white">
  <div class="d-flex flex-column mb-3 position-relative" style="margin-top: 7.5rem; width: 500px">
    <div class="d-flex justify-content-between w-100">
      <div class="vr-headline-300 mb-5 mr-5">
        {{
          editMode
            ? importMode && !importErrors && importIsRunning
              ? 'Kunden importieren'
              : 'Kunden bearbeiten'
            : 'Neuen Kunden anlegen'
        }}
      </div>
      <button
        *ngIf="!importMode"
        class="position-absolute"
        style="top: 0; right: -200px"
        mat-stroked-button
        color="primary"
        (click)="onImportCustomer()"
        [disabled]="importIsRunning"
      >
        <span>Kunden importieren</span>
      </button>
    </div>
    <ng-container *ngIf="newCustomerForm">
      <form [formGroup]="newCustomerForm" class="w-100">
        <div class="vr-headline-200 mb-3">Stammdaten</div>
        <mat-form-field class="notranslate">
          <mat-label>Name</mat-label>
          <input
            ngDefaultControl
            formControlName="corporationName"
            matInput
            type="text"
            (change)="nameIsFound($event)"
            [disabled]="importIsRunning"
          />
          <mat-error>
            <kf-input-error
              *ngIf="newCustomerForm.controls.corporationName.invalid"
              [message]="getFieldErrorMessage(newCustomerForm.controls.corporationName, 'corporationName')"
            >
            </kf-input-error>
            <kf-input-error *ngIf="duplicateName" [message]="'Dieser Kunde existiert möglicherweise bereits'">
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="notranslate">
          <mat-label>Kunden-Nr.</mat-label>
          <input
            ngDefaultControl
            formControlName="account"
            matInput
            type="text"
            (change)="numberIsFound($event)"
            [disabled]="importIsRunning || editMode"
          />
          <mat-error>
            <kf-input-error
              *ngIf="newCustomerForm.controls.account.invalid"
              [message]="getFieldErrorMessage(newCustomerForm.controls.account, 'account')"
            >
            </kf-input-error>
            <kf-input-error *ngIf="duplicateNumber" [message]="'Diese Kunden-Nr. ist bereits vergeben'">
            </kf-input-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="notranslate">
          <mat-label>Webseite (optional)</mat-label>
          <input ngDefaultControl formControlName="website" matInput type="text" [disabled]="importIsRunning" />
          <mat-error>
            <kf-input-error
              *ngIf="newCustomerForm.controls.website.invalid"
              [message]="getFieldErrorMessage(newCustomerForm.controls.website, 'website')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <ng-template *ngTemplateOutlet="errorTemplate; context: { $implicit: mainControl.website }"></ng-template>
        <div class="vr-text-small">Logo</div>
        <div class="d-flex align-self-start client-logo mt-1 w-100">
          <div *ngIf="logoString" class="d-flex w-50 pr-3">
            <img class="image-content" src="{{ logoString }}" />
          </div>
          <div class="w-50">
            <vr-form-upload
              [class.width.%]="100"
              ngDefaultControl
              [label]="logoString ? 'Logo entfernen' : ''"
              [placeholder]="'Bild wählen'"
              [value]="logoFile ? [logoFile] : []"
              [fileAccept]="fileUploadConfig.acceptedFiles"
              [fileLimit]="fileUploadConfig.fileLimit"
              [fileSizeLimit]="fileUploadConfig.sizeLimit"
              (valueChanged)="setFile($event)"
            ></vr-form-upload>
          </div>
        </div>
        <div *ngIf="!logoString" class="vr-text-extrasmall">Datei darf nicht größer als {{ getSizeInKB() }} sein.</div>
        <div class="d-flex flex-row w-100 justify-content-between mb-4">
          <div>
            <ng-template *ngTemplateOutlet="errorTemplate; context: { $implicit: mainControl.discount }"></ng-template>
            <mat-checkbox
              kfFocus
              ngDefaultControl
              formControlName="discount"
              [checked]="mainControl.discount.value"
              [disabled]="importIsRunning"
              [attr.data-testcafe]="'discount'"
            >
              Vorsteuerabzugsberechtigt
            </mat-checkbox>
          </div>
        </div>
        <ng-template *ngTemplateOutlet="dataFieldGroups"></ng-template>
        <div class="mb-5 mt-4 button-row">
          <div class="button-row-content">
            <button
              mat-flat-button
              color="primary"
              [disabled]="importIsRunning || !newCustomerForm.valid || duplicateNumber"
              (click)="importIsRunning ? null : onAddCustomer()"
            >
              <span>{{ editMode ? 'Kunden aktualisieren' : 'Kunden anlegen' }}</span>
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>

<button
  class="kf-popup-close"
  mat-icon-button
  [attr.aria-label]="importIsRunning ? 'Zurück' : 'Abbrechen'"
  (click)="onCancel()"
>
  <kf-icon name="ic_schliessen_24"></kf-icon>
</button>

<!-- TEMPLATES -->
<ng-template #errorTemplate let-controlElement>
  <div
    *ngIf="submitted && !newCustomerForm.valid && !!controlElement.errors"
    class="vr-text-extrasmall vr-color-error"
    style="min-height: 1.25rem"
  >
    <div *ngIf="!!controlElement.errors.required">Pflichtfeld</div>
    <div *ngIf="!!controlElement.errors.email || !!controlElement.errors.pattern">Ungültige E-Mail</div>
  </div>
</ng-template>

<ng-template #dataFieldGroups>
  <ng-container *ngIf="datafieldGroups">
    <div *ngFor="let datafieldGroup of datafieldGroups; let lastGroup = last" [class.mb-3]="!lastGroup">
      <div *ngIf="datafieldGroup.dataFields.length > 0">
        <div class="vr-headline-200 mb-3">{{ datafieldGroup.name }}</div>
        <div class="d-flex flex-column justify-content-between">
          <mat-form-field
            class="notranslate"
            [class.mb-3]="!last"
            *ngFor="let datafield of datafieldGroup.dataFields; let last = last"
          >
            <mat-label>{{ datafield.name }}</mat-label>
            <input
              ngDefaultControl
              [formControlName]="datafield.name"
              matInput
              type="text"
              [disabled]="importIsRunning"
              [value]="datafield.value"
              (change)="setNewDataFieldValue(datafieldGroup.id, datafield.dataFieldId, $event)"
            />
            <mat-error>
              <kf-input-error
                *ngIf="newCustomerForm.controls[datafield.name].invalid"
                [message]="getFieldErrorMessage(newCustomerForm.controls[datafield.name], datafield.name)"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
