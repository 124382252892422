<div
  id="bgzv-frontend-main"
  class="w-100 d-flex flex-column flex-nowrap"
  [class.snap]="!isEntryPoint"
  [class.is-aws]="isAWS"
>
  <div id="bgzv-content" [class.bgzv-frontend-main-content]="!isEntryPoint">
    <mat-sidenav-container
      [hasBackdrop]="true"
      (backdropClick)="onSideNavClose()"
      [class.app-height]="!isEntryPoint"
      autosize
    >
      <mat-sidenav #leftSidenav [autoFocus]="true" mode="over" [position]="'start'" class="sidenav-topper">
        <side-agenda-edit *ngIf="leftSidenavService.context === 'edit'"></side-agenda-edit>
      </mat-sidenav>
      <mat-sidenav #rightSidenav [autoFocus]="true" mode="over" [position]="'end'" class="sidenav-topper">
        <side-profile
          *ngIf="rightSidenavService.context === 'profile'"
          [inputValues]="profileInputValues"
        ></side-profile>

        <side-chat *ngIf="rightSidenavService.context === 'chat'"></side-chat>
        <side-consultation-toggle *ngIf="rightSidenavService.context === 'toggle'"></side-consultation-toggle>
        <side-recommendations-details
          *ngIf="rightSidenavService.context === 'recommendations'"
          [inputValues]="groupData"
        ></side-recommendations-details>
        <side-checkout
          *ngIf="rightSidenavService.context === 'checkout'"
          [inputValues]="checkoutInputValues"
        ></side-checkout>
        <side-product-details
          *ngIf="rightSidenavService.context === 'product'"
          [inputValues]="productDetailInputValues"
        ></side-product-details>
        <side-product-extras
          *ngIf="rightSidenavService.context === 'productExtras'"
          [inputValues]="productExtraInputValues"
        ></side-product-extras>
        <side-transition
          *ngIf="rightSidenavService.context === 'transition'"
          [inputValues]="transitionInputValues"
        ></side-transition>
        <side-task *ngIf="rightSidenavService.context === 'task'" [inputValues]="taskInputValues"></side-task>
        <side-task-indiv
          *ngIf="rightSidenavService.context === 'task-indiv'"
          [inputValues]="taskInputValues"
        ></side-task-indiv>
        <side-transition-indiv
          *ngIf="rightSidenavService.context === 'transition-indiv'"
          [inputValues]="transitionInputValues"
        ></side-transition-indiv>
        <side-notes *ngIf="rightSidenavService.context === 'notes'"></side-notes>
      </mat-sidenav>

      <mat-sidenav-content>
        <global-loader *ngIf="initActive" [message]="initMessage"></global-loader>
        <screen-entry *ngIf="isEntryPoint"></screen-entry>

        <div *ngIf="!isEntryPoint" class="position-relative parent">
          <div *ngIf="dismissOnClick" (click)="dismissOnClick && rightSidenavService.close()" class="grey-topper"></div>

          <div *ngIf="!initActive" class="d-flex vw-100" [class.app-height]="!isEntryPoint">
            <router-outlet></router-outlet>
          </div>

          <div [hidden]="!overlayActive" class="overlay-topper" [class.app-height]="!isEntryPoint">
            <router-outlet name="overlay"></router-outlet>
          </div>
          <div *ngIf="isOffline" class="offline-topper"></div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

    @if (!this.managedFooter) {
      <kbm-shared-footer
        [position]="footerPosition"
        [footerConfiguration]="footerConfig"
        (actionDispatched)="onFooterAction($event)"
      ></kbm-shared-footer>
    }
  </div>
</div>

<snackbar-templates></snackbar-templates>
