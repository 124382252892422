import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { Profile } from '@domain/app/profile';
import {
  ExpandedTopic,
  SelectedTopicItem,
  SubtopicOverviewItem,
  TopicOverviewItem,
  TopicSelectionResponse,
} from '@domain/app/topic.domain';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { QueryService } from '@services/query-service/query.service';
import { SearchService } from '@services/search-service/search.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { TopicService } from '@services/topic-service/topic-service';
import {
  baseConfig,
  buttonConfigBack,
  buttonConfigFinish,
  buttonConfigProceed,
  buttonConfigSkip,
} from '@utils/footer-config';
import { color, libIcons } from 'bgzv-frontend-library';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { Subject, Subscription, interval, lastValueFrom } from 'rxjs';
import { startWith, take, takeUntil } from 'rxjs/operators';

import ProfileUpdateInProgress = Profile.ProfileUpdateInProgress;

interface SelectedTopicIds {
  consultant: string[];
  customer: string[];
  consultation: string[];
}

@Component({
  selector: 'screen-topic-select',
  templateUrl: './screen-topic-select.component.html',
  styleUrls: ['./screen-topic-select.component.scss'],
})
export class ScreenTopicSelectComponent implements OnInit, OnDestroy {
  private subAlive = new Subject<void>();

  public expandedTopics: ExpandedTopic[];
  public footerConfig: FooterConfig = baseConfig;

  public step: number = 0;
  public maximumBubbles: number;
  public consultationSubtopics: TopicSelectionResponse;
  public consultantSubtopics: TopicSelectionResponse;
  public customerSubtopics: TopicSelectionResponse;

  public profileData: Profile.GetProfileResponse;
  public updateInProgress = false;
  public lastUpdated: string;

  private ival = null;
  private _dialogRef = null;
  private _dialogSub: Subscription;
  private selectedSubtopicIds: SelectedTopicIds = {
    consultant: [],
    customer: [],
    consultation: [],
  };

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    private router: Router,
    private queryService: QueryService,
    private clientService: ClientService,
    private contextService: ContextService,
    private searchService: SearchService,
    private topicService: TopicService,
    private configService: ConfigService,
    private footerService: SharedFooterService,
    private chg: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    if (this.quickstartMode) {
      this.contextService.lastQuestionGroupId.next('-1');
      this.contextService.prepToggleMode = 'main';

      this.router.navigate([RoutingPathMain.Consultation]);
    } else if (this.configService.getSettingsValue('disablePreparationProcess') === '2') {
      this.contextService.lastQuestionGroupId.next('-1');
      this.contextService.prepToggleMode = 'main';

      this.router.navigate([`${RoutingPathMain.TopicOverview}/${this.clientService.bankHubId}`]);
    }
  }

  async ngOnInit(): Promise<void> {
    this.contextService.lastQuestionGroupId.next('-1');
    await this.requestSubtopicData();

    if (this.isMainMode) {
      this.step = 2;
    }

    this.searchService.searchQuery = '';
    this.searchService.selectionType = 'all';

    this.footerConfigFactory();

    const topics = await lastValueFrom(this.searchService.fetchData().pipe(takeUntil(this.subAlive)));

    await this.topicService.mapTopicIcons(topics);
    this.expandedTopics = topics
      ?.filter(top => !top.availableForPreliminary)
      .map(top => ({
        [top.id]: true,
      }));

    this.requestProfileData();

    this.footerService.footerActionDispatched.pipe(takeUntil(this.subAlive)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.contextService.measureStartupTime('stop');
  }

  ngOnDestroy(): void {
    this.subAlive.next();
    this.subAlive.unsubscribe();
    this._dialogSub?.unsubscribe();
  }

  /// Footer actions

  public async onContinue(): Promise<void> {
    const requestObject = this.subtopicRequestObjectFactory(this.selectedSubtopicIds);

    await lastValueFrom(
      this.queryService.putSubtopicDataByConsultationId(this.clientService.consultationId, requestObject)
    );

    this.searchService.searchQuery = '';

    if (this.exitTopicSelection()) {
      await this.setConsultationStatus();
      await this.putRequestObject();
      const path = this.isPrepMode ? RoutingPathPrep : RoutingPathMain;
      this.contextService.skipSelection = true;
      if (this.startingPointMainConsultation && this.isMainMode) {
        if (this.selectedSubtopicIds.consultation.length === 0) {
          const data = {
            copyText: 'Bitte Beratungsthemen auswählen',
            denyText: 'Schließen',
            context: 'noTopic',
          } as DialogConfirmData;

          this._dialogRef = this.dialogService.openDialogConfirm(data);

          this._dialogSub = this._dialogRef.afterClosed().subscribe(result => {
            this._dialogRef = null;
          });
        } else {
          this.router.navigate([RoutingPathMain.Consultation]);
        }
      } else {
        this.router.navigate([`${path.TopicOverview}/${this.clientService.bankHubId}`]);
      }
    } else if (this.step === 0 && this.consultantSubtopics?.available.length === 0) {
      this.step = 2;
    } else {
      this.step += 1;
    }
    this.footerConfigFactory();
  }

  public async onBack(): Promise<void> {
    const requestObject = this.subtopicRequestObjectFactory(this.selectedSubtopicIds);

    await lastValueFrom(
      this.queryService.putSubtopicDataByConsultationId(this.clientService.consultationId, requestObject)
    );

    this.searchService.searchQuery = '';

    if (this.step === 0) {
      await this.putRequestObject();
      await this.setConsultationStatus();
      const path = this.isPrepMode ? RoutingPathPrep : RoutingPathMain;
      this.contextService.skipSelection = true;
      this.router.navigate([`${path.TopicOverview}/${this.clientService.bankHubId}`]);
    }

    if (this.step === 2 && this.consultantSubtopics.available.length === 0) {
      this.step -= 2;
    } else {
      this.step = Math.max(0, (this.step -= 1));
    }
    this.footerConfigFactory();
  }

  public onFooterAction(event: FooterAction): void {
    if (event.owner === 'bgzv') {
      if (event.id === 'topic-select-finish') {
        this.onContinue();
      } else if (event.id === 'topic-select-proceed') {
        this.onContinue();
      } else if (event.id === 'topic-select-back') {
        this.onBack();
      } else if (event.id === 'topic-select-skip') {
        this.onBack();
      }
    }
  }

  /// Topic selection

  public onTopicSelected(topic: TopicOverviewItem) {
    topic.subtopics.map(subtopic => {
      if (!subtopic.selected) {
        if (!topic.availableForPreliminary && !topic.consultantTopic) {
          this.consultationSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['consultation'] = [
            ...new Set([...this.selectedSubtopicIds['consultation'], ...topic.subtopics.map(x => x.id)]),
          ];
        } else if (topic.consultantTopic) {
          this.consultantSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['consultant'] = [
            ...new Set([...this.selectedSubtopicIds['consultant'], ...topic.subtopics.map(x => x.id)]),
          ];
        } else if (topic.availableForPreliminary) {
          this.customerSubtopics.selected.push(subtopic);
          this.selectedSubtopicIds['customer'] = [
            ...new Set([...this.selectedSubtopicIds['customer'], ...topic.subtopics.map(x => x.id)]),
          ];
        }
        subtopic.icon = topic.icon;
        subtopic.selected = true;
      }
    });
  }

  public onTopicDeselect(topic: TopicOverviewItem, type: string) {
    const forDeselectSubtopicsIds = new Set(topic.subtopics.map(obj => obj.id));
    this.selectedSubtopicIds[type] = [...this.selectedSubtopicIds[type].filter(id => !forDeselectSubtopicsIds.has(id))];
    switch (type) {
      case 'consultation': {
        this.consultationSubtopics.selected = this.consultationSubtopics.selected.filter(
          x => !forDeselectSubtopicsIds.has(x.id)
        );
        break;
      }
      case 'consultant': {
        this.consultantSubtopics.selected = this.consultantSubtopics.selected.filter(
          x => !forDeselectSubtopicsIds.has(x.id)
        );
        break;
      }
      case 'customer': {
        this.customerSubtopics.selected = this.customerSubtopics.selected.filter(
          x => !forDeselectSubtopicsIds.has(x.id)
        );
        break;
      }
    }
    topic.subtopics.map(x => (x.selected = false));
  }

  public onTopicExpanded(topic: ExpandedTopic) {
    const topicId = Object.keys(topic)[0];
    if (this.expandedTopics) {
      const oldExpanded = [...this.expandedTopics];
      const idxOfTopic = oldExpanded.findIndex(obj => obj.hasOwnProperty(topicId));
      this.expandedTopics = Object.assign(oldExpanded, { [idxOfTopic]: topic });
    }
  }

  /// Subtopic selection

  public onSubtopicSelected(subtopic: SubtopicOverviewItem, type: string) {
    subtopic.selected = true;
    this.selectedSubtopicIds[type].push(subtopic.id);
    switch (type) {
      case 'consultation': {
        this.consultationSubtopics.selected.push(subtopic);
        break;
      }
      case 'consultant': {
        this.consultantSubtopics.selected.push(subtopic);
        break;
      }
      case 'customer': {
        this.customerSubtopics.selected.push(subtopic);
        break;
      }
    }
  }

  public onSubtopicDeselected(subtopic: SubtopicOverviewItem, type: string) {
    subtopic.selected = false;
    this.selectedSubtopicIds[type] = this.selectedSubtopicIds[type].filter(x => x !== subtopic.id);
    switch (type) {
      case 'consultation': {
        this.consultationSubtopics.selected = this.consultationSubtopics.selected.filter(x => x.id !== subtopic.id);
        break;
      }
      case 'consultant': {
        this.consultantSubtopics.selected = this.consultantSubtopics.selected.filter(x => x.id !== subtopic.id);
        break;
      }
      case 'customer': {
        this.customerSubtopics.selected = this.customerSubtopics.selected.filter(x => x.id !== subtopic.id);
        break;
      }
    }
  }

  public async onSelectedSubtopicsReordered(subtopics: SubtopicOverviewItem[], type: string): Promise<void> {
    this.selectedSubtopicIds[type] = subtopics.map(x => x.id);
  }

  public getTestcafeId(name = '') {
    return `screenTopic-div-${name?.replace(/ /g, '')}`;
  }

  private async setConsultationStatus(): Promise<void> {
    if (this.clientService.consultationStatus === ConsultationStatusEnum.created) {
      const consultationId = this.clientService.consultationId;
      const consultationStatus = ConsultationStatusEnum.inPreparation;
      const newStatus = await lastValueFrom(
        this.queryService.putConsultationStatus(consultationId, { consultationStatus })
      );
      this.clientService.consultationStatus = newStatus.consultationStatus;
      this.clientService.prepDataSent = false;
    }
    return null;
  }

  private async putRequestObject(): Promise<void> {
    const requestObject = this.subtopicRequestObjectFactory(this.selectedSubtopicIds);

    await lastValueFrom(
      this.queryService.putSubtopicDataByConsultationId(this.clientService.consultationId, requestObject)
    );

    this.requestSubtopicData();
  }

  private async requestSubtopicData(): Promise<void> {
    const subtopics = await lastValueFrom(
      this.queryService.getSubtopicDataByConsultationId(this.clientService.consultationId)
    );

    this.consultationSubtopics = this.topicService.consultationSubtopics(subtopics);
    this.consultantSubtopics = this.topicService.consultantSubtopics(subtopics);
    this.customerSubtopics = this.topicService.customerSubtopics(subtopics);

    this.selectedSubtopicIds.consultation = this.topicService.consultationSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.consultant = this.topicService.consultantSelectedSubtopicIds(subtopics);
    this.selectedSubtopicIds.customer = this.topicService.customerSelectedSubtopicIds(subtopics);

    this.subtopicRequestObjectFactory(this.selectedSubtopicIds);
    this.getMaxBubbles(this.consultantSubtopics, this.customerSubtopics);
  }

  private subtopicRequestObjectFactory(data: SelectedTopicIds): SelectedTopicItem {
    return {
      selectedSubtopicIds: [...data.consultant, ...data.customer, ...data.consultation],
    } as SelectedTopicItem;
  }

  private getMaxBubbles(consultant: TopicSelectionResponse, customer: TopicSelectionResponse): void {
    this.maximumBubbles = (consultant.available.length > 0 ? 1 : 0) + (customer.available.length > 0 ? 1 : 0);
  }

  private async requestProfileData() {
    this.queryService.getProfile(this.clientService.consultationId, true).subscribe(profileData => {
      this.profileData = profileData;
      this.checkUpdateStatus();
    });
  }

  private checkUpdateStatus() {
    this.ival = interval(20000)
      .pipe(startWith(0), take(3))
      .subscribe(async () => {
        const result: ProfileUpdateInProgress = await lastValueFrom(
          this.queryService.getProfileUpdateInProgress(this.clientService.consultationId)
        );
        this.updateInProgress = result.updateInProgress;
        this.chg.detectChanges();
        if (!this.clientService.isTestConsultation) {
          this.lastUpdated = moment(result.lastUpdated).format('DD.MM.YYYY HH:mm');
        }
        if (this.updateInProgress === false) {
          this.refetchUserProfile();
          this.ival?.unsubscribe();
        }
      });
  }

  private refetchUserProfile(): void {
    this.queryService
      .getProfile(this.clientService.consultationId, true)
      .subscribe(profileData => (this.profileData = profileData));
  }

  private exitTopicSelection(): boolean {
    return (
      (this.customerSubtopics?.available.length === 0 && this.consultantSubtopics?.available.length === 0) ||
      (this.step === 1 && this.customerSubtopics?.available.length === 0) ||
      this.step === 2
    );
  }

  private footerConfigFactory(): void {
    const currentConfig = cloneDeep(this.footerConfig);
    const rightButtonElements = [];

    if (this.isPrepMode) {
      const buttonConfig1 = this.step === 0 ? buttonConfigSkip : buttonConfigBack;
      rightButtonElements.push(buttonConfig1);
    }

    const buttonConfig2 = this.step >= this.maximumBubbles ? buttonConfigFinish : buttonConfigProceed;
    rightButtonElements.push(buttonConfig2);
    currentConfig.right.elements = rightButtonElements;

    this.footerService.submitFooterConfig(currentConfig);
  }

  get headline(): string {
    const headlines = [
      'Beratungsthemen wählen',
      'Themen für Berater-Vorbereitung wählen',
      'Themen für Kunden-Vorbereitung wählen',
    ];

    if (this.isPrepMode) {
      return headlines[this.step];
    } else if (this.isMainMode) {
      return headlines[0];
    }
    return headlines[0];
  }

  get isPrepMode(): boolean {
    return this.clientService.consultationStatusType === 'prep' || this.contextService.currentMode === 'prep';
  }

  get isMainMode(): boolean {
    return this.clientService.consultationStatusType === 'main' || this.contextService.currentMode === 'main';
  }

  get quickstartMode(): boolean {
    return this.configService.quickstartMode;
  }

  get startingPointMainConsultation(): boolean {
    return this.configService.startingPointMainConsultation;
  }
}
