<div class="mb-4">
  <ng-template *ngTemplateOutlet="search"></ng-template>
</div>

<div class="d-flex align-items-center justify-content-between mb-4">
  <ng-template *ngTemplateOutlet="filter"></ng-template>
  <ng-template *ngTemplateOutlet="showAllCustomersToggle"></ng-template>
</div>

<div class="mb-4">
  <ng-template *ngTemplateOutlet="entries"></ng-template>
</div>

<!-- ENTRIES -->
<ng-template #entries>
  <div class="list" tabindex="-1" aria-live="polite">
    <ng-container *ngIf="!isRequesting && !isFiltering; else requestingTemplate">
      <ng-container *ngIf="filteredData.length > 0; else noConsultationsTemplate">
        @for (customer of getUniqueFilteredData(filteredData); track customer.customerNumber) {
          <mat-expansion-panel
            *ngIf="customer.consultations.length !== 0"
            #matPanel
            class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
            [expanded]="isExpanded(customer.customerNumber)"
          >
            <mat-expansion-panel-header
              expandedHeight="4rem"
              collapsedHeight="4rem"
              (click)="setExpandedMenuSet(customer.customerNumber)"
              (keyup)="setExpandedMenuSet(customer.customerNumber, $event)"
            >
              <mat-panel-title>
                <div class="d-flex align-items-center">
                  <div
                    class="mr-2"
                    [attr.data-testcafe]="getTestcafeId('div', customer.customerName, customer.customerNumber)"
                  >
                    <strong class="font-weight-bold d-block text-truncate">{{ customer.customerName }}</strong>
                    <span class="d-block text-truncate">Kunden-Nr.: {{ customer.customerNumber }}</span>
                  </div>
                  <div
                    [attr.data-testcafe]="getTestcafeId('badge', customer.customerName, customer.customerNumber)"
                    class="tag-info tag-neutral mb-1 ml-3"
                    [class.hidden]="!matPanel.expanded"
                  >
                    <div class="tag-info-text">
                      {{
                        customer.consultations?.length +
                          (customer.consultations?.length > 1 ? ' Beratungen ' : ' Beratung') +
                          ' angelegt'
                      }}
                    </div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            @if (isExpanded(customer.customerNumber)) {
              @for (consultation of customer.consultations; track consultation.id; let last = $last) {
                <div class="consultation-border d-md-flex justify-content-between" [class.mb-3]="!last">
                  <div class="d-flex flex-column w-100">
                    <div
                      class="vr-color-brand-primary font-weight-bold mb-2 cursor-pointer"
                      [attr.data-testcafe]="getTestcafeId('div', consultation.title, consultation.id)"
                      (click)="jumpToConsultation(consultation)"
                    >
                      {{ consultation.title }}
                    </div>
                    <div
                      class="cursor-pointer"
                      [attr.data-testcafe]="
                        getTestcafeId('div', consultation.appointmentDate, consultation.appointmentTime)
                      "
                      (click)="jumpToConsultation(consultation)"
                    >
                      {{ formatDate(consultation.appointmentDate) }} {{ consultation.appointmentTime }} Uhr
                      {{ getInstanceName(consultation) }}
                    </div>
                  </div>

                  <div class="d-flex align-items-center justify-content-end mt-3 mt-md-0 button-row">
                    <div class="button-row-content">
                      <button
                        [attr.data-testcafe]="getTestcafeId('button', getStatus(consultation.status), consultation.id)"
                        mat-stroked-button
                        color="primary"
                        (click)="jumpToConsultation(consultation)"
                      >
                        <span>{{ getStatus(consultation.status) }}</span>
                      </button>

                      <div #moreButton>
                        <kf-compact-button
                          [attr.data-testcafe]="'consultationOverview-button-more-' + consultation.id"
                          [matMenuTriggerFor]="menu"
                          type="secondary"
                          iconName="ic_menue_mehr_vertikal_24"
                          [attr.aria-label]="'Kontextmenü öffnen'"
                        ></kf-compact-button>

                        <mat-menu #menu="matMenu">
                          <button
                            *ngIf="appointmentModState(consultation)"
                            [attr.data-testcafe]="'dialogContext-button-editAppointment'"
                            (click)="editAppointment(consultation)"
                            mat-menu-item
                          >
                            Termin bearbeiten
                          </button>

                          <button
                            *ngIf="consultationIsPrepped(consultation)"
                            [attr.data-testcafe]="'dialogContext-button-startPrelim'"
                            (click)="startPrelim(consultation)"
                            mat-menu-item
                          >
                            Vorbefragung starten
                          </button>

                          <!-- result/ result summary -->
                          <ng-container *ngIf="consultationIsDone(consultation)">
                            <button
                              (click)="getConsultationSummary(consultation)"
                              [attr.data-testcafe]="'dialogContext-button-consultationResult'"
                              mat-menu-item
                            >
                              Beratungsergebnis
                            </button>
                          </ng-container>

                          <!-- delete -->
                          <ng-container *ngIf="consultationIsCreated(consultation)">
                            <button
                              [attr.data-testcafe]="'dialogContext-button-delete'"
                              mat-menu-item
                              (click)="confirmDeletion(consultation)"
                            >
                              Löschen
                            </button>
                          </ng-container>

                          <!-- archive -->
                          <ng-container *ngIf="consultationIsNotArchived(consultation)">
                            <button
                              [attr.data-testcafe]="'dialogContext-button-archive'"
                              mat-menu-item
                              (click)="confirmArchiving(consultation)"
                            >
                              Archivieren
                            </button>
                          </ng-container>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              }
            }
          </mat-expansion-panel>
        }
      </ng-container>
    </ng-container>
  </div>
</ng-template>
<!-- /ENTRIES -->

<!-- SEARCH FIELD -->
<ng-template #search>
  <div class="w-100">
    <mat-form-field>
      <mat-label>Suchfeld nach Titel, Kundennamen oder Kunden-Nr., Datum</mat-label>
      <input
        #searchInput
        [attr.data-testcafe]="'consultationOverview-input-search'"
        type="search"
        [formControl]="searchControl"
        placeholder="Suche nach Titel, Kundennamen oder Kunden-Nr., Datum"
        matInput
      />
      <ng-template #magnifier>
        <button disableRipple mat-icon-button matSuffix tabindex="-1" aria-label="Suchen">
          <kf-icon name="ic_search_24"></kf-icon>
        </button>
      </ng-template>
      <button
        *ngIf="searchControl.value; else magnifier"
        (click)="searchControl.reset()"
        aria-label="Sucheingaben löschen"
        disableRipple
        mat-icon-button
        matSuffix
      >
        <kf-icon name="ic_clear_24"></kf-icon>
      </button>
    </mat-form-field>
  </div>
</ng-template>
<!-- /SEARCH FIELD -->

<!-- FILTER -->
<ng-template #filter>
  <article class="d-flex justify-content-start align-items-center mb-4">
    <h2 class="mb-0 mr-3">Filter:</h2>
    <mat-chip-listbox kfChipsSet multiple="false" aria-label="Filter">
      <mat-chip-option kfChips (click)="setStatusFilter(statusFilterStatus.preparation)">
        Vorbereitung
      </mat-chip-option>
      <mat-chip-option kfChips (click)="setStatusFilter(statusFilterStatus.open)"> Beratung </mat-chip-option>
      <mat-chip-option kfChips (click)="setStatusFilter(statusFilterStatus.completed)"> Abgeschlossen </mat-chip-option>
    </mat-chip-listbox>
  </article>
</ng-template>
<!-- /FILTER -->

<!-- SHOW ALL CUSTOMERS -->
<ng-template #showAllCustomersToggle>
  <div>
    <mat-slide-toggle
      [attr.data-testcafe]="'toggle-all-customers'"
      (change)="toggleAllCustomers()"
      color="primary"
      labelPosition="before"
    >
      {{ showAllCustomers ? 'Nur meine Beratungen' : 'Alle Beratungen' }}
    </mat-slide-toggle>
  </div>
</ng-template>
<!-- /SHOW ALL CUSTOMERS -->

<ng-template #noConsultationsTemplate>
  <h2 class="d-flex flex-column align-items-center">Keine Beratungen vorhanden</h2>
</ng-template>

<ng-template #requestingTemplate>
  <div class="w-100 h-100 d-flex align-items-center justify-content-center">
    <mat-progress-spinner [color]="'primary'" mode="indeterminate" [diameter]="90"></mat-progress-spinner>
  </div>
</ng-template>
