import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogConfirmData } from '@components/dialog-confirm/dialog-confirm.component';
import { GetInstanceResponse } from '@domain/app/instances.domain';
import { LoginMessage } from '@domain/app/user.domain';
import { GetCustomerConsultationResponseItem, GetHubConsultationResponseItem } from '@domain/hub/consultation.domain';
import { CustomerResponseItem } from '@domain/hub/customer.domain';
import { ConsultationStatusEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { environment } from '@environment/environment';
import { AuthService } from '@modules/auth';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';
import moment from 'moment';
import 'moment/locale/de';
import { Subscription, forkJoin } from 'rxjs';

@Component({
  selector: 'screen-client-consultations',
  templateUrl: './screen-client-consultations.component.html',
  styleUrls: ['./screen-client-consultations.component.scss'],
})
export class ScreenClientConsultationsComponent implements OnInit, OnDestroy {
  public confirm = false;
  public dialogResponse: any;

  public screenContext = '';

  public customerData: CustomerResponseItem;
  public consultationDates: GetCustomerConsultationResponseItem[] = [];
  public consultationStatus = ConsultationStatusEnum;

  public displayedConsultationDates: GetCustomerConsultationResponseItem[] = [];
  public onlyActive = false;

  public instances = new Map<string, string>();

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly environment = environment;

  consultationId: string;
  customerId: number;
  moment = moment;

  private windowRef = null;
  private _actionSub: Subscription;
  private dialogSub: Subscription;
  private dialogRef = null;

  constructor(
    private router: Router,
    private actionService: ActionService,
    private clientService: ClientService,
    private queryService: QueryService,
    private dialogService: DialogService,
    private authService: AuthService,
    private contextService: ContextService
  ) {
    if (this.router.getCurrentNavigation()?.extras?.state) {
      const stateObj = this.router.getCurrentNavigation()?.extras?.state;
      if (stateObj && stateObj?.context) {
        this.screenContext = stateObj.context;
      } else {
        this.screenContext = '';
      }
    }
  }

  ngOnInit(): void {
    moment.locale('de');
    this.customerId = this.clientService.customerId;

    this._actionSub = this.actionService.action.subscribe(action => {
      if (action && action.target === 'client-consultations') {
        if (action.action === 'update') {
          this.getCustomerOptionsData();
        }
      }
    });

    this.getCustomerOptionsData();

    window.addEventListener('message', event => {
      if (event.origin === environment.preliminaryTarget) {
        if (event.data?.preliminaryLoaded === true && this.windowRef) {
          const message = {
            jwtToken: this.authService.jwtToken,
            instanceId: this.clientService.instanceId,
            customerId: this.clientService.customerId,
            consultationId: this.consultationId || this.clientService.consultationId,
            bankExternalId: this.clientService.bankExternalId,
            email: this.clientService.bankConsultEmail,
          } as LoginMessage;
          this.windowRef.postMessage(message, environment.preliminaryTarget);
        }
      }
    });

    this.contextService.customerMessage = '';
  }

  ngOnDestroy(): void {
    this._actionSub?.unsubscribe();
    this.dialogSub?.unsubscribe();
    this.instances.clear();
  }

  public selectNewConsultation() {
    // should only happen in the Prep Branch!
    this.doAction('overlay-main', 'consult-new');
  }

  public selectConsultationOption(data: GetCustomerConsultationResponseItem) {
    this.clientService.instanceId = data.instanceId;
    this.clientService.consultationId = data.id;
    this.clientService.consultationStatus = data.status;
    this.clientService.bankHubId = data.id;

    if (data.status === ConsultationStatusEnum.created) {
      this.router.navigate([`${RoutingPathPrep.TopicSelection}`]);
    } else if (data.status === ConsultationStatusEnum.mainConsultation) {
      this.router.navigate([`${RoutingPathMain.TopicOverview}/${data.id}`]);
    } else if (
      data.status === ConsultationStatusEnum.inPreparation ||
      data.status === ConsultationStatusEnum.preparationSent ||
      data.status === ConsultationStatusEnum.preparationDone
    ) {
      this.router.navigate([`${RoutingPathPrep.TopicOverview}/${data.id}`]);
    }
  }

  public onEditCustomer() {
    this.doAction('overlay-main', 'customer-new', { edit: true });
  }

  public isConsultationCompleted(consultationData: GetHubConsultationResponseItem) {
    return (
      consultationData.status === this.consultationStatus.archived ||
      consultationData.status === this.consultationStatus.checkoutDone
    );
  }

  public getConsultationSummary(consultationData: GetHubConsultationResponseItem) {
    this.router.navigate([`${RoutingPathMain.ResultSummary}/${consultationData.id}`], {
      state: { origin: 'start' },
    });
  }

  public getTestcafeId(id: number, name = '') {
    return `screenClientList-div-${id}-${name?.replace(/ /g, '')}`;
  }

  public getTestcafeButton(id: number, name = '') {
    return `screenClientList-div-${id}-${name?.replace(/ /g, '')}-more`;
  }

  get isOnVp() {
    return environment.platform === 'vp';
  }

  public handleDateFormat(consultationData: GetCustomerConsultationResponseItem) {
    if (consultationData.appointmentTime) {
      const time = consultationData.appointmentTime.split(':');
      return moment(consultationData.appointmentDate)
        .hour(parseInt(time[0], 10))
        .minute(parseInt(time[1], 10))
        .format('Do MMMM YYYY, HH:mm');
    } else {
      return moment(consultationData.appointmentDate).format('Do MMMM YYYY');
    }
  }

  public filterActive() {
    this.onlyActive = !this.onlyActive;
    if (this.onlyActive) {
      this.displayedConsultationDates = this.consultationDates.filter(x => !!x.id);
    } else {
      this.displayedConsultationDates = this.consultationDates;
    }
  }

  public confirmDeletion(consultation) {
    const data = {
      headingText: 'Beratung löschen',
      copyText: `Möchten Sie wirklich die ausgewählte Beratung löschen?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this.dialogRef = this.dialogService.openDialogConfirm(data);
    this.dialogSub = this.dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.deleteElement(consultation);
      }
      this.confirm = false;
      this.dialogRef = null;
    });
  }

  public confirmArchiving(consultation) {
    const data = {
      headingText: 'Beratung archivieren',
      copyText: `Möchten Sie wirklich die ausgewählte Beratung archivieren?`,
      confirmText: 'Ja',
      denyText: 'Nein',
    } as DialogConfirmData;

    this.dialogRef = this.dialogService.openDialogConfirm(data);
    this.dialogSub = this.dialogRef.afterClosed().subscribe(async result => {
      if (result?.confirmed === true) {
        this.archiveElement(consultation);
      }
      this.confirm = false;
      this.dialogRef = null;
    });
  }

  // context menu methods for matMenu
  public closeDialog(response?): void {
    this.dialogRef?.close(response);
  }

  public edit(consultation: GetCustomerConsultationResponseItem): void {
    this.doAction('overlay-main', 'consult-new', { edit: true, bankHubId: consultation.id });
  }

  public deleteElement(consultation): void {
    this.queryService.deleteConsultation(consultation.id).subscribe(x => {
      this.getConsultationDates();
    });
  }

  public archiveElement(consultation): void {
    this.queryService.postArchiveConsultation(consultation.id).subscribe(x => {
      this.getConsultationDates();

      this.confirm = false;
    });
  }

  /**
   * Triggers the load of the prelim
   *
   * "consultation" contains the data we need to pass over to the prelim
   */
  public startPrelim(consultation) {
    const ref = window.open('about:blank', '_blank');
    ref.location.href = environment.preliminaryTarget;
    this.dialogResponse = { action: 'openPrelim', ref, data: consultation };
    this.closeDialog(this.dialogResponse);
  }

  // --------------------------------------------- //
  // -------------- HELPER FUNCTIONS ------------- //
  // --------------------------------------------- //

  private getConsultationDates() {
    this.queryService.getConsultationsByCustomerId(this.customerId).subscribe(data => {
      this.consultationDates = data;
    });
  }

  private getCustomerOptionsData() {
    const consultations = this.queryService.getConsultationsByCustomerId(this.customerId);
    const customer = this.queryService.getCustomerById(this.customerId);
    const instances = this.queryService.getInstances();

    forkJoin([customer, consultations, instances]).subscribe(
      ([customerData, consultationData, instanceData]: [
        CustomerResponseItem,
        GetCustomerConsultationResponseItem[],
        GetInstanceResponse[],
      ]) => {
        const cmp = (a: any, b: any, direction: 'asc' | 'desc' = 'asc') => {
          const sign = direction === 'asc' ? 1 : -1;
          return a > b ? sign * 1 : a < b ? sign * -1 : 0;
        };

        this.customerData = customerData;
        if (!customerData.logo || customerData.logo.includes('null')) {
          this.customerData.logo = '';
        }
        this.consultationDates = consultationData.sort((a, b) => cmp(a.createdAt, b.createdAt, 'desc'));
        this.displayedConsultationDates = this.consultationDates;
        instanceData.forEach(x => {
          this.instances.set(x.id, x.name);
        });
      }
    );
  }

  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  get useMargin(): boolean {
    return this.contextService.isStickyMode || !this.contextService.isManagedFooter;
  }
}
