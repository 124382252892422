import { FormOfAddressEnum } from '@enums';

export const Settings = {
  noteMaxLength: 4000,
};

export const bgzvDimensions = {
  footerHeightPx: 64,
  headerHeightPx: 57,
};

export const CKEditorConfig = {
  language: 'de',
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    '|',
    'link',
    '|',
    'outdent',
    'indent',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'blockQuote',
    '|',
    'undo',
    'redo',
    '|',
  ],
  removePlugins: [
    'CKBox',
    'CKFinder',
    'CKFinderUploadAdapter',
    'EasyImage',
    'Image',
    'ImageCaption',
    'ImageStyle',
    'ImageToolbar',
    'ImageUpload',
    'MediaEmbed',
    'PictureEditing',
    'Table',
    'TableToolbar',
    'TextTransformation',
  ],
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      {
        model: 'heading1',
        view: { name: 'h1', classes: 'vr-headline-300' },
        title: 'Heading 1',
        class: 'ck-heading_heading1',
      },
      {
        model: 'heading2',
        view: { name: 'h2', classes: 'vr-headline-200' },
        title: 'Heading 2',
        class: 'ck-heading_heading2',
      },
      {
        model: 'heading3',
        view: { name: 'h3', classes: 'vr-headline-100' },
        title: 'Heading 3',
        class: 'ck-heading_heading3',
      },
      {
        model: 'heading4',
        view: { name: 'h4', classes: 'vr-text-large' },
        title: 'Heading 4',
        class: 'ck-heading_heading4',
      },
    ],
  },
  link: {
    defaultProtocol: 'https://',
  },
};

export const formOfAddress = [
  {
    label: FormOfAddressEnum.female,
    value: FormOfAddressEnum.female,
  },
  {
    label: FormOfAddressEnum.male,
    value: FormOfAddressEnum.male,
  },
];
