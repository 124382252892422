import {
  ButtonType,
  ComponentType,
  ElementConfig,
  FooterConfig,
  FooterSectionType,
} from '@de.fiduciagad.kbm/shared-footer-lib';

/// ---------------- DRAWING BUTTONS --------------- ///
export const buttonDrawing: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'start-drawing',
    config: {
      type: ButtonType.BASIC,
      icon: 'ic_annotation_zeichnen_24',
      tooltip: 'Zeichnung erstellen',
    },
  },
};
export const buttonDrawingActive: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'start-drawing',
    config: {
      type: ButtonType.BASIC,
      icon: 'ic_annotation_zeichnen_24',
      tooltip: 'Zeichnung öffnen',
      badgeLabel: '1',
    },
  },
};

/// ----------------- NOTES BUTTONS ---------------- ///
export const buttonNotes: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'start-notes',
    config: {
      type: ButtonType.BASIC,
      icon: 'ic_notiz_24',
      tooltip: 'Textnotiz erstellen',
    },
  },
};
export const buttonNotesActive: ElementConfig = {
  type: ComponentType.ICON_BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'start-notes',
    config: {
      type: ButtonType.BASIC,
      icon: 'ic_notiz_24',
      tooltip: 'Textnotiz öffnen',
      badgeLabel: '1',
    },
  },
};

/// ------------------------------------------------ ///
/// --------------- CONFIG SKELETON ---------------- ///
/// ------------------------------------------------ ///

export const baseConfig: FooterConfig = {
  leftCollapsable: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
  left: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
  centre: {
    isHidden: true,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
  right: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
};

export const baseConfigEmpty: FooterConfig = {
  leftCollapsable: {
    isHidden: true,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
  left: {
    isHidden: false,
    type: FooterSectionType.PRIMARY,
    elements: [],
  },
  centre: {
    isHidden: false,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
  right: {
    isHidden: false,
    type: FooterSectionType.HIDDEN,
    elements: [],
  },
};
