import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractStatusResponseItem } from '@domain/app/consultation.domain';

import {
  CheckoutDataFieldCompositionItem,
  CheckoutDataFieldGroupItem,
  CheckoutDataFieldItem,
} from '@domain/app/checkout.domain';

import { ContractStatusEnum, DataFieldElementTypeEnum, DataFieldTypeEnum } from '@enums';
import { ClientService } from '@services/client-service/client.service';
import { QueryService } from '@services/query-service/query.service';
import { color, libIcons } from 'bgzv-frontend-library';

import { MatSelectChange } from '@angular/material/select';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import { ProductResponse } from '@domain/app/product.domain';
import { Action, ActionService } from '@services/action-service/action.service';
import { ContractService } from '@services/contract-service/contract.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { isEmpty, isEqual } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface DataFieldFormValueChange {
  item: CheckoutDataFieldItem;
  changedValue: number | string | boolean;
}

interface ItemDatafieldFormGroup {
  [propName: string]: FormControl<ItemDatafieldType>;
}

type ItemDatafieldFormArray = FormArray<FormGroup<ItemDatafieldFormGroup>>;
type ItemDatafieldType = number | string | boolean | Date | null;

@Component({
  selector: 'item-datafield-form',
  templateUrl: './item-datafield-form.component.html',
  styleUrls: ['./item-datafield-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemDatafieldFormComponent implements OnInit {
  @Input() public set dataFieldData(value: CheckoutDataFieldCompositionItem) {
    if (value) {
      this._dataFieldData = value;
      this.createFormGroup();
      this.chg.detectChanges();
    }
  }

  @Input() productData: ProductResponse;
  @Input() dataFieldElementType: DataFieldElementTypeEnum = null;
  @Input() disableFields: boolean = false;
  @Input() showHeader: boolean = true;
  @Input() inCheckout: boolean = false;
  @Output() valueChanged = new EventEmitter<DataFieldFormValueChange>();
  @Output() dataFieldAdded = new EventEmitter<CheckoutDataFieldGroupItem>();
  @Output() dataFieldRemoved = new EventEmitter<CheckoutDataFieldGroupItem>();

  private destroySubs = new Subject<void>();
  private _dataFieldData: CheckoutDataFieldCompositionItem;

  public dataFieldForm: FormGroup<{ datafields: ItemDatafieldFormArray }>;

  public consultationId = this.clientService.consultationId;
  public isLoading = null;
  public productStatus: ContractStatusResponseItem[] = [];
  public uniqueContractDatafields: CheckoutDataFieldGroupItem[];
  public isPolling: { ordinal: number; status: ContractStatusEnum }[] = [];
  public dataFieldChanged: boolean = true;
  public currentDate: Date = new Date();

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly dataFieldType = DataFieldTypeEnum;
  readonly contractStatusEnum = ContractStatusEnum;

  constructor(
    private chg: ChangeDetectorRef,
    private queryService: QueryService,
    private clientService: ClientService,
    private loadingService: LoadingService,
    private actionService: ActionService,
    private contractService: ContractService,
    private snackBarService: SnackBarTemplatesService,
    private readonly formValidationService: FormValidationService
  ) {}

  public panelOpen: Array<any> = [];

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      if (loading !== this.isLoading) {
        this.isLoading = loading;

        if (!loading) {
          const t = this.dataFieldData?.dataFieldGroups.filter(x => x.multiplied && x.hasContractForm);
          this.uniqueContractDatafields = [...new Map(t?.map(item => [item?.ordinal, item]))?.values()];
        }

        this.chg.detectChanges();
      }
    });

    this.contractService.putRequested.pipe(takeUntil(this.destroySubs)).subscribe(async response => {
      await this.contractService
        .getSingleProductContractData(
          this.clientService.consultationId,
          this.dataFieldData?.dataFieldGroups,
          this.productData
        )
        .then(data => (this.productStatus = data));

      this.chg.detectChanges();
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.dataFieldData) {
      this.dataFieldData = changes.dataFieldData.currentValue;

      if (this.dataFieldData?.dataFieldGroups.find(x => x?.hasContractForm)) {
        this.productStatus = await this.contractService.getSingleProductContractData(
          this.clientService.consultationId,
          this.dataFieldData.dataFieldGroups,
          this.productData
        );
      }

      this.productStatus.find(x => {
        if (this.getLowestContractsStatus(x, x.ordinal) === ContractStatusEnum.generatePreview) {
          this.startContractStatusPolling(this.productData);
        }
        if (this.getLowestContractsStatus(x, x.ordinal) === ContractStatusEnum.error) {
          // if there's an error, we alert the user
          this.snackBarService.openSnackBar({
            type: SnackbarType.ALERT,
            message: 'Fehler bei der Erstellung der Vertragsdokumente.',
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (!this.inCheckout && this.contractService.pollingActive) {
      this.contractService._stopPolling();
    }
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  // --------------------------------------------- //

  //needed to parse the jsonAnswerOptions of some dataFields
  public optionDataFactory(item?: CheckoutDataFieldItem): string[] {
    const options = [];
    for (let option in JSON.parse(item.jsonAnswerOptions)) {
      options.push(JSON.parse(item.jsonAnswerOptions)[option]);
    }

    return options;
  }

  public async handleSelectionChange(event: MatSelectChange, item: CheckoutDataFieldItem): Promise<void> {
    const changedValue = event.value;

    this.handleValueChange(changedValue, item);
  }

  public async handleValueChange(changedValue: number | string | boolean, item: CheckoutDataFieldItem): Promise<void> {
    this.dataFieldChanged = true;
    this.valueChanged.emit({ changedValue, item });
  }

  public toDate(value: ItemDatafieldType) {
    if (value) {
      const splitValue = value.toString().split('.');
      const dateString = `${splitValue[2]},${splitValue[1]},${splitValue[0]}`;
      return new Date(dateString);
    } else {
      return '';
    }
  }

  public getFormGroup(index): FormGroup<ItemDatafieldFormGroup> {
    const a = this.dataFieldForm.controls.datafields as ItemDatafieldFormArray;
    return a.controls[index] as FormGroup<ItemDatafieldFormGroup>;
  }

  public getHeader(): string {
    if (this.dataFieldElementType === DataFieldElementTypeEnum.general) {
      return `<div class="vr-headline-200">${this.dataFieldData?.elementName}</div>`;
    } else {
      return `<div class="vr-headline-200">${this.dataFieldData?.compositionName}</div>
      <div class="vr-text-regular">${this.dataFieldData?.elementName}</div>`;
    }
  }

  public onAddDataField(group: CheckoutDataFieldGroupItem): void {
    this.dataFieldAdded.emit(group);
  }

  public onRemoveDataField(group: CheckoutDataFieldGroupItem): void {
    this.dataFieldRemoved.emit(group);
  }

  public getTestcafeLabel(groupName = '', groupId: number, itemName = '', itemId: number) {
    return `dataFieldGroup-${groupName?.replace(/ /g, '')}-${groupId}-dataField-${itemName?.replace(
      / /g,
      ''
    )}-${itemId}`;
  }

  public generateContractPreview(product, group) {
    if (!(this.isLoading || this.mandatoryDataFieldsIncomplete(this.getIndex(group)) || this.contractPolling(group))) {
      this.dataFieldChanged = false;
      this.contractService.pollingMap.set(group, product);
      let item = this.contractService.generateSingleStatusRequestItem(product, group);
      this.contractService.generateContractPreview(item);
      this.startContractStatusPolling(product, group);
    }
  }

  public startContractStatusPolling(product, group = null) {
    this.contractService.startContractStatusPolling();

    //needed to empty data, so buttons are disabled every time and not just the first time
    this.contractService.pollingResult.next([]);

    this.contractService.pollingResult.subscribe(data => {
      this.productStatus = data;
      let contractItems = data?.find(x => x.elementId === product.id);
      if (contractItems && isEmpty(contractItems)) {
        let index = this.isPolling.findIndex(x => x.ordinal === contractItems.ordinal);

        if (index && this.isPolling[index]?.status) {
          this.isPolling[index].status = this.contractService.getLowestContractStatus(contractItems?.contracts);
        } else {
          this.isPolling.push({
            ordinal: contractItems[0].ordinal,
            status: this.contractService.getLowestContractStatus(contractItems?.contracts),
          });
        }
        if (this.isPolling.every(x => x?.status !== ContractStatusEnum.generatePreview)) {
          this.isPolling = [];
        }
      }
      if (
        data.find(x => x.elementId === product.id && x.ordinal === group.ordinal)?.contracts[0].status ===
        ContractStatusEnum.preview
      ) {
        this.contractService.pollingMap.delete(group);
      }

      this.chg.detectChanges();
    });
    if (this.inCheckout) {
      // Start polling on checkout
      this.doAction('checkout', 'poll-contract-status');
    }
  }

  public getIsProductInstancePollingFinished(item): boolean {
    if (isEmpty(this.isPolling)) {
      return true;
    }
    return this.isPolling?.find(x => x.ordinal === item.ordinal)?.status === ContractStatusEnum.preview;
  }

  public mandatoryDataFieldsIncomplete(index: number): boolean {
    let invalid = true;
    const fields = this.dataFieldData.dataFieldGroups[index].dataFields;

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].mandatory) {
        invalid = !this.getFormGroup(index).get(fields[i].dataFieldValueId).valid;

        if (invalid) {
          break;
        }
      }
    }
    return invalid;
  }

  public getDataFieldsComplete(group: CheckoutDataFieldGroupItem): boolean {
    return this.productStatus?.find(x => x?.ordinal === group.ordinal)?.dataFieldsComplete || false;
  }

  public getUpdateNeeded(group: CheckoutDataFieldGroupItem): boolean {
    return this.productStatus?.find(x => x?.ordinal === group.ordinal)?.updateNeeded || false;
  }

  public openContractPreviews(elementId: string, group: any): void {
    if (
      this.getLowestContractsStatus({ id: elementId }, group.ordinal) !==
      (this.contractStatusEnum.error || this.contractStatusEnum.none || this.contractStatusEnum.generatePreview)
    ) {
      const documentIds = this.productStatus
        .filter(x => x.ordinal === group.ordinal && x.elementId === elementId)
        .flatMap(x => x.contracts)
        .map(x => x.documentId);
      this.contractService.openContractPDF(documentIds, this.clientService.customerId);
    } else {
      console.log('%c [bgzv-frontend-main] PREVIEWS HAVE NOT BEEN GENERATED', 'color: #0066cc');
    }
  }

  public getHasContractForm(item): boolean {
    return item?.hasContractForm || false;
  }

  public getContractIncluded(group: CheckoutDataFieldGroupItem): boolean {
    return group.contractIncluded || false;
  }

  public async toggleIncludeExcludeContract(
    checked: boolean,
    group: CheckoutDataFieldGroupItem,
    product: ProductResponse
  ) {
    const _product = this.contractService.generateSingleStatusRequestItem(product, group);
    const groupIndex = this.dataFieldData?.dataFieldGroups.findIndex(x => isEqual(x, group));

    if (checked && !group.contractIncluded) {
      this.queryService.putIncludeContract(this.clientService.consultationId, _product).subscribe(x => {
        this.queryService.getContractStatus(this.clientService.consultationId).subscribe(data => {
          this.productStatus = data;
        });
      });
      this.dataFieldData.dataFieldGroups[groupIndex].contractIncluded = true;
    } else if (!checked && group.contractIncluded) {
      this.queryService.putExcludeContract(this.clientService.consultationId, _product).subscribe(x => {
        this.queryService.getContractStatus(this.clientService.consultationId).subscribe(data => {
          this.productStatus = data;
        });
      });
      this.dataFieldData.dataFieldGroups[groupIndex].contractIncluded = false;
    }
    this.chg.detectChanges();
  }

  public getLowestContractsStatus(item: ProductResponse, ordinal: number): ContractStatusEnum {
    return this.contractService.getLowestContractStatus(
      this.productStatus?.find(x => x?.elementId === item.id && x?.ordinal === ordinal)?.contracts
    );
  }

  public showContractCheckbox(group: CheckoutDataFieldGroupItem, item: ProductResponse): boolean {
    return group?.hasContractForm;
  }

  public getContract(i: number) {
    i = i === 0 ? 1 : i;
    return this.dataFieldData?.dataFieldGroups.filter(x => x.ordinal === i && x.multiplied && x.hasContractForm) || [];
  }

  public getIndex(item: CheckoutDataFieldGroupItem): number {
    return this.dataFieldData?.dataFieldGroups.findIndex(x => x === item);
  }

  public get highestOrdinal(): number {
    return Math.max(...this._dataFieldData?.dataFieldGroups.map(x => x.ordinal));
  }

  public get pollingActive(): boolean {
    return this.contractService.pollingActive;
  }

  public get dataFieldData(): CheckoutDataFieldCompositionItem {
    return this._dataFieldData;
  }

  public get showAddDataFieldButton(): boolean {
    const groups = this.dataFieldData?.dataFieldGroups;
    return !!groups[0].multiplied && this.dataFieldElementType === DataFieldElementTypeEnum.tasks;
  }

  public get showRemoveDataFieldButton(): boolean {
    const groups = this.dataFieldData?.dataFieldGroups;
    return !!groups[0].multiplied && groups.length >= 2 && this.dataFieldElementType === DataFieldElementTypeEnum.tasks;
  }

  public get isValidForm(): boolean {
    return this.dataFieldForm?.valid || false;
  }

  public get allFieldsFilledAndValid(): boolean {
    const v = this.dataFieldForm?.value.datafields.reduce((a, b) => {
      const q = Object.keys(b).some(x => b[x] === null || b[x] === '');
      q && a.push(q);
      return a;
    }, []);

    return v.length === 0;
  }

  // Expansion panel

  /**
   *
   * @param {string} id
   */
  public setPanelOpen(id: string) {
    if (!this.isPanelOpen[id]) {
      this.panelOpen[id] = true;
    }
  }

  /**
   *
   * @param {string} id
   * @returns boolean
   */
  public isPanelOpen(id: string): boolean {
    return this.panelOpen[id];
  }

  // --------------------------------------------- //
  private createFormGroup() {
    const allGroups = this.dataFieldData?.dataFieldGroups;
    const formGroups = new FormArray<FormGroup<ItemDatafieldFormGroup>>([]) as ItemDatafieldFormArray;

    allGroups.forEach(group => {
      const formControls = new FormGroup<ItemDatafieldFormGroup>({});
      // mandatory fields have to come first, sort order of fields has to be defined in configurator
      group.dataFields.forEach(field => {
        const name = `${field.dataFieldValueId}`;
        formControls.addControl(name, this.createFormControl(field));
      });
      formGroups.push(formControls);
    });

    this.dataFieldForm = new FormGroup<{ datafields: ItemDatafieldFormArray }>({ datafields: formGroups });
  }

  private createFormControl(item: CheckoutDataFieldItem): FormControl<ItemDatafieldType> {
    const validator = [];
    const value = this.getFormControlValue(item);

    if (item?.mandatory) {
      validator.push(Validators.required);
    }
    if (item?.jsonValidations) {
      validator.push(Validators.pattern(new RegExp(item.jsonValidations)));
    }

    return new FormControl<ItemDatafieldType>(value, validator);
  }

  private getFormControlValue(item: CheckoutDataFieldItem): ItemDatafieldType {
    const returnValue = item?.value ? item.value : item?.defaultValue ? item.defaultValue : null;

    if (typeof returnValue === 'string') {
      if (item.dataFieldType === DataFieldTypeEnum.number) {
        return parseFloat(returnValue);
      } else if (item.dataFieldType === DataFieldTypeEnum.checkbox) {
        return returnValue === 'true' ? true : returnValue === 'false' ? false : null;
      } else if (item.dataFieldType === DataFieldTypeEnum.radio) {
        return ['Ja', 'true', 'Nein', 'false'].includes(returnValue) ? returnValue : null;
      }
    }

    return returnValue;
  }

  public getFormControlFieldLabel(item: CheckoutDataFieldItem): string {
    let label = item?.name || 'Ihre Eingabe';
    if (!item?.mandatory) {
      label = label + ' (optional)';
    }
    return label;
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  public contractPolling(item): boolean {
    return this.contractService.pollingMap.has(item);
  }
}
