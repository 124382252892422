import { CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/de';
import { DoBootstrap, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { BgzvComponentsModule } from '@components/components.module';
import { SnackbarTemplatesComponent } from '@components/snackbar-templates/snackbar-templates.component';
import { SharedFooterComponent } from '@de.fiduciagad.kbm/shared-footer-lib';
import { KFThemeCommonModule } from '@de.fiduciagad.kundenportal/kf-theme/common';
import {
  ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT,
  ENVIRONMENT_SPECIFIC_IMPORTS,
  ENVIRONMENT_SPECIFIC_PROVIDERS,
} from '@environment/environment';
import { BgzvMarginalsModule } from '@marginals/marginals.module';
import { AuthInterceptor } from '@modules/auth';
import { BgzvOverlayModule } from '@overlays/overlay.module';
import { BgzvScreensModule } from '@screens/screens.module';
import { BgzvServicesModule } from '@services/services.module';
import { USERBACK } from '@services/userback/userback';
import { CustomDateAdapter } from '@utils/customDateAdapter';
import { RequestInterceptor } from '@utils/interceptors/request.interceptor';
import { PaymentIntervalPipe, TaxCalculationPipe } from '@utils/pipes/paymentPipe.pipe';
import { BgzvUtilsModule } from '@utils/utils.module';
import { BgzvLibraryModule } from 'bgzv-frontend-library';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { KfThemeModule } from './kf-theme_module';
import { MaterialModule } from './material_module';

/* PORTAL CONFIG */
// eslint-disable-next-line @typescript-eslint/naming-convention
declare const Zone: any;

@NgModule({
  declarations: [AppComponent, SnackbarTemplatesComponent],
  imports: [
    ...ENVIRONMENT_SPECIFIC_IMPORTS,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    BgzvComponentsModule,
    BgzvServicesModule,
    BgzvUtilsModule,
    BgzvMarginalsModule,
    BgzvOverlayModule,
    BgzvScreensModule,
    BgzvLibraryModule,
    AppRoutingModule,
    SharedFooterComponent,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerImmediately',
    // }),
    CKEditorModule,
    KFThemeCommonModule,
    KfThemeModule,
  ],
  providers: [
    ...ENVIRONMENT_SPECIFIC_PROVIDERS,
    CurrencyPipe,
    PaymentIntervalPipe,
    TaxCalculationPipe,
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: USERBACK, useFactory: () => (window as any).Userback },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
})
export class AppModule implements DoBootstrap {
  constructor(private readonly injector: Injector) {}

  ngDoBootstrap(): void {
    const app = ENVIRONMENT_SPECIFIC_CUSTOM_ELEMENT(AppComponent, this.injector);
    if (!customElements.get('bgzv-frontend-main')) {
      customElements.define('bgzv-frontend-main', app);
    }
  }
}
