<div class="boxed-content d-flex flex-column flex-scroll flex-grow-1">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <kf-headline [headline]="'Beratungsmodul'" [showBackButton]="false"></kf-headline>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <aside>
            <ng-template *ngTemplateOutlet="opener"></ng-template>
          </aside>
        </kf-tile>
      </div>
    </div>
    <div class="row mb-4 mt-4" *ngIf="showConsultations">
      <div class="col-12">
        <kf-tile [interactive]="false">
          <ng-template *ngTemplateOutlet="content"></ng-template>
        </kf-tile>
      </div>
    </div>
    <div style="height: 64px"></div>
  </div>
</div>

<!-- TEMPLATES -->
<ng-template #opener>
  <p>
    Damit Sie Ihre Kunden mit einem Beratungsmodul des BankingGuides beraten können, legen Sie bitte in der
    "Terminübersicht" einen Beratungstermin an und fügen Sie ein Beratungsmodul als Werkzeug hinzu oder starten dieses
    direkt aus dem Werkzeugkasten.
  </p>

  <footer class="w-100 d-flex justify-content-end mt-3">
    <div class="button-row">
      <div class="button-row-content">
        <button
          mat-flat-button
          color="primary"
          (click)="onBannerButtonClicked($event)"
          [attr.data-testcafe]="'screenEntry-button-customerList'"
        >
          <span>{{ buttonText }}</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<ng-template #content>
  <ng-container>
    <consultation-overview
      *ngIf="consultationData.customers"
      [consultationData]="consultationData.customers"
      [isRequesting]="isRequesting"
    ></consultation-overview>
  </ng-container>
</ng-template>
