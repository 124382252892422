import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NoteTypeEnum, RoutingPathMain, RoutingPathPrep } from '@enums';
import { ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { ViewProperties } from '@services/note-service/note';
import { NoteService } from '@services/note-service/note.service';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'select-note-button',
  templateUrl: './select-note-button.component.html',
  styleUrls: ['./select-note-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectNoteButtonComponent implements OnChanges {
  @Input() noteName: string | undefined;
  @Input() isSelected: boolean;
  @Input() type: NoteTypeEnum;
  @Input() depth: 0 | 1;
  @Input() editMode = false;
  @Input() questionGroupId: string | undefined;
  @Input() isTopicSelection: boolean | undefined;
  @Input() disabled = false;
  @Input() viewProperties: ViewProperties | undefined;
  @Output() clicked = new EventEmitter<Event>();

  @Input() questionGroupName: string | undefined;
  @Input() isCustomNote: boolean = false;

  private destroySubs = new Subject<void>();

  public deleted = false;
  public loading: boolean = false;

  readonly color = color;
  readonly noteTypeEnum = NoteTypeEnum;
  readonly buttonIcon = libIcons;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly contextService: ContextService,
    private readonly actionService: ActionService,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private clientService: ClientService,
    private noteService: NoteService
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.changeDetector.detectChanges();
    });
  }

  ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange.disabled) {
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy() {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  get displayName() {
    return this.noteName ?? this.questionGroupName ?? '';
  }

  public isJumpToQuestionBtnAvailable(): boolean {
    return (
      (this.questionGroupId !== undefined || this.isTopicSelection) &&
      this.viewProperties?.consultationMode === this.contextService.currentMode
    );
  }

  public jumpToQuestion(jumpQuestionGroupId, type: NoteTypeEnum) {
    if (!jumpQuestionGroupId || this.disabled) {
      return;
    }
    this.noteService.presetNoteType = type;

    this.actionService.setAction({ target: 'overlay-main', action: 'close' }).then(() => {
      if (
        this.contextService.currentMode === 'prep' &&
        this.contextService.currentMainContext !== RoutingPathPrep.Consultation
      ) {
        this.router.navigate([RoutingPathPrep.Consultation], {
          state: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      } else if (
        this.contextService.currentMode === 'main' &&
        this.contextService.currentMainContext !== RoutingPathMain.Consultation
      ) {
        this.router.navigate([RoutingPathMain.Consultation], {
          state: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      } else {
        this.actionService.setAction({
          target: 'consultation',
          action: 'jump',
          options: { questionGroupId: jumpQuestionGroupId, openDrawOverlay: true },
        });
      }
    });
  }

  public jumpToTopicOverview(type: NoteTypeEnum) {
    if (this.disabled || this.contextService.currentMode !== this.viewProperties.consultationMode) {
      return;
    }

    this.noteService.presetNoteType = type;

    this.actionService
      .setAction({ target: 'overlay-main', action: 'close' })
      .then(() =>
        this.router.navigate([`${this.contextService.currentMode}/topics/${this.clientService.bankHubId}`], {
          state: { openDrawOverlay: true },
        })
      )
      .then(() => this.actionService.setAction({ target: 'overlay-main', action: 'drawing' }));
  }
}
