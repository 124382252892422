import { CurrencyPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { SnackBarTemplatesService, SnackbarType } from '@components/snackbar-templates/snackbar-templates.service';
import {
  ConsultationSummaryCustomer,
  ConsultationSummaryProductItem,
  ContractStatusContractItem,
} from '@domain/app/consultation.domain';
import { ContractStatusEnum } from '@enums';
import { environment } from '@environment/environment';
import { ClientService } from '@services/client-service/client.service';
import { ContextService } from '@services/context-service/context.service';
import { ContractService } from '@services/contract-service/contract.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { getDocumentIcon } from '@utils/helpers/mediaType';
import { color, libIcons } from 'bgzv-frontend-library';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'result-products',
  templateUrl: './result-products.component.html',
  styleUrls: ['./result-products.component.scss'],
})
export class ResultProductsComponent implements OnInit {
  @Input() productData: ConsultationSummaryProductItem[];
  @Input() contractStatus: ContractStatusContractItem[];
  @Input() customerData: ConsultationSummaryCustomer;

  @ViewChild('productsPanel') productsPanel: MatExpansionPanel;

  public panelOpenState: boolean;
  public activeProductIndex: number;
  public consultationId = this.clientService.consultationId;
  public documentTypeAsIcon = getDocumentIcon;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly contractStatusEnum = ContractStatusEnum;

  readonly environment = environment.platform;

  public loading = false;

  private destroySubs = new Subject<void>();

  constructor(
    private contextService: ContextService,
    private queryService: QueryService,
    private clientService: ClientService,
    private currencyPipe: CurrencyPipe,
    public contractService: ContractService,
    private snackBarService: SnackBarTemplatesService,
    private mediaService: MediaService,
    private loadingService: LoadingService,
    private chg: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chg.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  public formatPrice(price: number, quantity: number): string {
    const value = price * quantity;

    return this.currencyPipe?.transform(value, 'EUR', 'symbol', null, 'de');
  }

  public async openDocument(documentUrl: string): Promise<void> {
    if (documentUrl.includes('http')) {
      window.open(documentUrl, '_blank');
    } else {
      const url = await this.mediaService.getMediaContent(documentUrl);
      window.open(url, '_blank');
    }
  }

  /**
   * We take the customerId from customerData instead of the one from clientService
   * because the id will not be set in clientService after archiving and calling the page directly
   *
   * @param {object} product
   * @param {number} customerId
   */
  public openContractPDF(product, customerId: number) {
    this.queryService.getBankBankHubDocument(product.contractDocumentId, customerId).subscribe(data => {
      const blob = new Blob([data], {
        type: 'application/pdf',
      });
      const url = window.URL.createObjectURL(blob);
      const ref = window.open('about:blank', '_blank');
      ref.location.href = url;
    });
  }

  public getContractStatus(product): ContractStatusEnum {
    // returns lowest contract status for a product
    // used to determine which contract icons have to be shown
    return this.contractStatus?.find(x => product.contractDocumentId === x.documentId)?.status;
  }

  public getContractPDFViewable(product): boolean {
    // should return true if status is on finalized, so a PDF can be viewed
    // finalized should never be the case here since we can't reach that in checkout
    if (product.contractDocumentId) {
      return this.getContractStatus(product) === this.contractStatusEnum.finalized;
    }

    return false;
  }

  public getContractError(product): boolean {
    // should return true if lowest contract status is error
    const status: ContractStatusEnum = this.getContractStatus(product);

    // if there's an error, we inform the user
    if ([this.contractStatusEnum.error, this.contractStatusEnum.errorFinalized].includes(status)) {
      this.snackBarService.openSnackBar({
        type: SnackbarType.ERROR,
        message: 'Fehler bei der Erstellung der Vertragsdokumente.',
      });
    }

    return (
      (status === this.contractStatusEnum.error || status === this.contractStatusEnum.errorFinalized) &&
      status !== undefined
    );
  }

  public getContractPending(product): boolean {
    // should return true if status is generate_finalized or generate_preview
    // but only if a product has contract(s)
    if (product.contractDocumentId) {
      const status: ContractStatusEnum = this.getContractStatus(product);
      return (
        [ContractStatusEnum.generateFinalized, ContractStatusEnum.generatePreview].includes(status) ||
        status === undefined
      );
    }

    return false;
  }

  public getSums(): Array<object> {
    let sums = [];

    this.productData.forEach(product => {
      if (product.fixCost || product.runningCost) {
        if (product.fixCost && product.fixCost.netPrice > 0) {
          const hasEntry = sums.findIndex(entry => entry.title === product.fixCost?.paymentInterval);

          if (hasEntry === -1) {
            sums.push({
              title: product.fixCost.paymentInterval,
              net: product.fixCost.netPrice * product.selectedQuantity,
              gross: product.fixCost.grossPrice * product.selectedQuantity,
            });
          } else {
            const entry = sums[hasEntry];
            entry.net += product.fixCost.netPrice * product.selectedQuantity;
            entry.gross += product.fixCost.grossPrice * product.selectedQuantity;
          }
        }

        if (product.runningCost && product.runningCost.netPrice > 0) {
          const hasEntry = sums.findIndex(entry => entry.title === product.runningCost?.paymentInterval);

          if (hasEntry === -1) {
            sums.push({
              title: product.runningCost.paymentInterval,
              net: product.runningCost.netPrice * product.selectedQuantity,
              gross: product.runningCost.grossPrice * product.selectedQuantity,
            });
          } else {
            const entry = sums[hasEntry];
            entry.net += product.runningCost.netPrice * product.selectedQuantity;
            entry.gross += product.runningCost.grossPrice * product.selectedQuantity;
          }
        }
      }
    });

    return sums;
  }

  public formatDataFieldValue(val: string): string {
    let formatDataFieldValue: string = val;

    if (val === 'true') {
      formatDataFieldValue = 'Ja';
    }
    if (val === 'false') {
      formatDataFieldValue = 'Nein';
    }

    return formatDataFieldValue;
  }

  public openProduct(index): void {
    this.activeProductIndex = index;
    this.productsPanel.open();
  }

  public getTestcafeId(name = '', id?: number) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }

  public hasAgreeOrTask(product: ConsultationSummaryProductItem): boolean {
    return product.agreeCauseCreated || product.taskCreated;
  }

  get assetPath(): string {
    return this.contextService.assetPath;
  }
}
