<aside class="side-chat" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <section class="d-flex w-100 align-items-center mb-4">
    <div class="flex-grow-1">
      <div class="vr-headline-200" *ngIf="customerData">
        Ihr Kunde: <strong>{{ customerData.corporationName }}</strong>
      </div>
    </div>
  </section>
  <ng-container *ngIf="!loading; else loadingTemplate">
    <ng-container *ngIf="!loadingMessages; else loadingTemplateMessages">
      <ng-container *ngIf="currentMessages.length !== 0; noMessagesTemplate">
        <div #chatFrame (scroll)="onChatScroll()" class="d-flex flex-column overflow-auto chat-frame" tabindex="-1">
          <div class="d-flex justify-content-center mt-2" [style.height]="'1.5rem'">
            <button
              [disabled]="loading"
              *ngIf="moreToShow"
              [attr.data-testcafe]="'sideChat-button-moreToShow'"
              mat-button
              color="primary"
              (click)="onMoreClick()"
            >
              <span>Vorherige Nachrichten</span>
              <kf-icon name="ic_pfeil_oben_24"></kf-icon>
            </button>
          </div>
          <div class="d-flex flex-grow-1"></div>
          <div
            *ngFor="let message of currentMessages"
            class="d-flex mt-4"
            [class.justify-content-end]="isConsultant(message.sender)"
          >
            <div
              class="bubble"
              [class.consultant]="isConsultant(message.sender)"
              [class.customer]="!isConsultant(message.sender)"
              [attr.data-testcafe]="getTestcafeId(message.createdAt, message.text)"
            >
              <i class="vr-text-small d-flex time-stamp">
                {{ timestamp(message.createdAt) }}
              </i>
              {{ message.text }}
            </div>
          </div>
          <div class="scroll-button" *ngIf="!isAtBottom">
            <button
              [attr.data-testcafe]="'sideChat-button-scroll'"
              mat-button
              color="primary"
              (click)="scrollToBottom()"
            >
              <span>Letzte Nachrichten</span>
              <kf-icon name="ic_pfeil_unten_24"></kf-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="flex-grow-1"></div>
    <textarea
      autofocus
      [(ngModel)]="message"
      class="w-100 mt-4 px-3 message-input"
      [attr.data-testcafe]="'sideChat-textarea-messageToCustomer'"
      [placeholder]="'Ihre Nachricht'"
    ></textarea>
    <div class="mt-4 button-row" #ref [class.footer-margin]="useMargin">
      <div class="button-row-content">
        <button
          [attr.data-testcafe]="'sideChat-button-answerCustomer'"
          [disabled]="isStringEmptyOrSpaces(message) || messageSending"
          mat-flat-button
          color="primary"
          (click)="sendMessage()"
        >
          <span>Nachricht absenden</span>
          <kf-icon name="ic_senden_24"></kf-icon>
        </button>

        <button
          [attr.data-testcafe]="'sideChat-button-update'"
          [disabled]="loading"
          mat-stroked-button
          color="primary"
          (click)="getMessageData()"
        >
          <span>Aktualisieren</span>
          <kf-icon name="ic_aktualisieren_24"></kf-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <button
    [attr.data-testcafe]="'sideChat-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
    (click)="onClose()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<ng-template #loadingTemplate>
  <div class="d-flex w-100 justify-content-center align-items-center h-75">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #loadingTemplateMessages>
  <div class="d-flex flex-grow-1 w-100 h-75 justify-content-center align-items-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

<ng-template #noMessages>
  <div class="d-flex flex-grow-1 w-100 justify-content-center align-items-center">Keine Nachrichten vorhanden</div>
</ng-template>
