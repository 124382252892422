import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';
import {
  CompositionChildResponse,
  CompositionRecommendationItem,
  CompositionRecommendationResponse,
} from '@domain/app/composition.domain';
import { IndividualTaskRespsonseItem, TaskRecommendationItem } from '@domain/app/task.domain';
import { TransitionRecommendationItem, TransitionResponse } from '@domain/app/transition.domain';
import { MediaTypeEnum, ProductTypesEnum } from '@enums';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { QueryService } from '@services/query-service/query.service';
import { IntersectionStatus } from '@utils/directives/intersection.directive';
import { color, libIcons } from 'bgzv-frontend-library';
import { Observable, Subject, forkJoin, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'item-cart',
  templateUrl: './item-cart.component.html',
  styleUrls: ['./item-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemCartComponent implements OnInit, OnDestroy {
  @Input() cartType: 'cart' | 'summary' = 'summary';
  @Input() subtopicId = null;
  @Output() scrolled = new EventEmitter<boolean>();
  @Output() allRecommendationsSelected = new EventEmitter<boolean>();

  @ViewChild('tabs') tabGroup: MatTabGroup;

  private scrolledPos = 0;
  private compositionId: string;
  private destroySubs = new Subject<void>();

  public cartData: CompositionRecommendationItem[];
  public individualTasks: IndividualTaskRespsonseItem[];
  public individualTransitions: TransitionResponse[];

  public currentTab = ProductTypesEnum.products;
  public tab = ProductTypesEnum;

  public productCount = 0;
  public taskCount = 0;
  public transitionCount = 0;
  public intersectingId = '0';

  public loading = false;

  public recommendedMainProduct: Array<{ id: string; selected: boolean; name: string; type: ProductTypesEnum }> = [];
  public mainProducts: Array<{ id: string; name: string }> = [];
  public mainProductsWithTasks: Array<{ id: string; name: string }> = [];
  public mainProductsWithTransitions: Array<{ id: string; name: string }> = [];

  public scrolledDown = false;

  public recommendationType: ProductTypesEnum;
  public showSelectAllRecommendedProductsButton: boolean = false;
  public showSelectAllRecommendedTasksButton: boolean = false;
  public showSelectAllRecommendedTransitionsButton: boolean = false;

  readonly color = color;
  readonly buttonIcon = libIcons;
  private intitialCartOpening = true;

  constructor(
    private chgRef: ChangeDetectorRef,
    private actionService: ActionService,
    private clientService: ClientService,
    private queryService: QueryService,
    private mediaService: MediaService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.loading = loading;
      this.chgRef.detectChanges();
    });

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'cart-item') {
        if (action.action === 'reload-cart' || action.action === 'reload-summary') {
          this.retrieveRecommendationData(action?.options?.productExtras);
        }
        if (action.action === 'all-recommended-selected') {
          this.handleSelectAllRecommended(action.options);
        }
        if (action.action === 'indiv-task-selected') {
          this.showTaskIndiv('-1');
        }
        if (action.action === 'indiv-transition-selected') {
          this.showTransitionIndiv('-1');
        }
      }
    });

    this.retrieveRecommendationData();
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    const oldScroll = this.scrolledDown;
    this.scrolledDown = event.target.scrollTop > 20;
    this.scrolledPos = event.target.scrollTop;

    if (oldScroll !== this.scrolledDown) {
      this.scrolled.emit(this.scrolledDown);
    }
  }

  public onTabButtonSelected(index: number) {
    this.currentTab = Object.values(ProductTypesEnum).at(index);
    if (this.currentTab === ProductTypesEnum.products) {
      this.doAction('footer', 'show-all-recommended', {
        allRecommendedChecked: this.allRecommendedProductsSelected,
        showIndivTasks: false,
        showIndivTransitions: false,
      });
    } else if (this.currentTab === ProductTypesEnum.tasks) {
      this.doAction('footer', 'show-all-recommended', {
        allRecommendedChecked: this.allRecommendedProductsSelected,
        showIndivTaskButton: true,
        showIndivTransitionButton: false,
      });
    } else if (this.currentTab === ProductTypesEnum.transitions) {
      this.doAction('footer', 'show-all-recommended', {
        allRecommendedChecked: this.allRecommendedProductsSelected,
        showIndivTaskButton: false,
        showIndivTransitionButton: true,
      });
    }

    this.doAction('overlay-cart', 'show-all-recommended', {
      allRecommendedChecked: this.allRecommendedProductsSelected,
    });
  }

  public showProductDetails(compositionId: string, productId: string, item: CompositionRecommendationItem) {
    this.doAction('main', 'product', {
      compositionId: compositionId,
      productId: productId,
      hideButton: item.mainProduct.id === productId && item.mainProduct.variants.length > 0,
    });
  }

  public showDataFields(compositionId: string, productId: string) {
    this.doAction('main', 'product', {
      compositionId: compositionId,
      productId: productId,
      showDataFields: true,
      hideButton: true,
    });
  }

  public showExtraProductDetails(compositionId: string) {
    this.compositionId = compositionId;
    this.doAction('main', 'productExtrasToggle', {
      compositionData: this.cartData.find(x => x.id === compositionId),
    });
  }

  public showTask(compositionId: number, compositionTitle: string, event: CompositionChildResponse) {
    this.doAction('main', 'task', {
      compositionId: compositionId,
      compositionTitle: compositionTitle,
      taskId: event.elementId,
      skipSelection: event.skipSelection,
      selected: event.selected,
      mainData: event.mainData,
    });
  }

  public showTaskIndiv(indivTaskId: string) {
    this.doAction('main', 'task-indiv', {
      taskId: indivTaskId || '-1',
      subtopicId: this.subtopicId || '-1',
      skipSelection: true,
    });
  }

  public showTransitionIndiv(indivTransitionId: string) {
    this.doAction('main', 'transition-indiv', {
      transitionId: indivTransitionId || '-1',
      subtopicId: this.subtopicId || '-1',
      skipSelection: true,
    });
  }

  public showTransition(compositionId: number, compositionTitle: string, event: CompositionChildResponse) {
    this.doAction('main', 'transition', {
      compositionId: compositionId,
      compositionTitle: compositionTitle,
      transitionId: event.elementId,
      skipSelection: event.skipSelection,
      selected: event.selected,
      mainData: event.mainData,
    });
  }

  public setProductSelectedState(compositionId: string, eventData) {
    this.retrieveRecommendationData();
  }

  public setProductQuantity(compositionId: string, eventData) {
    this.setProductCount();
  }

  public assignedTask(compositionId: string, isAssigned: boolean) {
    this.retrieveRecommendationData();
    this.setTaskCount();
  }

  public assignedTransition(compositionId: string, isAssigned: boolean) {
    this.retrieveRecommendationData();
    this.setTransitionCount();
  }

  public handleSelectAllRecommended(checked: boolean) {
    if (checked === false) {
      return;
    }

    const compositionArray = this.compositionIdMapFactory();
    const handlePutRequests = (x: { cId: string; pId: string }, type: ProductTypesEnum): Observable<any> => {
      if (type === ProductTypesEnum.products) {
        return this.queryService.putSelectProduct(this.clientService.consultationId, {
          compositionId: x.cId,
          productId: x.pId,
          quantity: 1,
        });
      }
      if (type === ProductTypesEnum.transitions) {
        return this.queryService.putSelectTransition(this.clientService.consultationId, {
          compositionId: x.cId,
          transitionId: x.pId,
        });
      }
      if (type === ProductTypesEnum.tasks) {
        return this.queryService.putSelectTask(this.clientService.consultationId, {
          compositionId: x.cId,
          taskId: x.pId,
        });
      }

      return of(null);
    };

    const selectRequestArray: Observable<any>[] = this.recommendedMainProduct
      .filter((v, i, a) => !v.selected || a.findIndex(t => t.id === v.id) === i)
      .reduce(
        (previousItem, item) =>
          item.selected
            ? previousItem
            : [
                ...previousItem,
                ...compositionArray.filter(x => x.pId === item.id).map(x => handlePutRequests(x, item.type)),
              ],
        []
      );

    forkJoin(selectRequestArray).subscribe(x => {
      this.retrieveRecommendationData();
    });
  }

  public sortTasks(data) {
    return data.sort((a, b) => (a.id < b.id ? -1 : 1));
  }

  public retrieveRecommendationData(productExtras = false) {
    this.queryService
      .getRecommendedCompositions(this.clientService.consultationId, this.subtopicId)
      .pipe(
        map(data => {
          data?.recommendations.sort((a, b) => (a.id < b.id ? -1 : 1));
          data?.individualTasks.sort((a, b) => (a.id < b.id ? -1 : 1));
          data?.individualTransitions.sort((a, b) => (a.id < b.id ? -1 : 1));
          return data;
        })
      )
      .subscribe(async data => {
        if (data === null) {
          return;
        }
        await this.rewriteMediaContent(data);
        this.cartData = data?.recommendations;
        this.individualTasks = data?.individualTasks;
        this.individualTransitions = data?.individualTransitions;

        if (this.compositionId && productExtras) {
          this.doAction('main', 'productExtrasUpdate', {
            compositionData: this.cartData.find(x => x.id === this.compositionId),
          });
        }

        this.setProductCount();
        this.setTaskCount();
        this.setTransitionCount();
        if (this.cartType === 'summary') {
          this.clientService.setSolutionBasketCount();
        }

        if (this.intitialCartOpening) {
          this.onTabButtonSelected(this.setDefaultTab());
          this.intitialCartOpening = false;
        }

        const c = document.querySelector(`#container_${this.cartType}`) as HTMLElement;
        if (c) {
          c.scrollTop = this.scrolledPos;
        }

        this.doAction('overlay-cart', 'show-all-recommended', {
          allRecommendedChecked: this.allRecommendedProductsSelected,
        });

        this.chgRef.detectChanges();
      });
  }

  private setDefaultTab(): number {
    if (this.productCount !== 0) {
      this.currentTab = ProductTypesEnum.products;
      this.tabGroup.selectedIndex = 0;
      return 0;
    }
    if (this.taskCount !== 0) {
      this.currentTab = ProductTypesEnum.tasks;
      this.tabGroup.selectedIndex = 1;
      return 1;
    }
    if (this.transitionCount !== 0) {
      this.currentTab = ProductTypesEnum.transitions;
      this.tabGroup.selectedIndex = 2;
      return 2;
    } else {
      this.currentTab = ProductTypesEnum.products;
      return 0;
    }
  }

  // --------------------------------------------- //
  // ------------ JUMP & INTERSECTION ------------ //
  // --------------------------------------------- //

  public onVisibilityChanged(cartItem: CompositionRecommendationItem, status: IntersectionStatus) {
    if (status === IntersectionStatus.Visible) {
      this.intersectingId = cartItem.mainProduct.id;
      this.chgRef.detectChanges();
    }
  }

  public handleCartJump(itemId) {
    const element = document.querySelector(`#main_${itemId}`) as HTMLElement;
    const offset = element.offsetTop - element.parentElement.offsetTop;
    element.parentElement.scroll({ top: offset, behavior: 'smooth' });
  }

  public showProductsInCart(cartItem: CompositionRecommendationItem): boolean {
    return (
      (this.cartType === 'summary' && !!cartItem.mainProduct) || (this.cartType === 'cart' && !!cartItem.mainProduct)
    );
  }

  public showTasksInCart(cartItem: TaskRecommendationItem): boolean {
    return this.cartType === 'summary' || (this.cartType === 'cart' && cartItem.selected);
  }

  public showTransitionsInCart(cartItem: TransitionRecommendationItem): boolean {
    return this.cartType === 'summary' || (this.cartType === 'cart' && cartItem.selected);
  }

  public getProductTypeTextColor(active: boolean): string {
    return active ? color.find(color.name.White) : color.find(color.name.Neutral700);
  }

  public getProductTypeButtonColor(active: boolean): string {
    return active ? color.find(color.name.BrandPrimary) : color.find(color.name.Neutral700);
  }

  public getRecommendedText(recommended) {
    let recommendedText: string = '';
    if (recommended.products > 0) {
      if (recommended.products === 1) {
        recommendedText += '1 Produkt';
      } else {
        recommendedText += recommended.products + ' Produkte';
      }
    }
    if (recommended.tasks > 0) {
      if (recommended.products > 0) {
        recommendedText += ', ';
      }
      if (recommended.tasks === 1) {
        recommendedText += '1 Maßnahme';
      } else {
        recommendedText += recommended.tasks + ' Maßnahmen';
      }
    }
    if (recommended.transitions > 0) {
      if (recommended.products > 0 || recommended.tasks > 0) {
        recommendedText += ', ';
      }
      if (recommended.transitions === 1) {
        recommendedText += '1 Expertengespräch';
      } else {
        recommendedText += recommended.transitions + ' Expertengespräche';
      }
    }
    return recommendedText;
  }

  // --------------------------------------------- //
  // ------------- PRIVATE FUNCTIONS ------------- //
  // --------------------------------------------- //

  private setProductCount() {
    const init = this.cartData.flatMap(x => x.mainProduct).filter(y => y !== undefined);
    this.productCount = init.reduce((previousValue, currentValue) => {
      const variants = currentValue.variants;
      const variantsCount = variants
        ?.filter(x => x?.selected)
        .reduce((sum, current) => sum + current.selectedQuantity, 0);
      const additionals = currentValue.additionals;
      const additionalsCount = additionals
        ?.filter(x => x.selected)
        .reduce((sum, current) => sum + current.selectedQuantity, 0);
      const variantsAdditionals =
        variants?.reduce((variantsPreviousValue, variantsCurrentValue) => {
          const additionals = variantsCurrentValue.additionals;
          return (
            variantsPreviousValue +
            additionals?.filter(x => x.selected).reduce((sum, current) => sum + current.selectedQuantity, 0)
          );
        }, 0) | 0;
      return previousValue + variantsCount + additionalsCount + variantsAdditionals + currentValue.selectedQuantity;
    }, 0);

    this.recommendedMainProduct = [];
    this.mainProducts = [];
    this.mainProductsWithTasks = [];
    this.mainProductsWithTransitions = [];

    init.forEach(mainProduct => {
      if (this.cartType === 'summary' || this.cartType === 'cart') {
        this.mainProducts.push({ id: mainProduct.id, name: mainProduct.name });
      }

      // get all recommended additionals and variants
      if (mainProduct.recommended === true) {
        this.recommendedMainProduct.push({
          id: mainProduct.id,
          selected: mainProduct.selected,
          name: mainProduct.name,
          type: ProductTypesEnum.products,
        });
      }

      mainProduct?.variants
        .filter(v => v.recommended === true)
        .map(v =>
          this.recommendedMainProduct.push({
            id: v.id,
            selected: v.selected,
            name: v.name,
            type: ProductTypesEnum.products,
          })
        );

      mainProduct?.additionals
        .filter(a => a.recommended === true)
        .map(a =>
          this.recommendedMainProduct.push({
            id: a.id,
            selected: a.selected,
            name: a.name,
            type: ProductTypesEnum.products,
          })
        );
    });

    this.cartData
      .flatMap(x => {
        x.tasks?.length > 0 && x.mainProduct && this.mainProductsWithTasks.push({ id: x.id, name: x.name });
        return x.tasks;
      })
      .filter(x => x.recommended === true)
      .map(a =>
        this.recommendedMainProduct.push({ id: a.id, selected: a.selected, name: a.name, type: ProductTypesEnum.tasks })
      );

    this.cartData
      .flatMap(x => {
        x.transitions?.length > 0 && x.mainProduct && this.mainProductsWithTransitions.push({ id: x.id, name: x.name });
        return x.transitions;
      })
      .filter(x => x.recommended === true)
      .map(a =>
        this.recommendedMainProduct.push({
          id: a.id,
          selected: a.selected,
          name: a.name,
          type: ProductTypesEnum.transitions,
        })
      );
  }

  private setTaskCount() {
    let count = 0;
    const init = this.cartData.flatMap(x => x.tasks).filter(y => y !== undefined);
    count = init.filter(z => z.selected).length || 0;
    this.taskCount = count + this.individualTasks.filter(x => x.selected).length;
  }

  private setTransitionCount() {
    let count = 0;
    const init = this.cartData.flatMap(x => x.transitions).filter(y => y !== undefined);
    count = init.filter(z => z.selected).length || 0;
    this.transitionCount = count + this.individualTransitions.filter(x => x.selected).length;
  }

  private compositionIdMapFactory(): { cId: string; pId: string }[] {
    let compositionArray: { cId: string; pId: string }[] = [];

    this.cartData.forEach(composition => {
      const cId = composition.id;
      if (composition.mainProduct) {
        compositionArray.push({ cId: cId, pId: composition.mainProduct.id });
        composition.mainProduct.variants.map(x => compositionArray.push({ cId: cId, pId: x.id }));
        composition.mainProduct.additionals.map(x => compositionArray.push({ cId: cId, pId: x.id }));
      }
      if (composition.tasks) {
        composition.tasks.map(x => compositionArray.push({ cId: cId, pId: x.id }));
      }
      if (composition.transitions) {
        composition.transitions.map(x => compositionArray.push({ cId: cId, pId: x.id }));
      }
    });

    return compositionArray;
  }

  private doAction(target: string = '', action: string = '', options?: any) {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private async rewriteMediaContent(data: CompositionRecommendationResponse): Promise<void> {
    await Promise.all(
      data.recommendations.map(async recommendation => {
        if (!!recommendation?.media && recommendation?.media.type === MediaTypeEnum.image) {
          recommendation.media.url = await this.mediaService.getMediaContent(recommendation.media.url);
        }
      })
    );
  }

  get productRecommendedCount() {
    const init = this.cartData?.flatMap(x => x.mainProduct).filter(y => y !== undefined);
    return init.reduce((previousValue, currentValue) => {
      const variants = currentValue.variants;
      const variantsCount = variants?.filter(x => x?.recommended).length | 0;
      const additionals = currentValue.additionals;
      const additionalsCount = additionals?.filter(x => x.recommended).length | 0;
      const variantsAdditionals =
        variants?.reduce((variantsPreviousValue, variantsCurrentValue) => {
          const additionals = variantsCurrentValue.additionals;
          return variantsPreviousValue + (additionals?.filter(x => x.recommended).length | 0);
        }, 0) | 0;
      return (
        previousValue + variantsCount + additionalsCount + variantsAdditionals + (currentValue.recommended ? 1 : 0)
      );
    }, 0);
  }

  get taskRecommendedCount() {
    const init = this.cartData.flatMap(x => x.tasks).filter(y => y !== undefined);
    return init.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.recommended ? 1 : 0);
    }, 0);
  }

  get transitionRecommendedCount() {
    const init = this.cartData.flatMap(x => x.transitions).filter(y => y !== undefined);
    return init.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue.recommended ? 1 : 0);
    }, 0);
  }

  get totalRecommendedCount() {
    return this.productRecommendedCount + this.taskRecommendedCount + this.transitionRecommendedCount;
  }

  get productCompositionCount(): number {
    return this.cartData?.flatMap(x => x.mainProduct).filter(y => y !== undefined).length || 0;
  }

  get taskCompositionCount(): number {
    return this.cartData?.flatMap(x => x.tasks).filter(y => y !== undefined).length || 0;
  }

  get transitionCompositionCount(): number {
    return this.cartData?.flatMap(x => x.transitions).filter(y => y !== undefined).length || 0;
  }

  get totalCartCount(): number {
    return this.productCount + this.taskCount + this.transitionCount;
  }

  get allRecommendedProductsSelected() {
    return this.recommendedMainProduct.length > 0 && this.recommendedMainProduct.every(x => x.selected === true);
  }

  get allRecommendedTasksSelected() {
    return (
      this.recommendedMainProduct.length > 0 &&
      this.recommendedMainProduct.filter(x => x.type === ProductTypesEnum.tasks).every(x => x.selected === true)
    );
  }

  get allRecommendedTransitionsSelected() {
    return (
      this.recommendedMainProduct.length > 0 &&
      this.recommendedMainProduct.filter(x => x.type === ProductTypesEnum.transitions).every(x => x.selected === true)
    );
  }

  get rootDiv() {
    return document.querySelector(`#container_${this.cartType}`) as HTMLElement;
  }

  get rootMargin() {
    const c = document.querySelector(`#container_${this.cartType}`) as HTMLElement;
    return '-40% 0px -50%';
  }

  get recommendedCount() {
    return {
      products: this.recommendedMainProduct.filter(x => x.type === ProductTypesEnum.products).length,
      tasks: this.recommendedMainProduct.filter(x => x.type === ProductTypesEnum.tasks).length,
      transitions: this.recommendedMainProduct.filter(x => x.type === ProductTypesEnum.transitions).length,
    };
  }
}
