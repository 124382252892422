<div class="d-flex flex-column h-100 bg-white">
  <marginals-header></marginals-header>
  <ng-container *ngIf="!checkoutFinished">
    <div class="d-flex flex-column flex-grow-1 flex-scroll">
      <header
        class="d-flex w-100 justify-content-between checkout-main-margin mt-4 checkout-header"
        style="height: 6.5rem"
      >
        <h2 class="vr-headline-300 vr-color-brand-primary-alternative font-weight-bold">Gewählte Lösungen</h2>
        <div class="d-flex align-items-start align-self-end">
          <button
            class="mr-4"
            [attr.data-testcafe]="'screenCheckout-button-sideNotes'"
            [disabled]="isLoading"
            (click)="onOpenNotes()"
            mat-stroked-button
            color="primary"
          >
            <kf-icon name="ic_herunterladen_24"></kf-icon>
            <span>Angehängte Notizen & Dokumente</span>
          </button>
          <div>
            <ng-template
              *ngTemplateOutlet="dataFieldButton; context: { item: generalGroup, cId: -1, type: 'general' }"
            ></ng-template>
            <ng-template *ngTemplateOutlet="errorHint"></ng-template>
          </div>
        </div>
      </header>
      <div *ngIf="hasCompositions; else noCompositions" class="checkout-main-grid checkout-main-margin mb-4">
        <ng-container *ngFor="let composition of checkoutCompositions">
          <bgzv-image
            *ngIf="composition.media && composition.media.url; else defaultImage"
            class="composition-image"
            [alt]="'icon'"
            [src]="composition.media.url"
          ></bgzv-image>
          <div>
            <h3 class="vr-headline-200 font-weight-bold mb-3">{{ composition.name }}</h3>
            <ng-template
              *ngTemplateOutlet="product; context: { data: composition.products, compositionId: composition.id }"
            ></ng-template>
            <div *ngIf="composition.products.length" class="mt-4"></div>
            <ng-template
              *ngTemplateOutlet="task; context: { data: composition.tasks, compositionId: composition.id }"
            ></ng-template>
            <div *ngIf="composition.tasks.length || composition.products.length" class="mt-4"></div>
            <ng-template
              *ngTemplateOutlet="transition; context: { data: composition.transitions, compositionId: composition.id }"
            ></ng-template>
          </div>
        </ng-container>
      </div>
    </div>
    <section
      *ngIf="hasCompositions"
      class="checkout-main-grid checkout-main-margin py-3 vr-bg-color-neutral-200"
      [class.side-footer-margin]="useMargin"
    >
      <div></div>
      <div>
        <ng-template *ngTemplateOutlet="totals; context: { data: checkoutTotals }"></ng-template>
      </div>
    </section>
  </ng-container>
</div>

<ng-template #product let-data="data" let-compositionId="compositionId">
  <div
    *ngIf="data.length > 0"
    class="vr-text-small checkout-production-grid vr-bg-color-neutral-100 font-weight-bold py-1"
  >
    <div class="checkout-place-start">Produkt</div>
    <div class="checkout-place-start">Abrechnung</div>
    <div class="checkout-place-end">Einzelpreis</div>
    <div class="checkout-place-end">Menge</div>
    <div class="checkout-place-end">Nettopreis</div>
    <div class="checkout-place-end">Bruttopreis</div>
    <div class="checkout-place-center"></div>
  </div>
  <div
    *ngFor="let item of data; let last = last; let i = index"
    id="checkout_{{ item.id }}"
    class="checkout-production-grid py-2"
    [class.checkout-divider]="!last"
  >
    <div class="checkout-place-start">
      <h4 class="item-name vr-text-regular text-break mb-0">{{ item.name }}</h4>
    </div>
    <div class="checkout-place-start">
      <div *ngIf="item.fixCost">{{ item.fixCost.paymentInterval | payInterval }}</div>
      <div *ngIf="item.runningCost">{{ item.runningCost.paymentInterval | payInterval }}</div>
    </div>
    <div class="checkout-place-end">
      <div *ngIf="item.fixCost" markNegativePrice>{{ formatPrice(item.fixCost.netPrice) }}</div>
      <div *ngIf="item.runningCost" markNegativePrice>
        {{ formatPrice(item.runningCost.netPrice) }}
      </div>
    </div>
    <div class="checkout-place-end">
      <div *ngIf="item.fixCost || (!item.fixCost && !item.runningCost)">{{ item.selectedQuantity }}</div>
      <div *ngIf="item.runningCost">{{ item.selectedQuantity }}</div>
    </div>
    <div class="checkout-place-end">
      <div *ngIf="item.fixCost" markNegativePrice>
        {{ formatPrice(item.fixCost.netPrice, item.selectedQuantity) }}
      </div>
      <div *ngIf="item.runningCost" markNegativePrice>
        {{ formatPrice(item.runningCost.netPrice, item.selectedQuantity) }}
      </div>
    </div>
    <div class="checkout-place-end">
      <div *ngIf="item.fixCost" markNegativePrice>
        {{ formatPrice(item.fixCost.grossPrice, item.selectedQuantity) }}
      </div>
      <div *ngIf="item.runningCost" markNegativePrice>
        {{ formatPrice(item.runningCost.grossPrice, item.selectedQuantity) }}
      </div>
    </div>
    <div class="checkout-place-center">
      <ng-template
        *ngTemplateOutlet="dataFieldButton; context: { item: item, cId: compositionId, type: 'products' }"
      ></ng-template>
      <ng-template *ngTemplateOutlet="errorHint"></ng-template>
    </div>
    <div class="checkout-place-center">
      <ng-template
        *ngTemplateOutlet="contractStatusDisplay; context: { item: item, cId: compositionId, type: 'products' }"
      ></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #task let-data="data" let-compositionId="compositionId">
  <div
    *ngIf="data.length > 0"
    class="vr-text-small checkout-transition-grid vr-bg-color-neutral-100 font-weight-bold py-1"
  >
    <div class="checkout-place-start">Maßnahme</div>
    <div class="checkout-place-start">Fälligkeitsdatum</div>
    <div class="checkout-place-start column-span-4">Verantwortlich</div>
    <div class="checkout-place-center"></div>
  </div>
  <div
    *ngFor="let item of data; let last = last"
    id="checkout_{{ item.id }}"
    class="checkout-transition-grid py-2"
    [class.checkout-divider]="!last"
  >
    <div class="checkout-place-start">
      <h4 class="item-name vr-text-regular text-break mb-0">{{ item.name }}</h4>
    </div>
    <div class="checkout-place-start">{{ formatTaskDate(item.appointmentDate) }}</div>
    <div class="checkout-place-start column-span-4">{{ formatContact(item.customerContact) }}</div>
    <div class="checkout-place-center">
      <button
        [disabled]="isLoading"
        [attr.data-testcafe]="'checkoutScreen-button-assign-' + getTestcafeId(item.name)"
        *ngIf="!item.customerContact"
        mat-stroked-button
        color="primary"
        (click)="openTaskAssignment(compositionId, item.name, item)"
      >
        <span>Zuweisen</span>
      </button>

      <div *ngIf="item.customerContact">
        <ng-template
          *ngTemplateOutlet="dataFieldButton; context: { item: item, cId: compositionId, type: 'tasks' }"
        ></ng-template>
      </div>
      <ng-template *ngTemplateOutlet="errorHint"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #transition let-data="data" let-compositionId="compositionId">
  <div
    *ngIf="data.length > 0"
    class="vr-text-small checkout-transition-grid vr-bg-color-neutral-100 font-weight-bold py-1"
  >
    <div class="checkout-place-start">Expertengespräche</div>
    <div class="checkout-place-start">Terminbeginn</div>
    <div class="checkout-place-start column-span-2">Verantwortlich</div>
    <div class="checkout-place-start column-span-2">Ansprechpartner des Unternehmens</div>
    <div class="checkout-place-center"></div>
  </div>
  <div
    *ngFor="let item of data; let last = last"
    id="checkout_{{ item.id }}"
    class="checkout-transition-grid py-2"
    [class.checkout-divider]="!last"
  >
    <div class="checkout-place-start">
      <h4 class="item-name vr-text-regular text-break mb-0">{{ item.name }}</h4>
    </div>
    <div class="checkout-place-start">
      <ng-container *ngFor="let date of item.appointments">
        <div>{{ formatTransitionDate(date.appointmentStart) }}</div>
      </ng-container>
    </div>
    <div class="checkout-place-start column-span-2">{{ formatContact(item.expertContact) }}</div>
    <div class="checkout-place-start column-span-2">{{ formatContact(item.customerContact) }}</div>
    <div class="checkout-place-center">
      <button
        *ngIf="!item.expertContact"
        [disabled]="isLoading"
        [attr.data-testcafe]="'checkoutScreen-button-dateSuggestion'"
        (click)="openTransitionAssignment(compositionId, item.name, item)"
        mat-stroked-button
        color="primary"
      >
        <span>Termin vorschlagen</span>
      </button>
      <div *ngIf="item.expertContact">
        <ng-template
          *ngTemplateOutlet="dataFieldButton; context: { item: item, cId: compositionId, type: 'transitions' }"
        ></ng-template>
        <ng-template *ngTemplateOutlet="errorHint"></ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #dataFieldButton let-item="item" let-cId="cId" let-type="type">
  <ng-container *ngIf="type === 'general'">
    <ng-container *ngIf="item">
      <button
        (click)="onOpenDataFields(item, cId, type)"
        [attr.data-testcafe]="'checkoutScreen-button-costumerData'"
        [disabled]="isLoading"
        *ngIf="item.dataFieldStatus === dataFieldStatus.notComplete"
        color="primary"
        id="checkout_general"
        mat-stroked-button
      >
        <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
        <span>Allgemeine Kundendaten</span>
      </button>

      <button
        (click)="onOpenDataFields(item, cId, type)"
        [attr.data-testcafe]="'checkoutScreen-button-completeData'"
        [disabled]="isLoading"
        *ngIf="item.dataFieldStatus === dataFieldStatus.mandatoryDone"
        color="primary"
        id="checkout_general"
        mat-stroked-button
      >
        <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
        <span>Allgemeine Kundendaten</span>
      </button>

      <button
        (click)="onOpenDataFields(item, cId, type)"
        [attr.data-testcafe]="'checkoutScreen-button-done'"
        [disabled]="isLoading"
        *ngIf="item.dataFieldStatus === dataFieldStatus.complete"
        color="primary"
        id="checkout_general"
        mat-stroked-button
      >
        <kf-icon name="ic_person_mit_kreis_24"></kf-icon>
        <span>Allgemeine Kundendaten</span>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type !== 'general'">
    <ng-container *ngIf="item">
      <button
        [disabled]="isLoading"
        *ngIf="item.dataFieldStatus === dataFieldStatus.notComplete"
        [attr.data-testcafe]="'checkoutScreen-button-costumerData'"
        (click)="onOpenDataFields(item, cId, type)"
        mat-stroked-button
        color="primary"
      >
        <span>Kundendaten</span>
      </button>

      <button
        *ngIf="item.dataFieldStatus === dataFieldStatus.mandatoryDone"
        [attr.data-testcafe]="'checkoutScreen-button-completeData'"
        [disabled]="isLoading"
        mat-stroked-button
        color="primary"
        (click)="onOpenDataFields(item, cId, type)"
      >
        <span>Vervollständigen</span>
      </button>

      <button
        *ngIf="item.dataFieldStatus === dataFieldStatus.complete"
        [attr.data-testcafe]="'checkoutScreen-button-done'"
        [disabled]="isLoading"
        mat-stroked-button
        color="primary"
        (click)="onOpenDataFields(item, cId, type)"
      >
        <kf-icon name="ic_haken_24"></kf-icon>
        <span>Fertig</span>
      </button>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #errorHint>
  <div class="error-hint mt-1">
    <div class="d-flex">
      <kf-icon class="mr-2" name="ic_warnschild_24"></kf-icon>
      <span class="text-nowrap">Angaben erforderlich</span>
    </div>
  </div>
</ng-template>

<ng-template #totals let-data="data">
  <section class="checkout-totals-grid">
    <div class="grid-span-8">
      <h3 class="vr-text-large font-weight-bold mb-0">Gesamtsumme</h3>
      <div class="vr-text-small">
        Entspricht der Bruttopreis dem Nettopreis, so handelt es <br />
        sich um umsatzsteuerbefreite Bankdienstleistungen.
      </div>
    </div>
    <ng-container *ngFor="let item of data">
      <div class="checkout-place-end">{{ item.type | payInterval }}</div>
      <div class="checkout-place-end font-weight-bold" markNegativePrice>
        {{ formatPrice(item.netPrice) }}
      </div>
      <div class="checkout-place-end font-weight-bold" markNegativePrice>
        {{ formatPrice(item.grossPrice) }}
      </div>
    </ng-container>
  </section>
</ng-template>

<ng-template #defaultImage>
  <img width="80" height="66" alt="" src="{{ assetPath }}/images/checkout-composition-icon.png" />
</ng-template>

<ng-template #noCompositions>
  <div class="no-composition">
    <div class="d-flex flex-column align-items-center">
      <img alt="" class="empty-recommendation-icon" src="{{ assetPath }}/images/empty-recommendation-icon.svg" />
      <div class="vr-text mt-3 empty-message">Es wurden keine Produkte, Maßnahmen oder Expertengespräche gewählt.</div>
    </div>
  </div>
</ng-template>

<ng-template #contractStatusDisplay let-product="item">
  <!-- state: no errors, not pending -->
  <ng-container *ngIf="getHasContractForm(product.id)">
    <!-- state: pdf can be viewed -->
    <ng-container *ngIf="getContractPDFViewable(product)">
      <a
        class="d-flex align-items-center"
        [style.color]="color.find(color.name.InteractionPrimary)"
        tabindex="0"
        kfTooltip="Vertragsdokument kann angezeigt werden"
        kfTooltipPosition="before"
        kfTooltipShow="hover_focus"
      >
        <kf-icon name="ic_pdf_24"></kf-icon>
      </a>
    </ng-container>

    <!-- state: pdf can be created -->
    <ng-container
      *ngIf="!getContractPDFViewable(product) && !getContractPending(product) && !getContractError(product)"
    >
      <a
        class="d-flex align-items-center"
        [style.color]="color.find(color.name.Neutral400)"
        tabindex="0"
        kfTooltip="Vertragsvorschau kann generiert werden"
        kfTooltipPosition="before"
        kfTooltipShow="hover_focus"
      >
        <kf-icon name="ic_pdf_24"></kf-icon>
      </a>
    </ng-container>
  </ng-container>

  <!-- state: pending -->
  <ng-container *ngIf="getContractPending(product)">
    <a
      class="d-flex align-items-center"
      tabindex="0"
      kfTooltip="Vertragsvorschau wird generiert"
      kfTooltipPosition="before"
      kfTooltipShow="hover_focus"
    >
      <mat-spinner [diameter]="24" [color]="'primary'"></mat-spinner>
    </a>
  </ng-container>

  <!-- state: error -->
  <ng-container *ngIf="getContractError(product)">
    <a
      class="d-flex align-items-center"
      [style.color]="color.find(color.name.Error)"
      [kfTooltip]="'Fehler: Vertragsinformationen zu ' + product.name + ' können nicht angezeigt werden.'"
      kfTooltipPosition="before"
      kfTooltipShow="hover_focus"
      tabindex="0"
    >
      <kf-icon class="mr-2" name="ic_warnschild_24"></kf-icon>
    </a>
  </ng-container>
</ng-template>
