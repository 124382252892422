<form class="compare-accounts-inputs my-5">
  <ng-container *ngFor="let input of inputs.inputs; index as i">
    <div *ngIf="input.inputType === 'TOGGLE'">
      <label class="d-flex align-items-center mt-3" [style.marginBottom]="'2.7rem'">
        <span class="d-inline-block">{{ input.inputName }}</span>
        <kf-icon
          name="ic_infozeichen_mit_kreis_24"
          class="mx-2"
          [kfTooltip]="input.tooltip"
          kfTooltipPosition="before"
          kfTooltipShow="hover_focus"
        ></kf-icon>
      </label>
      <mat-slide-toggle
        [checked]="inputs.persistedInputs && inputs.persistedInputs[input.id] >= 0"
        (change)="setValue(input.id, $event.checked)"
        color="primary"
      ></mat-slide-toggle>
    </div>
    <vr-slider
      #accounts
      *ngIf="input.inputType === 'SLIDER'"
      [maxValue]="input.maxValue"
      [stepValue]="input.stepValue"
      [defaultNumber]="getDefaultValue(input)"
      (valueSelected)="setValue(input.id, $event.value)"
      [infoTooltip]="input.tooltip"
      [showMarks]="false"
      [label]="input.inputName"
      [disabled]="isLoading"
      [answered]="true"
      [input]="true"
      [testcafeData]="input.inputName"
      (tabbed)="handleTab(i)"
      [color]="'primary'"
    ></vr-slider>
  </ng-container>
</form>
