import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoutingPathMain } from '@enums';
import { AuthService } from '@modules/auth';
import { ContextService } from '@services/context-service/context.service';
import { color } from 'bgzv-frontend-library';
import { Subscription } from 'rxjs';

@Component({
  selector: 'screen-login',
  templateUrl: './screen-login.component.html',
  styleUrls: ['./screen-login.component.scss'],
})
export class ScreenLoginComponent implements OnInit, OnDestroy {
  private _authSub: Subscription;
  private _authErrorSub: Subscription;

  loginForm: FormGroup;
  passwordChangeForm: FormGroup;
  passwordResetForm: FormGroup;

  loading = false;
  submitted = false;
  showPassword: boolean = false;
  error: string;

  readonly color = color;
  public loginState = 'defaultLogin';
  public loginHeader = null;
  public loginMessage = null;
  authObject: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private contextService: ContextService
  ) {
    this.loginHeader = 'Willkommen.';
    this.loginMessage = null;

    if (this.router.getCurrentNavigation()?.extras.state) {
      const stateObj = this.router.getCurrentNavigation().extras.state;
      // do stuff...
      if (stateObj.reason) {
        if (stateObj.reason === 'logout') {
          this.loginHeader = 'Auf Wiedersehen.';
          this.loginMessage = 'Sie haben sich erfolgreich ausgeloggt.';
        } else if (stateObj.reason === 'unauthorized') {
          this.loginHeader = 'Abgemeldet.';
          this.loginMessage = 'Die Session ist abgelaufen.';
        }
      }
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.passwordChangeForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this.passwordResetForm = this.formBuilder.group({
      code: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    this._authSub = this.authService.currentAuthState.subscribe(x => {
      if (this.authService.authState === 'defaultLogin') {
        this.loginState = this.authService.authState;
        this.error = '';
        this.loading = false;
      }
      if (this.authService.authState === 'signedIn') {
        this.error = '';
        this.loading = false;

        this.router.navigate([RoutingPathMain.Welcome]);
      }
      if (this.authService.authState === 'requireNewPassword') {
        console.log(`%c [bgzv-frontend-main] - Auth - new password required`, 'color: #0066cc');
        this.loginState = this.authService.authState;
        this.submitted = false;
        this.loading = false;
        this.error = '';
      }
      if (this.authService.authState === 'requireResetPassword') {
        console.log(`%c [bgzv-frontend-main] - Auth - password needs to be reset`, 'color: #0066cc');
        this.loginMessage = null;
        this.loginState = this.authService.authState;
        this.submitted = false;
        this.loading = false;
        this.error = '';
        this.formResetUsername.setValue(this.formUsername.value || '');
      }
    });

    this._authErrorSub = this.authService.currentErrorState.subscribe(error => {
      this.handleErrorState(error);
    });
  }

  ngOnDestroy(): void {
    this._authSub?.unsubscribe();
    this._authErrorSub?.unsubscribe();
  }

  handleLogin() {
    this.submitted = true;
    this.loading = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.loading = false;
      return;
    }
    this.authService
      .login(this.formUsername.value, this.formPassword.value)
      .then(x => {})
      .catch(e => {});
  }

  handlePasswordChange() {
    this.submitted = true;
    this.loading = true;

    if (this.passwordChangeForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .login(this.formUsername.value, this.formPassword.value, this.formChangePassword.value)
      .then(() => {
        this.loginState = '';
        this.error = '';
        this.submitted = false;
        this.loading = false;

        this.formPassword.reset();
      })
      .catch(e => {});
  }

  handlePasswordReset() {
    this.submitted = true;
    this.loading = true;

    if (this.passwordResetForm.invalid) {
      this.loading = false;
      return;
    }

    this.authService
      .resetPassword(this.formResetUsername.value, this.formCode.value, this.formResetPassword.value)
      .then(() => {
        this.loginState = '';
        this.error = '';
        this.submitted = false;
        this.loading = false;
        this.passwordResetForm.reset();
      })
      .catch(e => {});
  }

  handleErrorState(err) {
    this.loading = false;
    if (err === undefined || err === true || err?.code === undefined) {
      this.error = '';
      return false;
    }

    if (err?.code === 'NotAuthorizedException') {
      this.error = 'Falsches Passwort';
    } else if (err?.code === 'UserNotFoundException') {
      this.error = 'Nutzer exisitert nicht';
    } else if (err?.code === 'InvalidPasswordException') {
      this.error = 'Passwort entspricht nicht den Anforderungen';
    } else if (err?.code === 'ExpiredCodeException') {
      this.error = 'Verifizierungsfehler (Code abgelaufen?)';
    } else if (err?.code === 'LimitExceededException') {
      this.error = 'Zu viele Anmeldeversuche';
    }

    return true;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  get formControls() {
    return this.loginForm.controls;
  }
  get formPassword() {
    return this.formControls.password;
  }
  get formUsername() {
    return this.formControls.username;
  }

  get formChangeControls() {
    return this.passwordChangeForm.controls;
  }
  get formChangePassword() {
    return this.formChangeControls.password;
  }

  get formResetControls() {
    return this.passwordResetForm.controls;
  }
  get formCode() {
    return this.formResetControls.code;
  }
  get formResetUsername() {
    return this.formResetControls.username;
  }
  get formResetPassword() {
    return this.formResetControls.password;
  }

  get assetPath() {
    return this.contextService.assetPath;
  }
}
