<div class="d-flex w-100 h-100 position-relative" [style.backgroundColor]="color.find(color.name.White)">
  <div *ngIf="!inCheckout" class="menu-indent"></div>
  <ng-container *ngIf="!uploadPending && !fetchingDocumentPending; else loading">
    <ng-container *ngIf="previewMode; else uploadDocument">
      <ng-template *ngTemplateOutlet="documentPreview"></ng-template>
    </ng-container>
  </ng-container>

  <button
    *ngIf="!inCheckout"
    [attr.data-testcafe]="'documentsMenu-button-documentClose'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Dialog schließen"
    (click)="onCloseClick()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</div>

<ng-template #uploadDocument>
  <div *ngIf="!inCheckout" class="d-flex w-100 h-100 justify-content-center align-items-center">
    <documents-upload></documents-upload>
  </div>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>

<ng-template #documentPreview>
  <div class="w-100 h-100 documents-preview">
    <div class="position-relative d-flex h-100 w-100 justify-content-center align-items-center">
      <img *ngIf="imagePreviewSrc; else pdfPreviewWindow" [src]="imagePreviewSrc" alt="Vorschau Ihrer Zeichnung" />
    </div>
  </div>
</ng-template>

<ng-template #pdfPreviewWindow>
  <pdf-viewer [src]="pdfPreview" [render-text]="true" class="pdf-test"></pdf-viewer>
</ng-template>
