import { Injectable } from '@angular/core';
import { FooterAction, FooterConfig, FooterSection, FooterSectionType } from '@de.fiduciagad.kbm/shared-footer-lib';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, concatMap, Observable, Subject, take, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedFooterService {
  public footerConfigChanged = new BehaviorSubject<FooterConfig>(null);
  public footerPositionChanged = new BehaviorSubject<'float' | 'fix'>('float');
  public footerActionDispatched = new Subject<any>();
  public isManagedFooter = false;

  constructor() {}

  public submitFooterConfig(config: FooterConfig): void {
    const currentConfig = JSON.stringify(this.footerConfigChanged.getValue());
    const newConfig = JSON.stringify(config);

    const configCopy = () => {
      return JSON.parse(JSON.stringify(config));
    };

    if (currentConfig !== newConfig) {
      this.footerConfigChanged.next(configCopy());
    }
  }

  public submitFooterPosition(position: 'float' | 'fix'): void {
    const currentPos = this.footerPositionChanged.getValue();

    if (currentPos !== position) {
      this.footerPositionChanged.next(position);
    }
  }

  public handleFooterAction(action: FooterAction): void {
    this.footerActionDispatched.next(action);
  }

  public fixCollapsibleSectionDisplay(): void {
    // Fix for footer not updating after collapsible section
    const el = document.querySelector('.footer-content__primary-sections') as HTMLElement;
    el?.classList.remove('footer-content__primary-sections--collapsible-expanded');
  }

  public hideContents(): void {
    const currentConfig = cloneDeep(this.footerConfigChanged.getValue());

    for (let section in currentConfig) {
      const footerSection = currentConfig[section] as FooterSection;
      footerSection.elements = [];
      footerSection.type =
        section === 'right' && this.isManagedFooter ? FooterSectionType.PRIMARY : FooterSectionType.HIDDEN;
      footerSection.isHidden = true;
    }

    this.submitFooterConfig(currentConfig);
  }

  private colorCount = 0;
  public setCollapsibleButtonColor(): void {
    setTimeout(() => {
      const openButton = document.querySelector('.collapsible-section--expanded') as HTMLElement;
      const closedButton = document.querySelector('.collapsible-section--collapsed') as HTMLElement;

      if (openButton) {
        (openButton.children[1] as HTMLButtonElement).style.color = '#ffffff';
        this.colorCount = 0;
      } else if (closedButton) {
        (closedButton.children[1] as HTMLButtonElement).style.color = null;
        this.colorCount = 0;
      }

      if (!openButton && !closedButton && this.colorCount < 10) {
        this.setCollapsibleButtonColor();
        this.colorCount = this.colorCount + 1;
      } else if (this.colorCount >= 10) {
        this.colorCount = 0;
      }
    }, 50);
  }

  public setCollapsibleButtonVisibility(visible: boolean): void {
    setTimeout(() => {
      const openButton = document.querySelector('.collapsible-section--expanded') as HTMLElement;
      const closedButton = document.querySelector('.collapsible-section--collapsed') as HTMLElement;

      if (openButton) {
        (openButton.children[1] as HTMLButtonElement).style.display = visible ? 'block' : 'none';
      }
      if (closedButton) {
        (closedButton.children[1] as HTMLButtonElement).style.display = visible ? 'block' : 'none';
      }
    }, 50);
  }
}

// Not used, but might be useful in the future!
// This QueryService processes a queue of items, one at a time, with a delay between each item.
@Injectable({
  providedIn: 'root',
})
class QueueService<T> {
  private queue = new BehaviorSubject<T[]>([]);
  private isProcessing: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private itemProcessed: Subject<T> = new Subject<T>();

  constructor() {
    this.processQueue();
  }

  public enqueue(item: T): void {
    const currentQueue = this.queue.getValue();
    this.queue.next([...currentQueue, item]);
  }

  public onItemProcessed(): Observable<T> {
    return this.itemProcessed.asObservable();
  }

  private processQueue(): void {
    this.queue
      .pipe(
        concatMap(items => {
          if (items.length > 0 && !this.isProcessing.getValue()) {
            this.isProcessing.next(true);
            return timer(80).pipe(
              take(1),
              concatMap(() => {
                const currentItems = this.queue.getValue();
                const itemToProcess = currentItems.shift(); // Remove the first item from the queue
                this.queue.next(currentItems); // Update the queue without the first item
                this.isProcessing.next(false);
                this.itemProcessed.next(itemToProcess);
                return new Observable(observer => {
                  observer.next(itemToProcess);
                  observer.complete();
                });
              })
            );
          } else {
            return new Observable(observer => observer.complete());
          }
        })
      )
      .subscribe();
  }
}
