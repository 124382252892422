<div class="d-flex flex-wrap justify-content-end align-items-center py-4 mx-4 agenda-navigation-header">
  <div
    *ngIf="
      consultationStatus === consultationStatusEnum.inPreparation ||
      consultationStatus === consultationStatusEnum.preparationSent
    "
    class="flex-grow-1"
  >
    <button
      [attr.data-testcafe]="'agendaNavigation-button-finishPrelim'"
      [disabled]="agendaPanelState === panelStates.closed"
      mat-flat-button
      color="primary"
      (click)="toggleContextMode($event)"
    >
      <span>Vorbereitung beenden</span>
    </button>
  </div>

  <div class="d-flex flex-grow-1" *ngIf="consultationStatus === consultationStatusEnum.mainConsultation">
    <h2 class="mb-0">Beratungsthemen</h2>
    <div class="tag-info tag-neutral mt-1 ml-3">
      <div class="tag-info-text">{{ hasConsultationTopic.length }}<span class="sr-only">Unterthemen</span></div>
    </div>
  </div>

  @if (showEditButton) {
    <button
      class="mr-n1"
      [attr.data-testcafe]="'agendaNavigation-button-edit'"
      mat-icon-button
      (click)="doAction('main', 'edit')"
      kfTooltip="Themenauswahl bearbeiten"
      kfTooltipPosition="below"
      kfTooltipShow="hover_focus"
      aria-label="Themenauswahl bearbeiten"
    >
      <kf-icon name="ic_edit_24"></kf-icon>
    </button>
  }
</div>

<div
  class="menu-question-world-column px-4"
  #agendaNavigationComponent
  id="agendaNavigationComponent"
  (scroll)="onScroll($event)"
>
  <div class="menu-question-world-column__topics" tabindex="-1">
    <button
      [attr.data-testcafe]="'screenConsultation-button-showMore'"
      class="position-absolute up-button"
      mat-icon-button
      [class.show-more-button]="scrollTop"
      [class.hide-more-button]="!scrollTop"
      [disabled]="agendaPanelState === panelStates.closed || !scrollTop"
      (click)="onScrollToTop()"
      [attr.data-html2canvas-ignore]="true"
      [tabindex]="!scrollTop || agendaPanelState === panelStates.closed ? -1 : 0"
      kfTooltip="Inhalt nach oben scrollen"
      kfTooltipPosition="after"
      kfTooltipShow="hover_focus"
      aria-label="Nach oben scrollen"
    >
      <kf-icon name="ic_chevron_links_start_24"></kf-icon>
    </button>

    <button
      [attr.data-testcafe]="'screenConsultation-button-showMore'"
      class="position-absolute down-button"
      mat-icon-button
      [class.show-more-button]="scrollBottom"
      [class.hide-more-button]="!scrollBottom"
      [disabled]="agendaPanelState === panelStates.closed || !scrollBottom"
      (click)="onScrollToBottom()"
      [attr.data-html2canvas-ignore]="true"
      [tabindex]="!scrollBottom || agendaPanelState === panelStates.closed ? -1 : 0"
      kfTooltip="Inhalt nach unten scrollen"
      kfTooltipPosition="after"
      kfTooltipShow="hover_focus"
      aria-label="Nach unten scrollen"
    >
      <kf-icon name="ic_chevron_rechts_ende_24"></kf-icon>
    </button>

    <ng-container
      *ngIf="hasConsultationTopic.length === 0 && hasConsultantTopic.length === 0 && hasCustomerTopic.length === 0"
    >
      <div class="d-flex align-items-center mt-3">
        <kf-icon name="ic_infozeichen_mit_kreis_24" class="mr-2" aria-hidden="true"></kf-icon>
        <span class="align-left">Bitte bearbeiten Sie Ihre Themenauswahl!</span>
      </div>
    </ng-container>

    <ng-container *ngIf="hasConsultationTopic.length !== 0">
      <div class="menu-question-world-column__topic-items">
        <ng-container *ngIf="consultationStatus !== consultationStatusEnum.mainConsultation">
          <div class="d-flex mb-3">
            <div class="d-flex flex-grow-1">
              <h2 class="mb-0">Beratungsthemen</h2>
              <div class="tag-info tag-neutral mt-1 ml-3">
                <div class="tag-info-text">
                  {{ hasConsultationTopic.length }}<span class="sr-only">Unterthemen</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="wrap mb-4 pt-1">
          <ng-template
            *ngTemplateOutlet="
              subtopic;
              context: {
                $implicit: hasConsultationTopic
              }
            "
          ></ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="hasConsultantTopic.length !== 0">
      <div class="menu-question-world-column__topic-items">
        <div class="d-flex mb-3">
          <div class="d-flex flex-grow-1">
            <h2 class="mb-0 mr-2">Vorbereitung Berater</h2>
            <div>
              <div class="tag-info tag-neutral mt-1 ml-3">
                <div class="tag-info-text">{{ hasConsultantTopic.length }}<span class="sr-only">Unterthemen</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrap pt-1">
          <ng-template
            *ngTemplateOutlet="
              subtopic;
              context: {
                $implicit: hasConsultantTopic
              }
            "
          ></ng-template>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="hasCustomerTopic.length !== 0">
      <div class="menu-question-world-column__topic-items">
        <div class="d-flex mb-3">
          <div class="d-flex flex-grow-1">
            <h2 class="mb-0 mr-2">Vorbefragung Kunde</h2>
            <div>
              <div class="tag-info tag-neutral mt-1 ml-3">
                <div class="tag-info-text">{{ hasCustomerTopic.length }}<span class="sr-only">Unterthemen</span></div>
              </div>
            </div>
          </div>

          <button
            [attr.data-testcafe]="'agendaNavigation-button-openMail'"
            class="send-mail-button"
            mat-icon-button
            [disabled]="agendaPanelState === panelStates.closed"
            (click)="doAction('main', 'toggle')"
            [attr.data-html2canvas-ignore]="true"
            [tabindex]="!scrollTop || agendaPanelState === panelStates.closed ? -1 : 0"
            kfTooltip="Mail mit Themen zur Vorbereitung senden"
            kfTooltipPosition="after"
            kfTooltipShow="hover_focus"
            aria-label="Mail senden"
          >
            <kf-icon name="ic_briefumschlag_24"></kf-icon>
          </button>
        </div>
        <div class="wrap pt-1">
          <ng-template
            *ngTemplateOutlet="
              subtopic;
              context: {
                $implicit: hasCustomerTopic
              }
            "
          ></ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #subtopic let-subtopics="$implicit">
  <div *ngFor="let subtopic of subtopics; let j = index; let first = first; let last = last" class="subtopics">
    <div
      class="position-relative subtopic-grid"
      [class.has-connection-top]="!first"
      [class.has-connection-bottom]="!last"
      [class.mb-4]="!last"
      [class.subtopic-grid-no-icon]="!getMediaIcon(subtopic.icon?.id)"
    >
      <div>
        <div class="overflow-hidden"></div>
        <div *ngIf="subtopic.status === topicStatusEnum.done" class="figure-wrapper">
          <div *ngIf="j === 0" class="figure-top"></div>
          <ng-template *ngTemplateOutlet="fullbubble"></ng-template>
          <div *ngIf="subtopics.length && j === subtopics.length - 1" class="figure"></div>
        </div>
        <div *ngIf="subtopic.status === topicStatusEnum.inProgress" class="figure-wrapper parent">
          <div *ngIf="j === 0" class="figure-top"></div>
          <ng-template *ngTemplateOutlet="halfbubble"></ng-template>
          <div *ngIf="subtopics.length && j === subtopics.length - 1" class="figure"></div>
        </div>
        <div *ngIf="subtopic.status === topicStatusEnum.open || subtopic.status === undefined" class="figure-wrapper">
          <div *ngIf="j === 0" class="figure-top"></div>
          <ng-template *ngTemplateOutlet="topicOpen"></ng-template>
          <div *ngIf="subtopics.length && j === subtopics.length - 1" class="figure"></div>
        </div>
      </div>
      <img
        *ngIf="getMediaIcon(subtopic.icon?.id)"
        class="subtopic-icon"
        [src]="getMediaIcon(subtopic.icon?.id)"
        alt=""
      />
      <button
        id="main_{{ subtopic.id }}"
        [attr.data-testcafe]="'agendaNavigation-button-' + getTestcafeId(subtopic.title)"
        [disabled]="agendaPanelState === panelStates.closed"
        class="mr-4 title-button"
        [class.highlight]="subtopic.id === subtopicId"
        (click)="jumpToQuestionGroupSearch(subtopic)"
      >
        {{ subtopic.title }}
      </button>
      <div
        *ngIf="
          subtopic.availableForPreliminary &&
          (consultationStatus === consultationStatusEnum.preparationSent || showPrelimSent)
        "
      >
        <kf-icon
          [name]="subtopic.selectedForPreliminary ? 'ic_haken_24' : 'ic_schliessen_24'"
          [class]="subtopic.selectedForPreliminary ? 'color-success' : 'color-warn'"
        ></kf-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fullbubble>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find(color.name.BrandPrimary)"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #halfbubble>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    transform="rotate(180 0 0)"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.99967" cy="9.99967" r="4.41667" stroke-width="0" [attr.fill]="color.find(color.name.White)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99967 13.4163V6.58301C8.1127 6.58301 6.58301 8.1127 6.58301 9.99967C6.58301 11.8866 8.1127 13.4163 9.99967 13.4163ZM15.4163 9.99967C15.4163 12.9912 12.9912 15.4163 9.99967 15.4163C7.00813 15.4163 4.58301 12.9912 4.58301 9.99967C4.58301 7.00813 7.00813 4.58301 9.99967 4.58301C12.9912 4.58301 15.4163 7.00813 15.4163 9.99967Z"
      [attr.fill]="color.find(color.name.BrandPrimary)"
    />
  </svg>
</ng-template>

<ng-template #topicOpen>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.99967"
      cy="9.99967"
      r="4.41667"
      stroke-width="2"
      [attr.stroke]="color.find(color.name.Neutral500)"
      [attr.fill]="color.find(color.name.White)"
    />
  </svg>
</ng-template>
