import { CdkDropList } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { DialogOtherAnswersComponent } from '@components/dialog-other-answers/dialog-other-answers.component';
import { ChildrenOptions, FooterAction, FooterConfig } from '@de.fiduciagad.kbm/shared-footer-lib';
import { AnswerOptionItem, GivenAnswerItem, OtherAnswers, UpdateAnswerItem } from '@domain/app/answers.domain';
import { MediaItem } from '@domain/app/media.domain';
import { QuestionGroupResponse, QuestionItem, SyncedAnswers } from '@domain/app/question.domain';
import { QuestionGroupRecommendationResponse, RecommendationItem } from '@domain/app/recommendation.domain';
import {
  ConsultationStatusEnum,
  GivenAnswerEnum,
  MediaTypeEnum,
  NoteContextEnum,
  NoteOriginEnum,
  NoteTypeEnum,
  PanelStates,
  QuestionEnum,
  QuestionTemplateEnum,
  RecommendationTypeEnum,
  RoutingPathMain,
  RoutingPathOverlay,
  RoutingPathPrep,
} from '@enums';
import { environment } from '@environment/environment';
import { Action, ActionService } from '@services/action-service/action.service';
import { ClientService } from '@services/client-service/client.service';
import { ConfigService } from '@services/config-service/config.service';
import { ContextService } from '@services/context-service/context.service';
import { DialogService } from '@services/dialog-service/dialog.service';
import { DocumentService } from '@services/document-service/document.service';
import { FormValidationService } from '@services/form-validation-service/form-validation.service';
import { LoadingService } from '@services/loading-service/loading.service';
import { MediaService } from '@services/media-service/media.service';
import { NoteService } from '@services/note-service/note.service';
import { QueryService } from '@services/query-service/query.service';
import { SharedFooterService } from '@services/shared-footer/shared-footer.service';
import { TopicService } from '@services/topic-service/topic-service';
import {
  baseConfig,
  buttonDocumentsActive,
  buttonDrawing,
  buttonDrawingActive,
  buttonNext,
  buttonNotes,
  buttonNotesActive,
  buttonPrev,
} from '@utils/footer-config';
import { FormType, color, libIcons } from 'bgzv-frontend-library';
import cloneDeep from 'lodash-es/cloneDeep';
import uniqBy from 'lodash-es/uniqBy';
import { Subject, lastValueFrom } from 'rxjs';
import { finalize, takeUntil, throttleTime } from 'rxjs/operators';
import {
  ImageAnswerData,
  NumberMinMaxData,
  SegmentedLabelData,
  TriggerOverride,
  ValueObject,
  VrImageData,
  VrImageSelectOutput,
} from './screen-consultation.interfaces';
import {
  answerNullObject,
  answerObjectFactory,
  handleOtherAnswers,
  syncedAnswerTextFactory,
} from './screen-consultation.utils';

interface TextAnswerData {
  questionId: string;
  value: boolean | any;
  type: 'text' | 'text-input' | 'single' | 'multi';
  index?: number;
  answerObj?: AnswerOptionItem[];
}

interface PreviousAnswerRequest {
  consultationId: string;
  questionId: string;
  sendObj: any;
}

@Component({
  selector: 'screen-consultation',
  templateUrl: './screen-consultation.component.html',
  styleUrls: ['./screen-consultation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScreenConsultationComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('scrollable') scrollable: ElementRef;
  @ViewChild('otherPanel') expansionPanel: MatExpansionPanel;

  @ViewChild('other') recommendationOther: CdkDropList;
  @ViewChild('recommended') recommendationRecommended: CdkDropList;
  @ViewChild('selected') recommendationSelected: CdkDropList;

  private destroySubs = new Subject<void>();
  private debounceSelectImage = new Subject<{ questionId: string; value: VrImageSelectOutput[] }>();
  private tempTextAnswer: TextAnswerData | null = null;
  private previousAnswerRequest: PreviousAnswerRequest | null = null;
  private focusElement: any;
  private focusParent: string;

  private prevAnswerObj: UpdateAnswerItem[];
  private questionGroupId = '-1';
  private openDrawOnEntry = false;
  private totalRecommendationData: RecommendationItem[];
  private dialogRef: MatDialogRef<DialogOtherAnswersComponent, any> = null;

  public requestIsPending = false;
  public isLoading = false;

  private scrollPos = 0;
  private restoreScrollPos = false;

  public questionCollectionError = false;

  public questionGroupData: QuestionGroupResponse;
  public questionData: QuestionItem[];
  public recommendationGroupData: QuestionGroupRecommendationResponse;
  public imageData: VrImageData[];
  public questionImageData: VrImageData[];
  public icon: MediaItem;
  public documentData: MediaItem[] = [];

  public other: CdkDropList<RecommendationItem[]>;
  public selected: CdkDropList<RecommendationItem[]>;
  public recommended: CdkDropList<RecommendationItem[]>;

  public questionEnum = QuestionEnum;
  public questionTemplateEnum = QuestionTemplateEnum;
  public recommendationTypeEnum = RecommendationTypeEnum;
  public consultationStatusEnum = ConsultationStatusEnum;
  public mediaTypeEnum = MediaTypeEnum;
  public panelStates = PanelStates;

  public helperArray = Array;

  // Answer Maps
  public otherAnswersMap: Map<string, OtherAnswers> = new Map<string, OtherAnswers>();
  public singleAnswerData: Map<string, string> = new Map<string, string>();
  public numericalAnswerData: Map<string, ValueObject> = new Map<string, ValueObject>();
  public numericalAnswerMinMax: Map<string, NumberMinMaxData> = new Map<string, NumberMinMaxData>();
  public segmentedAnswerData: Map<string, SegmentedLabelData[]> = new Map<string, SegmentedLabelData[]>();
  public imageAnswerData: Map<string, ImageAnswerData[]> = new Map<string, ImageAnswerData[]>();
  public showTextMap: Map<string, AnswerOptionItem[]> = new Map<string, AnswerOptionItem[]>();
  public showSyncQuestionSet: Set<string> = new Set<string>();
  public consultationDrawId = '-1';

  public showTextField = false;
  public showMoreButton = false;

  public isInPrep = false;
  public showPrepDivider: 'consultant' | 'customer' | 'none' = 'none';

  public questionsScrolled = false;
  public recommendationsAvailable = false;

  public footerConfig: FooterConfig = baseConfig;

  readonly color = color;
  readonly buttonIcon = libIcons;
  readonly formType = FormType;
  readonly UUIDNull = '00000000-0000-0000-0000-000000000000';

  constructor(
    private router: Router,
    private chg: ChangeDetectorRef,
    private actionService: ActionService,
    private queryService: QueryService,
    private topicService: TopicService,
    private dialogService: DialogService,
    private clientService: ClientService,
    private contextService: ContextService,
    private mediaService: MediaService,
    private noteService: NoteService,
    private loadingService: LoadingService,
    private footerService: SharedFooterService,
    private documentService: DocumentService,
    private formBuilder: FormBuilder,
    private readonly configService: ConfigService,
    private readonly formValidationService: FormValidationService
  ) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.questionGroupId = '-1';
      this.openDrawOnEntry = false;
      for (const key in this.router.getCurrentNavigation().extras.state) {
        if (key === 'questionGroupId') {
          this.questionGroupId = this.router.getCurrentNavigation().extras.state[key];
        }
        if (key === 'openDrawOverlay') {
          this.openDrawOnEntry = this.router.getCurrentNavigation().extras.state[key];
        }
      }
    }
  }

  ngOnInit(): void {
    this.footerLeftButtonsFactory();

    this.contextService.agendaPanelState =
      (localStorage.getItem('agendaPanelState') as PanelStates) || PanelStates.closed;

    this.debounceSelectImage.pipe(throttleTime(300)).subscribe(x => {
      this.handleSelectImage(x.questionId, x.value);
    });

    this.actionService.action.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      if (action && action.target === 'consultation') {
        this.recommendationsAvailable = false;
        if (action.action === 'jump') {
          this.initJumpQuestionGroup(action);
        }
        if (action.action === 'reload-recommendation') {
          if (this.questionGroupData.template === this.questionTemplateEnum.products) {
            this.doAction('side-recommendation', 'get-recommendation');
          }
        }
        if (action.action === 'reload-prelim-topics') {
          this.requestPrelimTopics(this.clientService.consultationId, this.questionGroupId);
        }
        if (action.action === 'refresh-question-group') {
          this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupId, false);
        }
        if (action.action === 'recommendations-available') {
          this.recommendationsAvailable = true;
        }
        if (action.action === 'recommendations-unavailable') {
          this.recommendationsAvailable = false;
        }
      }
    });
    this.questionCollectionError = false;

    if (this.questionGroupId != '-1') {
      this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupId);
    } else if (this.questionGroupId == '-1') {
      this.queryService.getTopicDataByConsultationId(this.clientService.consultationId).subscribe(data => {
        this.topicService.selectedTopicsData = data.selected;

        if (data.selected.length > 0) {
          this.questionGroupId = this.topicService.firstQuestionGroupId;
          this.queryQuestionCollectionData(this.clientService.consultationId, this.topicService.firstQuestionGroupId);
        } else {
          this.questionCollectionError = true;
        }
      });
    }

    this.contextService.lastQuestionGroupId.pipe(takeUntil(this.destroySubs)).subscribe();
    this.contextService.context.pipe(takeUntil(this.destroySubs)).subscribe(context => {
      if (!!context && this.contextService.overlayWasClosed) {
        this.footerRightButtonsFactory();
        this.footerLeftButtonsFactory();
      }
    });

    this.loadingService.isLoading.pipe(takeUntil(this.destroySubs)).subscribe(loading => {
      this.isLoading = loading;
      this.chg.detectChanges();
      this.footerRightButtonsFactory();
    });

    this.footerService.footerActionDispatched.pipe(takeUntil(this.destroySubs)).subscribe(action => {
      this.onFooterAction(action);
    });

    this.footerService.footerConfigChanged.pipe(takeUntil(this.destroySubs)).subscribe(config => {
      this.footerConfig = config;
    });

    this.noteService.currentNotes.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      this.footerLeftButtonsFactory();
    });

    this.noteService.noteUpdated.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      this.footerLeftButtonsFactory();
    });

    this.noteService.noteDeleted.pipe(takeUntil(this.destroySubs)).subscribe(notesData => {
      this.noteService.getAllNotes();
      this.noteService.presetNoteType = null;
      this.footerLeftButtonsFactory();
    });
  }

  ngOnDestroy(): void {
    this.destroySubs.next();
    this.destroySubs.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.contextService.recPanelState = (localStorage.getItem('recPanelState') as PanelStates) || PanelStates.open;
    if (this.recPanelState === 'closed') {
      document.querySelector('#rightContent').classList.add('right-content-closed');
      document.querySelector('#rightContentTrigger').classList.add('right-content-trigger-closed');
    }
  }

  /// ------------------------------------------------ ///
  /// --------------- FOOTER HANDLING ---------------- ///
  /// ------------------------------------------------ ///

  public async onFooterAction(event: FooterAction): Promise<void> {
    if (event.owner === 'bgzv') {
      if (this.isLoading) {
        return;
      }

      // right-hand proceed buttons
      if (event.id === 'consultation-next-group') {
        this.initNextQuestionGroup();
      } else if (event.id === 'consultation-next-topic') {
        await lastValueFrom(this.queryService.putSubtopicComplete(this.clientService.consultationId, this.subtopicId));
        this.initFinishSubtopic();
        this.doAction('agenda', 'refresh');
      } else if (event.id === 'consultation-finish-prep') {
        await lastValueFrom(this.queryService.putSubtopicComplete(this.clientService.consultationId, this.subtopicId));
        this.initFinishPerparationMode();
      } else if (event.id === 'consultation-finish-all') {
        await lastValueFrom(this.queryService.putSubtopicComplete(this.clientService.consultationId, this.subtopicId));
        this.initFinishConsultation();
      }

      //right-hand back buttons
      else if (event.id === 'consultation-prev-group') {
        this.initPrevQuestionGroup();
      }

      // left-hand notes and documents buttons
      else if (event.id === 'start-drawing') {
        this.noteService.presetNoteType = NoteTypeEnum.drawing;
        this.doAction('overlay-main', RoutingPathOverlay.Draw);
      } else if (event.id === 'start-notes') {
        this.noteService.presetNoteType = NoteTypeEnum.text;
        this.doAction('overlay-main', RoutingPathOverlay.Draw);
      } else if (event.id.startsWith('open-document')) {
        const documentId = event.id.split('###')[1];
        this.documentService.showDocument(this.documentData.find(x => x.id === documentId));
      }
    }
  }

  private footerRightButtonsFactory(): void {
    if (this.contextService.currentOverlayContext !== null) {
      return;
    }

    if (
      !!this.footerConfig?.drawing ||
      this.footerConfig?.right.isHidden ||
      (!!this.footerConfig?.leftCollapsable && !this.footerConfig?.leftCollapsable.isHidden)
    ) {
      this.footerConfig = baseConfig;
      this.footerService.fixCollapsibleSectionDisplay();
    }

    const currentConfig = cloneDeep(this.footerConfig);
    const currentNext = cloneDeep(buttonNext);
    const currentPrev = cloneDeep(buttonPrev);
    const skipSummary = this.configService.skipSubtopicSummary;

    // proceed button configuration and logic
    if (this.isLastQuestionInSubtopic && !this.isLastQuestionTotal) {
      currentNext.inputs.action = 'consultation-next-topic';
      if (this.contextService.currentMode === 'prep') {
        const lq = this.isLastConsultantQuestionGroup;
        currentNext.inputs.config.label = lq ? 'Vorbefragung beenden' : 'Nächstes Thema';
      } else if (this.contextService.currentMode === 'main') {
        currentNext.inputs.config.label = skipSummary ? 'Nächstes Thema' : 'Thema abschließen';
      }
    } else if (this.isLastQuestionTotal) {
      if (this.contextService.currentMode === 'prep') {
        currentNext.inputs.action = 'consultation-finish-prep';
        currentNext.inputs.config.label = 'Themenauswahl beenden';
      } else if (this.contextService.currentMode === 'main') {
        currentNext.inputs.action = 'consultation-finish-all';
        currentNext.inputs.config.label = skipSummary ? 'Bestellprozess starten' : 'Thema abschließen';
      }
    } else {
      currentNext.inputs.action = 'consultation-next-group';
      currentNext.inputs.config.label = 'Weiter';
    }

    currentNext.inputs.config.disabled = this.isLoading;
    currentPrev.inputs.config.disabled = this.isLoading;

    currentConfig.right.elements = this.isFirstQuestionInSubtopic ? [currentNext] : [currentPrev, currentNext];

    this.footerService.submitFooterConfig(currentConfig);
  }

  private footerLeftButtonsFactory(): void {
    if (this.contextService.currentOverlayContext !== null) {
      return;
    }

    const currentNotes = this.noteService.currentNotes.getValue();
    const currentConfig = cloneDeep(this.footerConfig);
    let currentDrawButton = buttonDrawing;
    let currentNoteButton = buttonNotes;
    // default configuration
    currentConfig.left.elements = [currentDrawButton, currentNoteButton];

    // get relevant notes / drawings for this questiongroup
    const filteredNotes =
      currentNotes?.notesByTopic
        .flatMap(x => x.notes)
        .filter(x => {
          const originType =
            this.currentContextMode === 'main' ? NoteOriginEnum.consultation : NoteOriginEnum.preparation;
          return x.questionGroupId === this.questionGroupId && x.noteOrigin === originType;
        }) || [];

    // add indicator if there are notes / drawings for this questiongroup
    filteredNotes?.forEach(note => {
      if (note.noteType === NoteTypeEnum.drawing) {
        currentDrawButton = buttonDrawingActive;
      } else if (note.noteType === NoteTypeEnum.text) {
        currentNoteButton = buttonNotesActive;
      }
    });

    currentConfig.left.elements = [currentDrawButton, currentNoteButton];
    currentConfig.left.isHidden = false;

    // handle documents icon and menu
    if (!!this.documentData && this.documentData.length > 0) {
      buttonDocumentsActive.inputs.config.badgeLabel = this.documentData.length.toString();
      buttonDocumentsActive.inputs.children = [];

      for (const document of this.documentData) {
        const childItem: ChildrenOptions = {
          action: `open-document###${document.id}`,
          config: {
            label: `${document.name} (${document.type})`,
          },
        };
        buttonDocumentsActive.inputs.children.push(childItem);
      }

      currentConfig.left.elements.push(buttonDocumentsActive);
    }

    this.footerService.submitFooterConfig(currentConfig);
  }

  @HostListener('scroll', ['$event'])
  public onScroll(event) {
    // VSS-5165 - not great, not terrible
    if (!this.restoreScrollPos) {
      this.scrollPos = event.target.scrollTop;
    } else if (this.restoreScrollPos && event.target.scrollTop === 0) {
      event.target.scrollTop = this.scrollPos;
    }

    let pos = this.scrollPos + event.target.offsetHeight;
    const max = event.target.scrollHeight;

    this.restoreScrollPos = false;

    if (pos === max - 1) {
      return;
    }

    if (!this.questionsScrolled) {
      this.questionsScrolled = this.scrollPos > 46;
    } else if (this.questionsScrolled && this.scrollPos < 10) {
      this.questionsScrolled = false;
    }

    // terrible hack
    if (pos >= max - 1) {
      this.scrollPos -= 1;
      event.target.scrollTop -= 1;
      pos -= 1;
    }

    if (pos >= max - 15 && this.showMoreButton) {
      this.showMoreButton = false;
    } else if (pos < max - 15 && !this.showMoreButton) {
      this.showMoreButton = true;
      document.querySelector('#showMoreButton')?.classList.add('pulse-more-button');
    }

    if (this.scrollPos === 0) {
      this.showMoreButton = false;
      document.querySelector('#showMoreButton')?.classList.remove('pulse-more-button');
    }
  }

  get subtopicId() {
    return this.questionGroupData?.subtopicId || '0';
  }
  get questionCollectionId() {
    return this.questionGroupData.collectionId || '0';
  }
  get isFirstQuestionInSubtopic() {
    return this.questionGroupData?.currentCollection === 0 || false;
  }
  get isLastQuestionInSubtopic() {
    return this.questionGroupData?.lastCollectionSubtopic || false;
  }
  get isLastQuestionTotal() {
    return this.questionGroupData?.lastCollectionTotal && this.questionGroupData?.lastCollectionSubtopic;
  }
  get totalQuestionCollectionsInSubtopic() {
    return this.questionGroupData?.totalCollections || 0;
  }
  get isLastConsultantQuestionGroup() {
    return this.questionGroupData?.lastPreparationQuestionGroup || false;
  }

  get addToPrepDisabledState(): boolean {
    return (
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationSent ||
      this.clientService.consultationStatus === ConsultationStatusEnum.preparationDone ||
      this.questionGroupData?.availableForPreliminary === false
    );
  }

  get currentContextMode(): 'main' | 'prep' {
    return this.contextService.currentMode;
  }

  get answerType(): GivenAnswerEnum {
    if (environment.consultationType === 'prelim') {
      return GivenAnswerEnum.customer;
    } else {
      return this.contextService.currentMode === 'main' ? GivenAnswerEnum.consultation : GivenAnswerEnum.consultant;
    }
  }

  public getIcon(subtopicId: string): boolean {
    this.icon = this.topicService.selectedTopicDataBySubtopicId(subtopicId)?.icon;
    return !!this.icon;
  }

  // field error handling
  public getFieldErrorMessage(field: FormControl, name: string) {
    return this.formValidationService.getFieldErrorMessage(field, name);
  }

  //build a FormGroup dynamically
  public buildForm(questionGroup): { [index: string]: FormGroup } {
    const composition: { [index: string]: FormGroup } = {};
    this.questionGroupData.questions.forEach(question => {
      composition[question.id] = this.formBuilder.group(this.formGroupConfig(question));
    });
    return composition;
  }

  public formGroupConfig(questionData: QuestionItem): { [index: string]: [] } {
    const group = {};
    questionData.answers?.forEach(answer => {
      group[answer.id] = [answer.givenAnswers.find(x => x.answeredBy === 'CONSULTANT')?.value];
    });
    return group;
  }

  public getOtherAnswerLabel(data: OtherAnswers): number {
    let returnValue = 0;

    if (data) {
      if (data.CUSTOMER.length > 0) {
        returnValue += 1;
      }
      if (this.contextService.currentMode === 'main' && data.CONSULTANT.length > 0) {
        returnValue += 1;
      }
      if (data.HISTORY.length > 0) {
        returnValue += 1;
      }
    }

    return returnValue;
  }

  public requestPrelimTopics(consultationId, questionGroupId) {
    this.queryService.getQuestionCollectionById(consultationId, questionGroupId).subscribe(data => {
      this.isInPrep = data.selectedForPreliminary && data.availableForPreliminary;
    });
  }

  public onOpenOtherAnswerDialog(ev: Event, data: OtherAnswers, item: QuestionItem): void {
    if (!this.dialogRef) {
      this.dialogRef = this.dialogService.openDialogOtherAnswers(
        ev.target,
        data,
        item,
        this.contextService.currentMode
      );
      this.dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroySubs))
        .subscribe(result => {
          if (result?.answers) {
            const mapped = result.answers.map(({ label, ...rest }) => {
              rest.answeredBy = this.answerType;
              return rest;
            });
            this.handleSendAnswer(this.clientService.consultationId, result.question.id, mapped, true);
          }

          this.dialogRef = null;
        });
    }
  }

  public onOpenQuestionDataFields(item: QuestionItem): void {
    this.doAction('main', 'profile', { isQuestionDataField: item.connectedToDataFieldGroups, itemId: item.id });
  }

  public onCloseOtherAnswerDialog(ev: Event): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public onClickOtherAnswerDialog(ev: Event, data: OtherAnswers, question: QuestionItem): void {
    this.sendTempTextRequest();

    if (this.dialogRef) {
      this.onCloseOtherAnswerDialog(ev);
    } else {
      this.onOpenOtherAnswerDialog(ev, data, question);
    }
  }

  public onAgendaPanelTrigger(override: TriggerOverride = 'toggle', event: KeyboardEvent = null) {
    if (event && event?.key !== ' ') {
      return;
    }

    const agendaContainer = document.querySelector('#leftContent')?.classList;
    const agendaTrigger = document.querySelector('#leftContentTrigger')?.classList;

    if (override === 'toggle') {
      this.contextService.agendaPanelState =
        this.agendaPanelState === PanelStates.open ? PanelStates.closed : PanelStates.open;
      this.contextService.agendaPanelManual = true;
    } else if (override === 'open' && !this.contextService.agendaPanelManual) {
      this.contextService.agendaPanelState = PanelStates.open;
    } else if (override === 'closed' && !this.contextService.agendaPanelManual) {
      this.contextService.agendaPanelState = PanelStates.closed;
    }

    if (this.agendaPanelState === 'open') {
      agendaContainer?.remove('left-content-closed');
      agendaTrigger?.remove('left-content-trigger-closed');
    } else if (this.agendaPanelState === 'closed') {
      agendaContainer?.add('left-content-closed');
      agendaTrigger?.add('left-content-trigger-closed');
    }

    localStorage.setItem('agendaPanelState', this.agendaPanelState);
  }

  public onRecPanelTrigger(override: TriggerOverride = 'toggle', event: KeyboardEvent = null) {
    if (event && event?.key !== ' ') {
      return;
    }

    const recContainer = document.querySelector('#rightContent')?.classList;
    const recTrigger = document.querySelector('#rightContentTrigger')?.classList;

    if (override === 'toggle') {
      this.contextService.recPanelState =
        this.recPanelState === PanelStates.open ? PanelStates.closed : PanelStates.open;
      this.contextService.recPanelManual = true;
    } else if (override === 'open' && !this.contextService.recPanelManual) {
      this.contextService.recPanelState = PanelStates.open;
    } else if (override === 'closed' && !this.contextService.recPanelManual) {
      this.contextService.recPanelState = PanelStates.closed;
    }

    if (this.recPanelState === PanelStates.open) {
      recContainer?.remove('right-content-closed');
      recTrigger?.remove('right-content-trigger-closed');
    } else if (this.recPanelState === 'closed') {
      recContainer?.add('right-content-closed');
      recTrigger?.add('right-content-trigger-closed');
    }

    localStorage.setItem('recPanelState', this.recPanelState);
  }

  public onScrollToBottom(): void {
    const elm = this.scrollable?.nativeElement;
    const pos = elm.scrollTop + elm.offsetHeight;
    const max = elm.scrollHeight;

    if (pos < max) {
      elm.scroll({ top: max, behavior: 'smooth' });
    }

    document.querySelector('#showMoreButton')?.classList.remove('pulse-more-button');
    this.showMoreButton = false;
  }

  get agendaPanelState(): PanelStates {
    return this.contextService.agendaPanelState;
  }

  get recPanelState(): PanelStates {
    return this.contextService.recPanelState;
  }

  public openRecommendations(): void {
    this.doAction('main', 'recommendations');
  }

  // --------------------------------------------- //
  // ----------- SEND ANSWER FUNCTIONS ----------- //
  // --------------------------------------------- //

  public answerSelectMulti(answerObj: AnswerOptionItem[], questionId, index: number, value: boolean | any) {
    const selected = typeof value === 'boolean' ? value : value.selected;
    const notice = typeof value === 'boolean' ? null : value.note;

    let sendObj = [] as UpdateAnswerItem[];

    const givenAnswerObj = {} as GivenAnswerItem;
    givenAnswerObj.answeredBy = this.answerType;
    givenAnswerObj.note = notice || null;

    // Check if already answered in consultation / by consultant
    const givenAnswersIndex = answerObj[index].givenAnswers.findIndex(x => x.answeredBy === this.answerType);

    // Add CONSULTATION to givenAnswers Array
    if (selected && givenAnswersIndex === -1) {
      answerObj[index].givenAnswers.push(givenAnswerObj);
    } else if (selected && givenAnswersIndex >= 0) {
      answerObj[index].givenAnswers[givenAnswersIndex] = givenAnswerObj;
    } else if (!selected) {
      answerObj[index].givenAnswers.splice(givenAnswersIndex, 1);
    }

    // Get all answers that contain CONSULTATION
    const rawObj = answerObj.filter(x => x.givenAnswers.flatMap(y => y.answeredBy).includes(this.answerType));
    // Create Object to send to the Backend, only with CONSULTATION answers
    sendObj = answerObjectFactory(rawObj, this.answerType);

    // make sure multi answers have no value sent to the backend
    sendObj.forEach(x => (x.value = null));

    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  public answerSelectSingle(
    answerObj: AnswerOptionItem[],
    questionId: string,
    index: number,
    value: boolean | any,
    skipMapping: boolean = false
  ) {
    const notice = typeof value === 'boolean' ? null : value.note;

    let sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      answer.givenAnswers = answer.givenAnswers.filter(x => x.answeredBy !== this.answerType);
    }
    if (value !== '') {
      answerObj[index].givenAnswers.push({ answeredBy: this.answerType, note: notice });
    } else {
      answerObj[index].givenAnswers.push({ answeredBy: GivenAnswerEnum.none, note: notice });
    }

    sendObj = answerObjectFactory(answerObj, this.answerType);

    if (sendObj.length > 0) {
      !skipMapping && this.singleAnswerData.set(questionId, sendObj[0].id);
      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    }
  }

  public answerSelectSegment(answerObj: AnswerOptionItem[], questionId, value: string) {
    const index = answerObj.findIndex(x => String(x.id) === String(value));

    this.answerSelectSingle(answerObj, questionId, index, String(value));
  }

  public answerSelectImage(questionId: string, isMultiImage: boolean, value: VrImageSelectOutput[]) {
    if (this.loadingService.isLoading.getValue()) {
      this.chg.detectChanges();
      return;
    }

    let sendObj = [] as UpdateAnswerItem[];

    if (!isMultiImage) {
      // this will eventually call handleSelectImage
      // debounceSelectImage is for using only the first value then ignore everything for 300ms
      this.debounceSelectImage.next({ questionId, value });
      return null;
    } else if (isMultiImage) {
      if (value?.some(selectedAnswer => selectedAnswer.selected)) {
        value.forEach(selectedAnswer => {
          if (selectedAnswer.selected) {
            sendObj.push(
              ...answerObjectFactory([
                {
                  id: selectedAnswer.id,
                  givenAnswers: [{ answeredBy: this.answerType }],
                },
              ])
            );
          }
        });
      } else {
        sendObj = answerNullObject(this.answerType);
      }

      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    }
  }

  public answerSelectNumeric(answerObj: AnswerOptionItem[], questionId, value) {
    const sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      if (answer.minValue <= value && answer.maxValue >= value) {
        let noteValue = '';
        if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
          if (this.prevAnswerObj[0].id === answer.id) {
            noteValue = this.prevAnswerObj[0].note;
          }
        }
        sendObj.push({
          id: answer.id,
          value: value,
          note: noteValue,
          answeredBy: GivenAnswerEnum.none,
        });
        break;
      }
    }

    if (sendObj.length > 0) {
      sendObj[0].answeredBy = this.answerType;
      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    } else {
      console.error('[bgzv-frontend-main] No answer defined. Please check the content configuration.');
    }
  }

  public answerSelectSlider(question: QuestionItem, event) {
    const answerObj = question.answers;
    const questionId = question.id;

    const sendObj = [] as UpdateAnswerItem[];

    for (const answer of answerObj) {
      if (answer.minValue <= event.value && answer.maxValue >= event.value) {
        let noteValue = '';
        if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
          if (this.prevAnswerObj[0].id === answer.id) {
            noteValue = this.prevAnswerObj[0].note;
          }
        }
        sendObj.push({
          id: answer.id,
          value: event.value,
          note: event.noteInputValue || '',
          answeredBy: GivenAnswerEnum.none,
        });
        break;
      }
    }

    if (sendObj.length > 0) {
      const resetNote = this.updateSliderValues(question, event);
      if (resetNote) {
        sendObj[0].note = '';
      }

      sendObj[0].answeredBy = this.answerType;
      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    } else {
      console.error('[bgzv-frontend-main] No answer defined. Please check the content configuration.');
    }
  }

  public answerSelectText(answerObj: AnswerOptionItem[], questionId, value) {
    const sendObj = [] as UpdateAnswerItem[];
    for (const answer of answerObj) {
      let noteValue = '';
      if (this.prevAnswerObj && this.prevAnswerObj.length > 0) {
        if (this.prevAnswerObj[0].id === answer.id) {
          noteValue = this.prevAnswerObj[0].note;
        }
      }
      sendObj.push({
        id: answer.id,
        value: value,
        note: noteValue,
        answeredBy: GivenAnswerEnum.none,
      });
      break;
    }

    if (sendObj.length > 0) {
      sendObj[0].answeredBy = this.answerType;
      this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
    } else {
      console.error('[bgzv-frontend-main] No answer defined. Please check the content configuration.');
    }
  }

  // --------------------------------------------- //
  // -------- TEXT FIELD HELPER FUNCTIONS -------- //
  // --------------------------------------------- //

  public showTextFieldData(question: QuestionItem, answerId: string = this.UUIDNull): boolean {
    if (answerId == this.UUIDNull) {
      return this.showTextMap.has(question.id);
    } else {
      if (this.showTextMap.has(question.id)) {
        return this.showTextMap.get(question.id).findIndex(x => x.id === answerId) >= 0;
      }
      return false;
    }
  }

  public showSliderTextFieldData(question: QuestionItem): string {
    if (!this.showTextMap.has(question.id)) {
      return this.UUIDNull;
    } else if (
      this.showTextMap.has(question.id) &&
      this.showTextMap.get(question.id).find(x => x.givenAnswers.length > 0)
    ) {
      return this.showTextMap.get(question.id).find(x => x.givenAnswers.length > 0).id;
    }

    return this.UUIDNull;
  }

  public getTextFieldData(question: QuestionItem, answerId: string = this.UUIDNull, property: string = 'id') {
    if (!this.showTextMap.has(question.id)) {
      return '';
    }
    let returnData = {} as AnswerOptionItem;

    if (answerId == this.UUIDNull) {
      returnData = this.showTextMap.get(question.id)[0];
    } else {
      const index = this.showTextMap.get(question.id).findIndex(x => x.id === answerId);
      if (index === -1) {
        return '';
      }
      returnData = this.showTextMap.get(question.id)[index];
    }

    if (property !== 'givenAnswers') {
      return returnData[property] || '';
    } else {
      return returnData.givenAnswers?.find(givenAnswer => givenAnswer.answeredBy === this.answerType)?.note || '';
    }
  }

  public handleTextFieldInput(value: string, questionId: string) {
    this.prevAnswerObj[0].note = value;
    this.sendAnswer(this.clientService.consultationId, questionId, this.prevAnswerObj);
  }

  public showPreselectedState(givenAnswersObj: GivenAnswerItem[]): boolean {
    if (this.contextService.currentMode === 'main') {
      return givenAnswersObj.findIndex(x => x.answeredBy === GivenAnswerEnum.consultation) >= 0;
    } else if (this.contextService.currentMode === 'prep') {
      return givenAnswersObj.findIndex(x => x.answeredBy === GivenAnswerEnum.consultant) >= 0;
    }

    return false;
  }

  public onAddToPrepClicked(ev: Event) {
    this.requestIsPending = true;
    if (this.isInPrep) {
      this.queryService
        .removeQuestionGroupFromPreliminary(this.clientService.consultationId, this.questionGroupData.collectionId)
        .pipe(
          finalize(() => {
            this.requestIsPending = false;
            this.chg.detectChanges();
          })
        )
        .subscribe(x => {
          this.isInPrep = false;
        });
    } else {
      this.queryService
        .addQuestionGroupToPreliminary(this.clientService.consultationId, this.questionGroupData.collectionId)
        .pipe(
          finalize(() => {
            this.requestIsPending = false;
            this.chg.detectChanges();
          })
        )
        .subscribe(x => {
          this.isInPrep = true;
        });
    }
  }

  public resetFocusElement() {
    this.focusElement = null;
  }

  public setTempTextAnswer(
    questionId: string,
    value: boolean | any,
    type: 'text' | 'text-input' | 'multi' | 'single',
    answerObj?: AnswerOptionItem[],
    index: number = -1
  ): void {
    this.tempTextAnswer = { answerObj, questionId, index, value, type };
  }

  public sendTempTextRequest(): void {
    if (!this.tempTextAnswer || this.tempTextAnswer?.value === null) {
      return;
    }

    const tempAnswer = { ...this.tempTextAnswer };
    this.tempTextAnswer = null;

    if (tempAnswer.type === 'text') {
      this.answerSelectText(tempAnswer.answerObj, tempAnswer.questionId, tempAnswer.value);
    } else if (tempAnswer.type === 'multi') {
      this.answerSelectMulti(tempAnswer.answerObj, tempAnswer.questionId, tempAnswer.index, tempAnswer.value);
    } else if (tempAnswer.type === 'single') {
      this.answerSelectSingle(
        tempAnswer.answerObj,
        tempAnswer.questionId,
        tempAnswer.index,
        tempAnswer.value,
        this.previousAnswerRequest?.questionId === tempAnswer.questionId
      );
    } else if (tempAnswer.type === 'text-input') {
      this.handleTextFieldInput(tempAnswer.value, tempAnswer.questionId);
    }
  }

  public setFocusElement(event: any, parent: string = null) {
    this.focusParent = parent;
    this.focusElement = event;
  }

  public showsNotesInput(question: QuestionItem): boolean {
    return this.getCurrentNumericalAnswerOption(question)?.hasTextField;
  }

  // --------------------------------------------- //
  // -- IMAGE & DOCUMENTS AT QUESTION FUNCTIONS -- //
  // --------------------------------------------- //

  public async showDocument(document: MediaItem, event: Event): Promise<void> {
    event.preventDefault();
    this.documentService.showDocument(document);
  }

  public hasQuestionImages(question: QuestionItem): number {
    return question.medias.filter(x => x.type === MediaTypeEnum.image).length || 0;
  }

  public hasQuestionDocuments(question: QuestionItem): number {
    return question.medias.filter(x => x.type !== MediaTypeEnum.image).length || 0;
  }

  public getQuestionImages(question: QuestionItem): VrImageData[] {
    const images = question.medias.filter(x => x.type === MediaTypeEnum.image);
    return this.questionImageData.filter(x => images.findIndex(y => y.id === x.id) >= 0);
  }

  // --------------------------------------------- //
  // --------- PRIVATE HELPER FUNCTIONS ---------- //
  // --------------------------------------------- //

  private doAction(target: string = '', action: string = '', options?: any): void {
    const data = { target: target, action: action } as Action;
    if (options) {
      data.options = options;
    }
    this.actionService.setAction(data);
  }

  private updateSliderValues(question: QuestionItem, event): boolean {
    let resetNoteInput = this.shouldReset(question, event);

    this.numericalAnswerData.set(question.id, {
      defaultSliderValue: false,
      value: event.value,
      note: resetNoteInput ? '' : event.noteInputValue,
      answerId: this.getCurrentNumericalAnswerId(question, event.value),
    });

    return resetNoteInput;
  }

  private shouldReset(question, event): boolean {
    const prevAnswer = this.numericalAnswerData.get(question.id);

    if (!prevAnswer.answerId) {
      return false;
    }

    return prevAnswer.answerId !== this.getCurrentNumericalAnswerId(question, event.value);
  }

  private navigateToTopicSummary(): void {
    const route =
      this.clientService.consultationStatus !== ConsultationStatusEnum.mainConsultation
        ? RoutingPathPrep
        : RoutingPathMain;
    this.router.navigate([route.TopicSummary], {
      state: {
        subtopicId: this.subtopicId,
        nextQuestionGroupId: this.questionGroupData.nextCollectionId,
        prevQuestionGroupId: this.questionGroupData.collectionId,
        allDone: this.questionGroupData.lastCollectionTotal,
        isLastConsultantQuestionGroup: this.isLastConsultantQuestionGroup,
        prepDividerType: this.showPrepDivider,
      },
    });

    this.showPrepDivider = 'none';
  }

  private queryQuestionCollectionData(consultationId: string, qcId: string, scrollToTop = true): void {
    this.queryService.getQuestionCollectionById(consultationId, qcId).subscribe(async data => {
      await this.handleImageData(data.medias.filter(x => x.type === MediaTypeEnum.image));
      await this.handleQuestionImageData(data.questions);

      this.questionGroupData = data;
      this.questionData = data.questions.filter(x => x.showQuestion === true);
      this.questionGroupId = data.collectionId;
      this.consultationDrawId = this.questionGroupData?.noteId || '-1';
      this.noteService.currentContextNoteId = this.consultationDrawId;
      this.contextService.lastQuestionGroupId.next(data.collectionId);
      if (this.openDrawOnEntry) {
        this.actionService.setAction({ target: 'overlay-main', action: 'drawing' });
        this.openDrawOnEntry = false;
      }
      this.noteService.currentQuestionGroupId = qcId;
      this.noteService.pageReference = NoteContextEnum.questionGroup;
      this.showSyncQuestionSet.clear();

      if (
        (this.questionGroupData.template === QuestionTemplateEnum.products &&
          this.contextService.currentMode === 'main') ||
        (this.questionGroupData.template === QuestionTemplateEnum.default &&
          this.contextService.currentMode === 'prep' &&
          this.questionGroupData.availableForPreliminary)
      ) {
        this.onRecPanelTrigger('open');
      } else if (
        this.questionGroupData.template === QuestionTemplateEnum.default ||
        (this.questionGroupData.template === QuestionTemplateEnum.products &&
          this.contextService.currentMode === 'prep')
      ) {
        this.onRecPanelTrigger('closed');
      }

      if (
        this.questionGroupData?.questions.filter(
          question => question.type === QuestionEnum.tool && question.showQuestion === true
        ).length > 0
      ) {
        this.onAgendaPanelTrigger('closed');
        this.onRecPanelTrigger('closed');
      }

      handleOtherAnswers(this.questionGroupData, this.otherAnswersMap);

      // Functions for extracting the relevant data for the various question types.
      this.handleTextFieldData();
      this.handleSingleData();
      this.handleSliderData();
      this.handleSegmentRadioData();
      this.handleImageSelectData();

      this.handleDocumentData(data);

      if (this.contextService.currentMode === 'main' && this.clientService.numSolutions === -1) {
        this.queryService.getRecommendedCompositions(this.clientService.consultationId).subscribe(listData => {});
      }

      if (this.contextService.currentMode === 'prep') {
        this.isInPrep = data.selectedForPreliminary && data.availableForPreliminary;
        this.determinePrepDivider();
      }

      this.footerRightButtonsFactory();
      this.footerLeftButtonsFactory();

      const scrollableContainer = document.querySelector('#mainContent') as HTMLDivElement;

      if (scrollToTop && scrollableContainer) {
        scrollableContainer.scrollTop = 0;
      }

      this.focusAnswer();
    });

    this.contextService.measureStartupTime('stop');
  }

  private focusAnswer(): void {
    if (!this.focusElement) {
      return;
    }
    const targetRef = document.getElementById(this.focusElement.id || this.focusParent);
    let htmlCollection = [];

    if (!this.focusElement?.id) {
      const focusRef = this.focusElement.ref;
      const regularClassName = targetRef?.getElementsByClassName(focusRef?.className);
      const htmlElement =
        regularClassName?.length !== 0
          ? regularClassName
          : targetRef?.getElementsByClassName(focusRef?._elementRef?.nativeElement?.className);
      htmlCollection = [htmlElement[0]];
    } else {
      htmlCollection = [targetRef];
    }

    if (htmlCollection?.length > 0) {
      let input = null;
      const focusTarget = htmlCollection[0] as HTMLInputElement;
      if (
        focusTarget?.className.includes('slider') ||
        focusTarget?.className.includes('mat-checkbox') ||
        focusTarget?.localName === 'input'
      ) {
        input = focusTarget;
      }
      if (focusTarget?.className.includes('image-button-container')) {
        input = focusTarget.getElementsByClassName('mat-checkbox')[0]?.querySelector('input') || focusTarget;
      } else {
        input = !focusTarget?.querySelector('input') ? focusTarget : focusTarget.querySelector('input');
      }
      input?.focus();
    }
  }

  private initNextQuestionGroup(): void {
    // get the data for the next question-collection
    this.focusElement = null;
    this.sendTempTextRequest();
    this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupData.nextCollectionId);
    this.expansionPanel?.close();
  }

  private initPrevQuestionGroup(): void {
    // get the data for the prev question-collection
    this.focusElement = null;

    this.sendTempTextRequest();
    this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupData.prevCollectionId);
    this.expansionPanel?.close();
  }

  private initJumpQuestionGroup(action: Action): void {
    // get the data for the next question-collection
    this.focusElement = null;

    this.sendTempTextRequest();
    this.openDrawOnEntry = action.options?.openDrawOverlay || false;
    this.queryQuestionCollectionData(this.clientService.consultationId, action.options.questionGroupId, true);
    this.expansionPanel?.close();
  }

  private initFinishSubtopic(): void {
    const skipSummary = this.configService.skipSubtopicSummary;

    if (skipSummary) {
      this.initNextQuestionGroup();
    } else {
      this.focusElement = null;
      this.sendTempTextRequest();
      if (this.contextService.currentMode === 'main') {
        this.navigateToTopicSummary();
      } else if (this.contextService.currentMode === 'prep') {
        if (this.showPrepDivider !== 'none' || this.isLastConsultantQuestionGroup) {
          this.navigateToTopicSummary();
        } else {
          this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupData.nextCollectionId);
        }
      }
      this.expansionPanel?.close();
    }
  }

  private initFinishPerparationMode(): void {
    const hasTopicForPrelim = this.topicService.selectedTopicsData?.find(topic => topic.availableForPreliminary);
    const hasSubTopicForPrelim = this.topicService.selectedTopicsData?.map(topic => {
      topic.subtopics?.find(subtopic => subtopic.availableForPreliminary);
    });

    this.focusElement = null;
    if (this.isLastQuestionTotal) {
      this.router.navigate([`${RoutingPathPrep.TopicOverview}/${this.clientService.bankHubId}`]);

      if (!!hasTopicForPrelim || !!hasSubTopicForPrelim) {
        this.doAction('main', 'toggle');
      }
      return;
    }
  }

  private initFinishConsultation(): void {
    const skipSummary = this.configService.skipSubtopicSummary;
    const nextQuestionGroupId = this.questionGroupData?.nextCollectionId || '-1';

    if (skipSummary && nextQuestionGroupId === '-1') {
      this.router.navigate([RoutingPathMain.Checkout]);
    } else if (skipSummary) {
      this.initNextQuestionGroup();
    } else if (!skipSummary) {
      this.initFinishSubtopic();
    }
  }

  private async handleImageData(imageData: MediaItem[]): Promise<void> {
    this.imageData = [];
    if (imageData.length > 0) {
      for await (const x of imageData) {
        const url = await this.mediaService.getMediaContent(x.url);
        this.imageData.push({ url: url, altText: x.name });
      }
    }
  }

  private async handleQuestionImageData(questionData: QuestionItem[]): Promise<void> {
    const imageMediaItems = questionData.flatMap(x => x.medias.filter(y => y.type === MediaTypeEnum.image));

    if (imageMediaItems.length > 0) {
      this.questionImageData = [];
      for await (const x of imageMediaItems) {
        const url = await this.mediaService.getMediaContent(x.url);
        this.questionImageData.push({ id: x.id, url, altText: x.name });
      }
    }
  }

  private handleDocumentData(data: QuestionGroupResponse): void {
    this.documentData = uniqBy(
      [
        ...data.medias.filter(x => x.type !== MediaTypeEnum.image && x.type !== MediaTypeEnum.logo),
        ...this.questionData.flatMap(x =>
          x.medias.filter(y => y.type !== MediaTypeEnum.image && y.type !== MediaTypeEnum.logo)
        ),
      ],
      item => {
        return item.id;
      }
    );

    // still used in side-transition
    this.doAction('footer', 'document-info', { documents: this.documentData });
  }

  private handleSingleData(): void {
    this.singleAnswerData.clear();

    const myData = this.questionGroupData.questions.filter(
      x => x.type === QuestionEnum.single || x.type === QuestionEnum.toggle
    );

    for (const single of myData) {
      const questionWithAnswers = single.answers.filter(x =>
        x.givenAnswers.find(k => k.answeredBy === this.answerType)
      );
      if (questionWithAnswers.length > 0) {
        this.singleAnswerData.set(single.id, questionWithAnswers[0].id);
      }
    }
  }

  private handleSliderData(): void {
    this.numericalAnswerMinMax.clear();
    const myData = this.questionGroupData.questions.filter(x => {
      return (
        x.type === this.questionEnum.slider ||
        x.type === this.questionEnum.conSlider ||
        x.type === this.questionEnum.expSlider ||
        x.type === this.questionEnum.number
      );
    });

    try {
      for (const slider of myData) {
        const minArray = slider.answers.flatMap(x => x.minValue);
        const maxArray = slider.answers.flatMap(x => x.maxValue);
        const min = Math.min(...minArray);
        const max = Math.max(...maxArray);
        this.numericalAnswerMinMax.set(slider.id, { min: min, max: max });

        const givenAnswers = slider.answers.flatMap(x => x.givenAnswers);
        const answerId = slider.answers.find(x => x.givenAnswers.find(y => y.answeredBy === this.answerType))?.id;
        const hasGivenAnswer = givenAnswers.findIndex(r => r.answeredBy === this.answerType) >= 0;
        const valueObject = hasGivenAnswer
          ? {
              value: Number(givenAnswers.find(r => r.answeredBy === this.answerType).value || 0),
              defaultSliderValue: false,
              note: givenAnswers.find(r => r.answeredBy === this.answerType).note,
              answerId: answerId,
            }
          : { value: Number(slider.defaultValue || 0), defaultSliderValue: true, note: '' };
        this.numericalAnswerData.set(slider.id, valueObject);
      }
    } catch (err) {}
  }

  private handleSegmentRadioData(): void {
    this.segmentedAnswerData.clear();
    const myData = this.questionGroupData.questions.filter(x => x.type === this.questionEnum.segments);

    try {
      for (const segementedRadioData of myData) {
        const labelData = segementedRadioData.answers.map(y => {
          return {
            id: y.id,
            label: y.label,
            checked: y.givenAnswers.findIndex(r => r.answeredBy === this.answerType) >= 0,
          };
        });
        this.segmentedAnswerData.set(segementedRadioData.id, labelData);
      }
    } catch (err) {}
  }

  private async handleImageSelectData(): Promise<void> {
    this.imageAnswerData.clear();
    const myData = this.questionGroupData.questions.filter(x => {
      return x.type === this.questionEnum.singleImage || x.type === this.questionEnum.multiImage;
    });

    try {
      for await (const imageQuestionData of myData) {
        const answerData: ImageAnswerData[] = await Promise.all(
          imageQuestionData.answers.map(async x => {
            return {
              id: x.id,
              answertext: x.label,
              imageUrl: await this.mediaService.getMediaContent(x.media.url),
              checked: this.showPreselectedState(x.givenAnswers),
              tooltip: x.tooltip,
            };
          })
        );
        this.imageAnswerData.set(imageQuestionData.id, answerData);
      }
      this.chg.detectChanges();
    } catch (error) {
      console.log(`%c [bgzv-frontend-main] - ${error}`, 'color: #ff3300');
    }
  }

  private handleSelectImage(questionId: string, value: VrImageSelectOutput[]) {
    let sendObj: UpdateAnswerItem[] = [];

    if (value.some(selectedAnswer => selectedAnswer.selected)) {
      const selectedId = value.find(selectedAnswer => selectedAnswer.selected)?.id || this.UUIDNull;
      const answeredBy = selectedId === this.UUIDNull ? GivenAnswerEnum.none : this.answerType;
      sendObj.push({ id: selectedId, note: null, value: null, answeredBy: answeredBy });
    } else {
      sendObj = answerNullObject(this.answerType);
    }

    this.chg.detectChanges();
    this.sendAnswer(this.clientService.consultationId, questionId, sendObj);
  }

  private handleTextFieldData(): void {
    this.showTextMap.clear();
    const type = this.contextService.currentMode === 'main' ? GivenAnswerEnum.consultation : GivenAnswerEnum.consultant;

    for (const question of this.questionGroupData.questions) {
      if (
        question.type === QuestionEnum.single ||
        question.type === QuestionEnum.number ||
        question.type === QuestionEnum.segments ||
        question.type === QuestionEnum.text
      ) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true && y.givenAnswers.length > 0);
        if (
          filteredAnswers.length > 0 &&
          filteredAnswers[0].givenAnswers.filter(x => x.answeredBy === type).length > 0
        ) {
          this.showTextMap.set(question.id, [filteredAnswers[0]]);
          this.prevAnswerObj = answerObjectFactory(filteredAnswers, type);

          if (question.type === QuestionEnum.single || question.type === QuestionEnum.segments) {
            delete this.prevAnswerObj[0].value;
          }
        }
      } else if (question.type === QuestionEnum.multi) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true && y.givenAnswers.length > 0);
        if (
          filteredAnswers.length > 0 &&
          filteredAnswers.flatMap(x => x.givenAnswers).filter(x => x.answeredBy === type).length > 0
        ) {
          this.showTextMap.set(question.id, filteredAnswers);
        }
      } else if (
        question.type === QuestionEnum.slider ||
        question.type === QuestionEnum.expSlider ||
        question.type === QuestionEnum.conSlider
      ) {
        const filteredAnswers = question.answers.filter(y => y.hasTextField === true);
        if (filteredAnswers.length > 0) {
          this.showTextMap.set(question.id, filteredAnswers);
          this.prevAnswerObj = answerObjectFactory(filteredAnswers, GivenAnswerEnum.consultation);
        }
      }
    }
  }

  private handleTextFieldDataAfterSelection(questionId, answerObj: UpdateAnswerItem[]): void {
    const question = this.questionGroupData.questions.find(x => x.id === questionId);

    if (question.type === QuestionEnum.single || question.type === QuestionEnum.segments) {
      if (this.showTextMap.has(questionId)) {
        this.showTextMap.delete(questionId);
      }

      const answer = question.answers.find(x => x.id === answerObj[0].id);
      this.prevAnswerObj = null;

      if (answer && answer.hasTextField) {
        answer.givenAnswers = [];
        const { id, ...hello } = answerObj[0];
        answer.givenAnswers.push(hello);

        this.showTextMap.set(question.id, [answer]);
        this.prevAnswerObj = answerObj;
      } else if (!answer && this.showTextMap.has(question.id)) {
        this.showTextMap.delete(question.id);
      }
    } else if (question.type === QuestionEnum.multi) {
      if (this.showTextMap.has(questionId)) {
        this.showTextMap.delete(questionId);
      }

      const answers = question.answers.filter(x => answerObj.find(y => x.id === y.id));

      const newAnswers: AnswerOptionItem[] = [];
      if (answers && answers.length > 0) {
        for (const answer of answers) {
          if (answer && answer.hasTextField) {
            newAnswers.push(answer);
          }
        }
        this.showTextMap.set(question.id, newAnswers);
        this.prevAnswerObj = answerObj;
      } else if (this.showTextMap.has(question.id)) {
        this.showTextMap.delete(questionId);
      }
    } else if (
      question.type === QuestionEnum.slider ||
      question.type === QuestionEnum.expSlider ||
      question.type === QuestionEnum.conSlider
    ) {
      if (this.showTextMap.has(questionId)) {
        const answers = this.showTextMap.get(questionId);
        answers.forEach(element => {
          element.givenAnswers = [];
          if (answerObj[0].id === element.id) {
            const { id, ...hello } = answerObj[0];
            this.prevAnswerObj = answerObj;
            element.givenAnswers.push(hello);
          }
        });
      }
    }
  }

  private sendAnswer(consultationId: string, questionId: string, sendObject: UpdateAnswerItem[]) {
    if (this.tempTextAnswer) {
      this.setPreviousTextRequest(consultationId, questionId, sendObject);
      this.sendTempTextRequest();
    } else {
      this.handleSendAnswer(consultationId, questionId, sendObject);
    }
  }

  private setPreviousTextRequest(consultationId, questionId, sendObj) {
    this.previousAnswerRequest = { consultationId, questionId, sendObj };
  }

  private handleSendAnswer(
    consultationId: string,
    questionId: string,
    sendObject: UpdateAnswerItem[],
    reload = false
  ): void {
    if (sendObject.length === 0) {
      console.error('No answer defined. Please check the content configuration.');
      return;
    }

    this.handleTextFieldDataAfterSelection(questionId, sendObject);
    let getRecommendations = false;

    // if we empty a textfield with text in it, the backend needs this in case some rules prompted
    // a new question from the text in the textfield
    if (sendObject[0].value === '') {
      sendObject = answerNullObject(sendObject[0].answeredBy);
    }

    this.queryService.putAnswerFromGroup(consultationId, questionId, sendObject).subscribe(data => {
      // here we should get back the same data-structure as the initial request
      if (
        (data.productsChanged || data.tasksChanged || data.transitionsChanged || data.questionsChanged) &&
        !data.questionGroupsChanged
      ) {
        this.doAction('side-recommendation', 'get-recommendation');
      } else if (data.productsChanged || data.tasksChanged || data.transitionsChanged) {
        getRecommendations = true;
      }

      if (data.questionsChanged || data.questionGroupsChanged || getRecommendations || reload) {
        this.queryQuestionCollectionData(this.clientService.consultationId, this.questionGroupId, false);
      }
      // if there a queued requests because of text-fields, they will be sent here
      if (this.previousAnswerRequest) {
        const previousAnswer = { ...this.previousAnswerRequest };
        this.previousAnswerRequest = null;
        this.sendAnswer(previousAnswer.consultationId, previousAnswer.questionId, previousAnswer.sendObj);
      }
      this.doAction('agenda', 'refresh');

      this.restoreScrollPos = true;
      this.chg.detectChanges();
    });
  }

  public getNotesLabel(question: QuestionItem): string {
    return this.getCurrentNumericalAnswerOption(question)?.label;
  }

  public getNotesInput(question: QuestionItem): string {
    return this.numericalAnswerData.get(question.id)?.note;
  }

  private getCurrentNumericalAnswerOption(question: QuestionItem): AnswerOptionItem {
    const value = this.numericalAnswerData.get(question.id)?.value;
    return question.answers.filter(x => value <= x.maxValue && value >= x.minValue)[0];
  }

  private getCurrentNumericalAnswerId(question: QuestionItem, value): string {
    return question.answers.filter(x => value <= x.maxValue && value >= x.minValue)[0].id;
  }

  private determinePrepDivider(): void {
    this.showPrepDivider = 'none';
    const c = this.topicService.selectedSubTopicDataByQuestionId(this.questionGroupData.collectionId);
    const n = this.topicService.selectedSubTopicDataByQuestionId(this.questionGroupData.nextCollectionId);

    if (c?.consultantSubtopic && !n?.consultantSubtopic && n?.availableForPreliminary) {
      this.showPrepDivider = 'consultant';
    } else if (c?.availableForPreliminary && !n?.availableForPreliminary && n?.consultantSubtopic) {
      this.showPrepDivider = 'customer';
    }
  }

  // --------------------------------------------- //
  // --------- RECOMMENDATION DE-/SELECT --------- //
  // --------------------------------------------- //

  public isQuestionShown(question: QuestionItem): boolean {
    return !(question.showQuestion !== undefined && !question.showQuestion);
  }

  public isQuestionPaired(question: QuestionItem): boolean {
    if (this.contextService.currentMode === 'main') {
      return question.syncedAnswers?.findIndex(x => x.answeredBy === GivenAnswerEnum.consultation) >= 0;
    } else if (this.contextService.currentMode === 'prep') {
      return question.syncedAnswers?.findIndex(x => x.answeredBy === GivenAnswerEnum.consultant) >= 0;
    }

    return false;
  }

  public getPairedQuestionAnswer(question: QuestionItem): string[] {
    let returnValue: SyncedAnswers;
    returnValue = question.syncedAnswers.find(x => x.answeredBy === this.answerType);
    return syncedAnswerTextFactory(returnValue);
  }

  public isDebugInQuestionHeadingShown(question: QuestionItem): boolean {
    return question.type === QuestionEnum.variantCalculator && this.configService.isDebugMode;
  }

  public getStepValue(question): number {
    if (question.type !== this.questionEnum.expSlider && question.stepValue === 0) {
      return 1;
    } else {
      return question.stepValue;
    }
  }

  public getSliderQuestionLabel(questionName): string {
    const questionString = questionName.split('#/#');
    if (questionString.length > 1) {
      return `${questionString[questionString.length - 2]}#/#${questionString[questionString.length - 1]}`;
    } else {
      return questionString;
    }
  }

  public getSliderQuestionHeader(questionName): string {
    const questionString = questionName.split('#/#');
    if (questionString.length > 1) {
      return questionString[0];
    } else {
      return questionName;
    }
  }

  public getTextAnswer(question: QuestionItem): string {
    const currentAnswer = question.answers[0]?.givenAnswers?.find(x => x.answeredBy === this.answerType);
    return currentAnswer?.value || '';
  }

  public getLabelName(compositionElement: RecommendationItem): string {
    const foundProducts = this.totalRecommendationData.find(
      x =>
        x.compositionId === compositionElement.compositionId &&
        (x.type === RecommendationTypeEnum.product ||
          x.type === RecommendationTypeEnum.main ||
          x.type === RecommendationTypeEnum.variant ||
          x.type === RecommendationTypeEnum.additional)
    );
    if (!foundProducts) {
      return compositionElement.name || 'NO TITLE';
    } else {
      if (compositionElement.type === RecommendationTypeEnum.transition) {
        return compositionElement.parentElementName;
      } else {
        return `${compositionElement.parentElementName} (${compositionElement.name || 'NO TITLE'})`;
      }
    }
  }

  public questionHasTextFields(question: QuestionItem): boolean {
    return question.answers.filter(answer => answer.hasTextField).length > 0;
  }

  public changeSyncQuestionState(questionId: string): void {
    !this.showSyncQuestionSet.has(questionId)
      ? this.showSyncQuestionSet.add(questionId)
      : this.showSyncQuestionSet.delete(questionId);
  }

  public showSyncedQuestions(questionId: string): boolean {
    return this.showSyncQuestionSet.has(questionId);
  }

  public tooManyMarks(question: QuestionItem): boolean {
    const values = this.numericalAnswerMinMax.get(question.id);
    if (!values || question.type !== QuestionEnum.slider) {
      return false;
    }
    return (values?.max - values?.min) / question?.stepValue > 10;
  }

  public getTestcafeId(name = '', id: string) {
    return `${name?.replace(/ /g, '')}-${id}`;
  }

  public getTestcafeAnswerId(name = '') {
    return `${name?.replace(/ /g, '')}`;
  }

  get useMargin(): boolean {
    return this.contextService.isStickyMode || !this.contextService.isManagedFooter;
  }

  get isScreenshotting(): boolean {
    return this.noteService.screenshotting;
  }

  set isScreenshotting(screenshotting: boolean) {
    this.noteService.screenshotting = screenshotting;
  }
}
