<form *ngIf="dataFieldForm" [formGroup]="dataFieldForm" class="mb-4">
  <ng-container
    *ngFor="let group of dataFieldData?.dataFieldGroups; let i = index; let last = last; let first = first"
    formArrayName="datafields"
  >
    <!-- NON-MULTIPLIED DATA FIELD GROUPS-->
    <ng-container *ngIf="!group?.multiplied">
      <div class="font-weight-bold w-100 datafield-divider" [class.datafield-divider]="!last">
        <h3 class="vr-text-regular font-weight-bold text-nowrap mb-0 mr-4">{{ group.name }}</h3>
        <ng-template *ngTemplateOutlet="fields; context: { data: group, i, last }"></ng-template>
      </div>
    </ng-container>

    <!-- MULTIPLIED DATA FIELD GROUPS WITHOUT CONTRACTS-->
    <ng-container *ngIf="group?.multiplied && !getHasContractForm(group)">
      <ng-template *ngTemplateOutlet="panel; context: { data: group, i, last, first }"></ng-template>
    </ng-container>
  </ng-container>

  <!-- MULTIPLIED DATA FIELD GROUPS WITH CONTRACTS -->
  <ng-container
    *ngFor="let group of uniqueContractDatafields; let i = index; let first = first; let last = last"
    formArrayName="datafields"
  >
    <ng-template
      *ngTemplateOutlet="
        panelContract;
        context: { data: getContract(group.ordinal), ordinal: group.ordinal, i, last, first }
      "
    ></ng-template>
  </ng-container>
</form>

<!-- TEMPLATES -->
<!-- PANEL FOR DFG WITH CONTRACT -->
<ng-template #panelContract let-group="data" let-i="i" let-ordinal="ordinal" let-last="last">
  <div
    #groupPanel
    class="border group-panel"
    [id]="'groupOrdinal_' + ordinal"
    [class.group-panel-with-contracts]="getHasContractForm(group[0]) && getContractIncluded(group[0])"
  >
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="panelOpen['groupOrdinal_' + ordinal] = true"
      (closed)="panelOpen['groupOrdinal_' + ordinal] = false"
      [expanded]="group[0]?.ordinal === 1 || panelOpen['groupOrdinal_' + ordinal]"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title class="text-nowrap">{{ 'Vertrag ' + ordinal }}</mat-panel-title>
          <div class="w-100 d-flex mat-panel-items justify-content-between">
            <ng-container
              *ngIf="
                mandatoryDataFieldsIncomplete(getIndex(group[0])) && !isPanelOpen('groupOrdinal_' + group[0]?.ordinal)
              "
            >
              <div class="vr-text-small vr-color-error d-flex align-items-center ml-2">
                <kf-icon name="ic_warnschild_24" class="mr-2"></kf-icon
                ><span class="text-nowrap">Angaben erforderlich</span>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div *ngFor="let item of group; let j = index">
          <ng-container *ngIf="getHasContractForm(group[0]) && getContractIncluded(group[0])">
            <div #contractButton class="d-flex my-0 p-1 justify-content-start contract-buttons">
              <ng-template
                *ngTemplateOutlet="
                  contractButtons;
                  context: { data: productStatus, productData, group: group[0], index: i }
                "
              ></ng-template>
            </div>
          </ng-container>
          <div class="expansion-panel-body">
            <!-- CONTRACT CHECKBOX -->
            <div *ngIf="getHasContractForm(group[0])" class="mt-4 contract-toggle">
              <div class="mb-4">
                <mat-checkbox
                  kfFocus
                  #contractCheckbox
                  *ngIf="j === 0"
                  [attr.data-testcafe]="'contractFor-' + item.dataFieldGroupId"
                  [checked]="getContractIncluded(item)"
                  (change)="toggleIncludeExcludeContract(contractCheckbox.checked, item, productData)"
                  >Mit Vertrag?</mat-checkbox
                >
              </div>
              <h3 class="vr-text-large text-nowrap mb-2">{{ item?.name }}</h3>
            </div>

            <ng-template *ngTemplateOutlet="fields; context: { data: item, i: getIndex(item), last }"></ng-template>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- PANEL FOR DFG WITHOUT CONTRACT -->
<ng-template #panel let-group="data" let-i="i" let-last="last">
  <div #groupPanel class="border group-panel" [id]="'groupOrdinal_' + group?.ordinal">
    <mat-expansion-panel
      class="mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
      (opened)="panelOpen['groupOrdinal_' + group?.ordinal] = true"
      (closed)="panelOpen['groupOrdinal_' + group?.ordinal] = false"
      [expanded]="group?.ordinal === 1 || panelOpen['groupOrdinal_' + group?.ordinal]"
    >
      <mat-expansion-panel-header>
        <div class="d-flex py-3 w-100 align-items-center">
          <mat-panel-title
            ><strong class="font-weight-bold text-nowrap">{{ group?.name }}</strong></mat-panel-title
          >
          <div class="w-100 d-flex mat-panel-items justify-content-between">
            <ng-container
              *ngIf="mandatoryDataFieldsIncomplete(getIndex(group)) && !isPanelOpen('groupOrdinal_' + group?.ordinal)"
            >
              <div class="vr-text-small vr-color-error d-flex align-items-center ml-2">
                <kf-icon name="ic_warnschild_24" class="mr-2"></kf-icon
                ><span class="text-nowrap">Angaben erforderlich</span>
              </div>
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel-header>

      <div class="expansion-panel-body">
        <ng-template *ngTemplateOutlet="fields; context: { data: group, i, last }"></ng-template>
      </div>
    </mat-expansion-panel>
  </div>
</ng-template>

<!-- DATA FIELDS -->
<ng-template #fields let-group="data" let-i="i" let-last="last">
  <div class="wrapper group-wrapper">
    <div
      *ngFor="let item of group.dataFields"
      class="d-flex flex-column w-50 font-weight-normal pr-3 mb-3 group-content"
    >
      <!-- item-dataField for text-input-->
      <div class="d-flex" *ngIf="item.dataFieldType === dataFieldType.text">
        <mat-form-field
          [formGroup]="getFormGroup(i)"
          class="notranslate"
          [attr.data-testcafeData]="
            getTestcafeLabel(group.name, group.dataFieldGroupId, item.name, item.dataFieldValueId)
          "
          [attr.data-item]="item"
        >
          <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
          <input
            #textInput
            [formControlName]="item.dataFieldValueId"
            autocomplete="off"
            matInput
            (change)="handleValueChange(textInput.value, item)"
            type="text"
          />
          <mat-error>
            <kf-input-error
              *ngIf="getFormGroup(i).controls[item.dataFieldValueId].invalid"
              [message]="getFieldErrorMessage(getFormGroup(i).controls[item.dataFieldValueId], item.name)"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="item.description">
          <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
        </ng-container>
      </div>

      <!-- item-dataField for number-input-->
      <div class="d-flex" *ngIf="[dataFieldType.number, dataFieldType.price].includes(item.dataFieldType)">
        <mat-form-field
          [formGroup]="getFormGroup(i)"
          class="notranslate"
          [attr.data-testcafeData]="
            getTestcafeLabel(group.name, group.dataFieldGroupId, item.name, item.dataFieldValueId)
          "
          [attr.data-item]="item"
        >
          <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
          <input
            #numberInput
            [formControlName]="item.dataFieldValueId"
            autocomplete="off"
            matInput
            (change)="handleValueChange(numberInput.value, item)"
            type="number"
          />
          <mat-error>
            <kf-input-error
              *ngIf="getFormGroup(i).controls[item.dataFieldValueId].invalid"
              [message]="getFieldErrorMessage(getFormGroup(i).controls[item.dataFieldValueId], item.name)"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="item.description">
          <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
        </ng-container>
      </div>

      <!-- item-dataField for textarea -->
      <div class="d-flex" *ngIf="item.dataFieldType === dataFieldType.longText">
        <mat-form-field
          class="notranslate"
          [formGroup]="getFormGroup(i)"
          [attr.testcafeData]="getTestcafeLabel(group.name, group.dataFieldGroupId, item.name, item.dataFieldValueId)"
          [attr.data-item]="item"
        >
          <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
          <div class="kf-field-char-counter" aria-hidden="true">{{ textArea.value?.length || 0 }}/4000</div>
          <textarea
            #textArea
            ngDefaultControl
            [formControlName]="item.dataFieldValueId"
            matInput
            maxLength="4000"
            (change)="handleValueChange(textArea.value, item)"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <ng-container *ngIf="item.description">
          <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
        </ng-container>
      </div>

      <!-- item-dataField dropdown -->
      <div class="d-flex" *ngIf="item.dataFieldType === dataFieldType.dropdown">
        <mat-form-field [formGroup]="getFormGroup(i)" [attr.data-item]="item">
          <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
          <mat-select
            #select
            kfSelectMdc
            [formControlName]="item.dataFieldValueId"
            [disabled]="disableFields"
            (selectionChange)="handleSelectionChange($event, item)"
          >
            <mat-option *ngFor="let option of optionDataFactory(item)" [value]="option">{{ option }}</mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="getFormGroup(i).controls[item.dataFieldValueId].invalid"
              [message]="getFieldErrorMessage(getFormGroup(i).controls[item.dataFieldValueId], item.name)"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="item.description">
          <ng-template *ngTemplateOutlet="infoIcon; context: { description: item.description }"></ng-template>
        </ng-container>
      </div>

      <div
        *ngIf="item.dataFieldType === dataFieldType.checkbox"
        class="dfCheckBox"
        [formGroup]="getFormGroup(i)"
        [attr.item]="item"
      >
        <mat-checkbox
          kfFocus
          #dataFieldCheckbox
          ngDefaultControl
          [attr.data-testcafe]="'dataField-checkbox-' + item.name + '-' + item.dataFieldValueId"
          [disabled]="disableFields"
          [formControlName]="item.dataFieldValueId"
          (change)="handleValueChange(dataFieldCheckbox.checked, item)"
        >
          {{ item.name }}
        </mat-checkbox>
      </div>

      <mat-radio-group
        *ngIf="item.dataFieldType === dataFieldType.radio"
        class="my-radio-group"
        [formGroup]="getFormGroup(i)"
        [attr.item]="item"
      >
        {{ item.name }}
        <mat-radio-button
          #radioButton
          *ngFor="let option of optionDataFactory(item)"
          kfFocus
          ngDefaultControl
          [value]="option"
          [checked]="item.value === option"
          [formControlName]="item.dataFieldValueId"
          (change)="handleValueChange(radioButton.value, item)"
        >
          {{ option }}
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="item.dataFieldType === dataFieldType.date" [formGroup]="getFormGroup(i)">
        <mat-label>{{ getFormControlFieldLabel(item) }}</mat-label>
        <input
          #dateField
          [formControlName]="item.dataFieldValueId"
          [matDatepicker]="picker"
          [min]="currentDate"
          [value]="toDate(getFormGroup(i).value[item.dataFieldValueId])"
          matInput
          readonly
          tabindex="-1"
          placeholder="dd.mm.yyyy"
          aria-describedby="date-format-hint"
          color="primary"
          (dateChange)="handleValueChange(dateField.value, item)"
        />
        <mat-error>
          <kf-input-error
            *ngIf="getFormGroup(i).controls[item.dataFieldValueId].invalid"
            [message]="getFieldErrorMessage(getFormGroup(i).controls[item.dataFieldValueId], item.name)"
          >
          </kf-input-error>
        </mat-error>
        <mat-datepicker-toggle [for]="picker" matSuffix aria-label="Datum wählen">
          <kf-icon name="ic_calendar_24" matDatepickerToggleIcon></kf-icon>
        </mat-datepicker-toggle>
        <mat-datepicker kfDatepickerPositioning #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <ng-container *ngIf="last">
    <button
      *ngIf="showRemoveDataFieldButton"
      class="datafield-button"
      (click)="onRemoveDataField(group)"
      mat-flat-button
      color="primary"
      [disabled]="isLoading"
    >
      <kf-icon name="ic_loeschen_24"></kf-icon>
      <span>Datensatz entfernen</span>
    </button>
  </ng-container>
</ng-template>

<!-- CONTRACT BUTTONS FOR PANEL HEADER -->
<ng-template #contractButtons let-data="data" let-group="group" let-product="productData" let-index="index">
  <!-- contract preview can be generated -->
  <ng-container
    *ngIf="
      getLowestContractsStatus(product, group.ordinal) !== contractStatusEnum.generatePreview &&
      getLowestContractsStatus(product, group.ordinal) !== contractStatusEnum.error &&
      getLowestContractsStatus(product, group.ordinal) !== contractStatusEnum.preview &&
      !contractPolling(group)
    "
  >
    <button
      mat-stroked-button
      color="primary"
      (click)="generateContractPreview(product, group)"
      [disabled]="isLoading || mandatoryDataFieldsIncomplete(getIndex(group)) || contractPolling(group)"
    >
      <kf-icon name="ic_pdf_24"></kf-icon>
      <span>Vertragsvorschau generieren</span>
    </button>
  </ng-container>

  <!-- contract preview being generated -->
  <ng-container
    *ngIf="
      getLowestContractsStatus(product, group.ordinal) === contractStatusEnum.generatePreview ||
      !getIsProductInstancePollingFinished(group) ||
      contractPolling(group)
    "
  >
    <div class="d-flex vr-text-regular align-items-center">
      <mat-spinner [diameter]="24" [color]="'primary'" class="mr-1"></mat-spinner>
      <p class="font-weight-bold text-nowrap m-0">Vertragsvorschau wird generiert</p>
    </div>
  </ng-container>

  <!-- contract preview generated  -->
  <ng-container
    *ngIf="
      getLowestContractsStatus(product, group.ordinal) === contractStatusEnum.preview &&
      getIsProductInstancePollingFinished(group)
    "
  >
    <div class="button-row">
      <div class="button-row-content">
        <button
          mat-stroked-button
          color="primary"
          (click)="generateContractPreview(product, group)"
          [disabled]="
            isLoading ||
            mandatoryDataFieldsIncomplete(getIndex(group)) ||
            contractPolling(group) ||
            !getUpdateNeeded(group)
          "
        >
          <kf-icon name="ic_aktualisieren_24"></kf-icon>
          <span>Vertragsvorschau aktualisieren</span>
        </button>

        <button
          mat-stroked-button
          color="primary"
          (click)="openContractPreviews(product.id, group)"
          [disabled]="
            isLoading ||
            mandatoryDataFieldsIncomplete(getIndex(group)) ||
            contractPolling(group) ||
            getUpdateNeeded(group)
          "
        >
          <span>Vertragsvorschau öffnen</span>
        </button>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #infoIcon let-description="description">
  <kf-icon
    [kfTooltip]="description | stripHtml"
    alt="Details"
    class="ml-2 mt-2 icon-is-interactive"
    kfTooltipPosition="after"
    kfTooltipShow="click"
    name="ic_infozeichen-mit-kreis_24"
    role="button"
    tabindex="0"
  ></kf-icon>
</ng-template>
