import { NoteTypeEnum } from '@enums';

export enum TargetEnum {
  recommened = 'RECOMMENDED',
  other = 'OTHER',
}

export interface VrImageData {
  id?: string;
  url: any;
  altText?: string;
  selected?: boolean;
}

export interface SegmentedLabelData {
  id: string;
  label: string;
  checked: boolean;
}

export interface ImageAnswerData {
  id: string;
  answertext: string;
  imageUrl: string;
  checked: boolean;
}

export interface NumberMinMaxData {
  min: number;
  max: number;
}

export type TriggerOverride = 'toggle' | 'closed' | 'open';

export const sortCriteria = {
  MAIN_PRODUCT: 1,
  VARIANT_PRODUCT: 2,
  TASK: 3,
  TRANSITION: 4,
  ADDITIONAL_PRODUCT: 5,
};

export interface ValueObject {
  value: number;
  defaultSliderValue: boolean;
  note?: string;
  answerId?: string;
}

export interface NoteUpdatedResult {
  id?: string;
  type?: Record<NoteTypeEnum, string> | string;
}

export interface VrImageSelectOutput {
  id: string;
  selected: boolean;
}
