<div style="width: 85vw">
  <!-- HEADER -->
  <div class="pl-4 py-4 border-bottom" style="min-height: 8rem">
    <div class="vr-headline-200 font-weight-bold mb-2">
      Angehängte Notizen und Dokumente ({{
        numberOfElements === numberOfSelectedElements
          ? numberOfElements
          : numberOfSelectedElements + ' / ' + numberOfElements
      }})
    </div>
    <div>
      {{
        numberOfElements > 0
          ? 'Ausgewählte Notizen und Dokumente werden dem Bestelldokument angehängt.'
          : 'Für diese Beratung wurden keine Notizen oder Dokumente erstellt.'
      }}
    </div>
  </div>

  <div class="d-flex pl-4" style="min-height: calc(100vh - 8rem); max-height: calc(100vh - 8rem)">
    <side-notes-menu
      class="d-flex w-25"
      [class.border-right]="numberOfElements > 0"
      (numberOfElements)="getNumberOfElements($event)"
      (numberOfSelectedElements)="getNumberOfSelectedElements($event)"
      (note)="onOpenNote($event)"
      (document)="onOpenDocument($event)"
    ></side-notes-menu>

    <!-- NOTE / DOCUMENT VIEWER -->
    <div class="d-flex flex-column flex-scroll mt-3 px-3 w-75">
      <div *ngIf="currentNote" class="d-flex flex-column vr-headline-200 font-weight-bold mb-2 pl-2 text-break">
        {{ displayName }}
      </div>
      <div
        *ngIf="currentNote && type === noteTypeEnum.drawing"
        class="d-flex flex-column pl-2"
        [innerHTML]="drawing"
      ></div>
      <div
        *ngIf="currentNote && type === noteTypeEnum.text"
        class="d-flex flex-column pl-2"
        style="hyphens: auto; overflow-wrap: break-word; white-space: pre-line"
        [innerHTML]="text"
      ></div>
      <div *ngIf="currentDocument" class="d-flex flex-column pl-2 app-height">
        <documents-viewer #documentViewRef [inCheckout]="true"></documents-viewer>
      </div>
    </div>
  </div>

  <button
    [attr.data-testcafe]="'sideNotes-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
    (click)="handleClose()"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</div>
