import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OtherAnswerItem, OtherAnswers } from '@domain/app/answers.domain';
import { QuestionItem } from '@domain/app/question.domain';
import { QuestionEnum } from '@enums';
import { color, libIcons } from 'bgzv-frontend-library';

export interface DialogOtherAnswerData {
  otherAnswers: OtherAnswers;
  target: any;
  question: QuestionItem;
  context?: string;
}

@Component({
  selector: 'dialog-other-answers',
  templateUrl: './dialog-other-answers.component.html',
  styleUrls: ['./dialog-other-answers.component.scss'],
})
export class DialogOtherAnswersComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogContainer') dialogContainer: ElementRef;

  public otherAnswers: OtherAnswers;

  public customerAnswers: OtherAnswerItem[];
  public consultantAnswers: OtherAnswerItem[];
  public consultationAnswers: OtherAnswerItem[];
  public historyAnswers: OtherAnswerItem[];

  readonly color = color;
  readonly buttonIcon = libIcons;

  constructor(
    public dialogRef: MatDialogRef<DialogOtherAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogOtherAnswerData
  ) {}

  ngOnInit(): void {
    this.otherAnswers = this.data.otherAnswers;

    this.customerAnswers = this.data.otherAnswers.CUSTOMER || [];
    this.consultantAnswers = this.showConsultantAnswers ? this.data.otherAnswers.CONSULTANT : [];
    this.historyAnswers = this.showHistoryAnswers ? this.data.otherAnswers.HISTORY : [];
  }

  ngAfterViewInit(): void {
    const rect: DOMRect = this.data.target.getBoundingClientRect();
    const dialogHeight = this.dialogContainer.nativeElement.offsetHeight;

    if (rect.bottom + dialogHeight > window.innerHeight) {
      this.dialogRef.updatePosition({
        left: `${rect.right + 24}px`,
        bottom: `6rem`,
      });
    } else {
      this.dialogRef.updatePosition({
        left: `${rect.right + 24}px`,
        top: `${rect.top}px`,
      });
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public onTakeAnswers(label: string) {
    let selectedAnswers = [];

    if (label.toLowerCase().includes('kunde')) {
      selectedAnswers = this.customerAnswers;
    } else if (label.toLowerCase().includes('berater')) {
      selectedAnswers = this.consultantAnswers;
    } else if (label.toLowerCase().includes('antwort')) {
      selectedAnswers = this.historyAnswers;
    }

    const defaults = { note: null, value: null };
    const answers = selectedAnswers.map(x => {
      return { ...defaults, ...x };
    });
    this.dialogRef.close({ answers: answers, question: this.data.question });
  }

  get showCustomerAnswers(): boolean {
    return this.data.otherAnswers.CUSTOMER.length > 0;
  }

  get showConsultantAnswers(): boolean {
    return this.data.context === 'main' && this.data.otherAnswers.CONSULTANT.length > 0;
  }

  get showConsultantMargin(): boolean {
    return this.showConsultantAnswers && this.showCustomerAnswers;
  }

  get showHistoryAnswers(): boolean {
    return this.data.otherAnswers.HISTORY.length > 0;
  }

  get showHistoryMargin(): boolean {
    return (this.showHistoryAnswers && this.showConsultantAnswers) || this.showCustomerAnswers;
  }

  get showAnswerLabel(): boolean {
    return (
      this.data.question.type !== QuestionEnum.text &&
      this.data.question.type !== QuestionEnum.conSlider &&
      this.data.question.type !== QuestionEnum.expSlider
    );
  }
}
