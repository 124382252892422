import { ButtonType, ComponentType, ElementConfig } from '@de.fiduciagad.kbm/shared-footer-lib';

export const buttonFinishConsultation: ElementConfig = {
  type: ComponentType.BUTTON,
  inputs: {
    owner: 'bgzv',
    action: 'result-finish-consultation',
    config: {
      type: ButtonType.STROKED,
      label: 'Beratung beenden',
    },
  },
};
