<div class="d-flex flex-column flex-scroll w-100 ml-3 mt-3">
  <div class="d-flex justify-content-between">
    <div class="mb-2 vr-text-large font-weight-bold text-truncate">
      <span
        *ngIf="
          allNotes?.notes.length > 0 || allNotes?.notesByPageReference?.length > 0 || allNotes?.notesByTopic.length > 0
        "
        >Notizen</span
      >
    </div>
  </div>
  <div *ngIf="allNotes" class="mb-4">
    <ng-container *ngFor="let note of allNotes.notes">
      <div class="d-flex align-items-center">
        <mat-checkbox
          class="mb-2"
          [checked]="note.includeInResult"
          [attr.data-testcafe]="'sideNotesMenu-checkbox-note-' + note.name"
          (change)="handleIncludeExcludeNote(note, note.id, note.includeInResult)"
          [color]="'grey'"
          ><span class="sr-only">{{
            truncateText(note.name, 20) + ' ' + (note.includeInResult ? 'abwählen' : 'auswählen')
          }}</span></mat-checkbox
        >
        <button
          class="mb-2 ml-1"
          [attr.data-testcafe]="getTestcafeId(note, 'note')"
          mat-button
          color="primary"
          (click)="onOpenNote(note, note.id)"
        >
          <kf-icon [name]="note.type === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"></kf-icon>
          <span>{{ truncateText(note.name, 20) }}</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngFor="let note of allNotes.notesByPageReference">
      <div class="d-flex align-items-center">
        <mat-checkbox
          class="mb-2"
          [checked]="note.noteIncludeInResult"
          [attr.data-testcafe]="'sideNotesMenu-checkbox-note-Agenda'"
          (change)="handleIncludeExcludeNote(note, note.noteId, note.noteIncludeInResult)"
          [color]="'grey'"
        >
          <span class="sr-only">{{
            truncateText(topicOverviewNote?.pageReference | pageReference, 25) +
              ' ' +
              (note.noteIncludeInResult ? 'abwählen' : 'auswählen')
          }}</span>
        </mat-checkbox>
        <button
          class="mb-2 ml-1"
          *ngIf="filterPageReference() === noteContextEnum.topicSelection"
          [attr.data-testcafe]="getTestcafeId(note, 'note')"
          mat-button
          color="primary"
          (click)="onOpenNote(note, note.noteId)"
        >
          <kf-icon
            [name]="note.noteType === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"
          ></kf-icon>
          <span>{{ truncateText(topicOverviewNote?.pageReference | pageReference, 25) }}</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngFor="let topic of allNotes.notesByTopic">
      <div *ngFor="let note of topic.notes">
        <div class="d-flex align-items-center">
          <mat-checkbox
            class="mb-2"
            [checked]="note.noteIncludeInResult"
            [attr.data-testcafe]="'sideNotesMenu-checkbox-note-' + note.questionGroupName"
            (change)="handleIncludeExcludeNote(note, note.noteId, note.noteIncludeInResult)"
            [color]="'grey'"
          >
            <span class="sr-only">{{
              truncateText(note.questionGroupName, 25) + ' ' + (note.noteIncludeInResult ? 'abwählen' : 'auswählen')
            }}</span>
          </mat-checkbox>
          <button
            class="mb-2 ml-1"
            [attr.data-testcafe]="getTestcafeId(note, 'note')"
            mat-button
            color="primary"
            (click)="onOpenNote(note, note.noteId)"
          >
            <kf-icon
              [name]="note.noteType === noteTypeEnum.drawing ? 'ic_annotation_zeichnen_24' : 'ic_notiz_24'"
            ></kf-icon>
            <span>{{ truncateText(note.questionGroupName, 25) }}</span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="d-flex justify-content-between">
    <div class="mb-2 vr-text-large font-weight-bold text-truncate">
      <span *ngIf="consultantDocuments?.length > 0">Dokumente</span>
    </div>
  </div>
  <ng-container>
    <ng-container *ngFor="let document of consultantDocuments">
      <div class="d-flex align-items-center">
        <mat-checkbox
          class="mb-2"
          [checked]="document.includeInResult"
          [attr.data-testcafe]="'sideNotesMenu-checkbox-document-' + document.filename"
          (change)="handleIncludeExcludeDocument(document)"
          [color]="'grey'"
        >
          <span class="sr-only">{{
            truncateText(document.filename, 20) + ' ' + (document.includeInResult ? 'abwählen' : 'auswählen')
          }}</span>
        </mat-checkbox>

        <button
          class="mb-2 ml-1"
          [attr.data-testcafe]="getTestcafeId(document, 'document')"
          mat-button
          color="primary"
          (click)="onOpenDocument(document)"
        >
          <kf-icon [name]="document.filename.includes('.pdf') ? 'ic_pdf_24' : 'ic_annotation_bild_24'"></kf-icon>
          <span>{{ truncateText(document.filename, 20) }}</span>
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
