<div class="kf-popup">
  <div [class]="data.showWarningIcon ? 'kf-popup-header-icon' : 'kf-popup-header'">
    <button
      class="kf-popup-close"
      mat-icon-button
      matDialogClose
      aria-label="Dialog schließen"
      kfTooltip="Klicken oder ESC drücken, um zu schließen"
      kfTooltipPosition="before"
      kfTooltipShow="hover_focus"
    >
      <kf-icon name="ic_schliessen_24"></kf-icon>
    </button>

    <kf-icon class="kf-popup-icon" name="ic_error_96" *ngIf="data.showWarningIcon"></kf-icon>
  </div>
  <div class="kf-popup-container">
    <h1>{{ data.headingText ? data.headingText : 'Hinweis' }}</h1>
    <div class="mat-body-1">
      {{ data.copyText }}
    </div>
    <div class="kf-popup-buttons">
      <button
        [attr.data-testcafe]="'dialogConfirm-button-no'"
        (click)="onSelect(false)"
        color="primary"
        mat-stroked-button
      >
        {{ data.denyText }}
      </button>
      <button
        *ngIf="data.confirmText"
        [attr.data-testcafe]="'dialogConfirm-button-yes'"
        (click)="onSelect(true)"
        color="primary"
        mat-flat-button
      >
        {{ data.confirmText }}
      </button>
    </div>
  </div>
</div>
