<aside
  class="d-flex flex-column app-height align-items-center bg-white overflow-hidden task-container-width"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
>
  <div class="d-flex flex-column flex-grow-1 flex-scroll w-100">
    <header class="d-flex w-100 justify-content-between side-header">
      <div class="d-flex flex-grow-1 align-items-center">
        <div class="d-flex align-items-center">
          <button
            *ngIf="state !== taskState.default || inputValues.sideOpen"
            [attr.data-testcafe]="'sideTaskIndiv-button-back'"
            (click)="inputValues.sideOpen ? doAction('recommendation-extra', 'details-closed') : goToDefault()"
            aria-label="Ansicht schließen"
            class="mr-2"
            kfTooltip="Ansicht schließen"
            kfTooltipPosition="before"
            kfTooltipShow="hover_focus"
            mat-icon-button
          >
            <kf-icon name="ic_chevron_links_24"></kf-icon>
          </button>

          <div class="d-flex flex-column">
            <ng-container *ngIf="!taskData?.name">
              <h2 class="text-truncate vr-headline-200 mb-0 side-heading">Individuelle Maßnahme</h2>
            </ng-container>
            <ng-container *ngIf="taskData?.name">
              <h2
                class="text-truncate vr-headline-200 mb-0 side-heading"
                [kfTooltip]="taskData?.name.length > 50 ? taskData.name : null"
                kfTooltipPosition="below"
                kfTooltipShow="hover_focus"
              >
                {{ taskData?.name }}
              </h2>
            </ng-container>
            <ng-container *ngIf="state === taskState.contact">
              <p class="text-truncate mb-0">Neuer Ansprechpartner</p>
            </ng-container>
            <ng-container *ngIf="state === taskState.datepick">
              <p class="text-truncate mb-0">Fälligkeit wählen</p>
            </ng-container>
          </div>
        </div>
      </div>
    </header>

    <ng-container *ngIf="!!taskData || inputValues.taskId === '-1'; else loading">
      <ng-container *ngIf="state === taskState.default">
        <ng-template *ngTemplateOutlet="default"></ng-template>
      </ng-container>
      <ng-container *ngIf="state === taskState.contact">
        <ng-template *ngTemplateOutlet="contact"></ng-template>
      </ng-container>
      <ng-container *ngIf="state === taskState.datepick">
        <ng-template *ngTemplateOutlet="datepick"></ng-template>
      </ng-container>
    </ng-container>
  </div>

  <button
    *ngIf="state === taskState.default && !inputValues.sideOpen"
    [attr.data-testcafe]="'sideTaskIndiv-button-close'"
    class="kf-popup-close"
    mat-icon-button
    aria-label="Panel schließen"
    (click)="handleClose()"
    kfTooltip="Klicken oder ESC drücken, um zu schließen"
    kfTooltipPosition="before"
    kfTooltipShow="hover_focus"
  >
    <kf-icon name="ic_schliessen_24"></kf-icon>
  </button>
</aside>

<!-- ---------------------------------------------- -->
<!-- -------------- DEFAULT TEMPLATE -------------- -->
<!-- ---------------------------------------------- -->
<ng-template #default>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="taskForm" class="w-100 d-flex flex-column">
        <div class="mt-3">
          <mat-form-field class="notranslate" [formGroup]="taskForm">
            <mat-label>Titel</mat-label>
            <input
              ngDefaultControl
              [formControl]="taskFormControls.name"
              matInput
              type="text"
              (change)="onTaskName(taskFormControls.name.value)"
            />
            <mat-error>
              <kf-input-error
                *ngIf="taskFormControls.name.invalid"
                [message]="getFieldErrorMessage(taskFormControls.name, 'name')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- ------ CONTACT SELECTION ------ -->
        <div class="d-flex align-items-center mb-3">
          <ng-container *ngIf="contactsList.length === 0">
            <button
              [attr.data-testcafe]="'sideTaskIndiv-button-addConsultant'"
              mat-stroked-button
              color="primary"
              (click)="state = taskState.contact"
            >
              <kf-icon name="ic_pluszeichen_24"></kf-icon>
              <span>Ansprechpartner</span>
            </button>
          </ng-container>

          <ng-container *ngIf="contactsList.length > 0">
            <div class="d-flex flex-column w-100">
              <mat-form-field required>
                <mat-label>Ansprechpartner</mat-label>
                <mat-select
                  #select
                  kfSelectMdc
                  (selectionChange)="onContactSelected($event)"
                  [formControl]="taskFormControls.customerContact"
                  placeholder="Ansprechpartner auswählen"
                >
                  <mat-option *ngFor="let option of contactsList" [value]="option.value">{{ option.label }}</mat-option>
                </mat-select>
                <mat-error>
                  <kf-input-error
                    *ngIf="taskFormControls.customerContact.invalid"
                    [message]="getFieldErrorMessage(taskFormControls.customerContact, 'customerContact')"
                  >
                  </kf-input-error>
                </mat-error>
              </mat-form-field>

              <div class="d-flex justify-content-end">
                <button
                  [attr.data-testcafe]="'sideTaskIndiv-button-addConsultant'"
                  mat-stroked-button
                  color="primary"
                  (click)="state = taskState.contact"
                >
                  <kf-icon name="ic_pluszeichen_24"></kf-icon>
                  <span>Ansprechpartner</span>
                </button>
              </div>
            </div>
          </ng-container>
        </div>

        <!-- ------ COMMENT SELECTION ------ -->
        <mat-form-field class="notranslate" [formGroup]="taskForm">
          <mat-label>Notiz</mat-label>
          <div class="kf-field-char-counter" aria-hidden="true">{{ taskSendObj.individualNote?.length || 0 }}/4000</div>
          <textarea
            #textArea
            ngDefaultControl
            [(ngModel)]="taskSendObj.individualNote"
            formControlName="individualNote"
            matInput
            maxLength="4000"
            cdkTextareaAutosize
            (change)="onTaskCommentAdded(textArea.value)"
          ></textarea>
        </mat-form-field>

        <div class="d-flex flex-column justify-content-between">
          <div>
            <!-- ------ APPOINTMENT SELECTION ------ -->
            <div class="d-flex align-items-center">
              <kf-icon name="ic_kalender_termin_24" class="mr-2"></kf-icon>
              <div class="font-weight-bold">Fälligkeitsdatum</div>
            </div>
            <ng-container *ngIf="appointmentDate">
              <div class="d-flex flex-column mt-2">
                <div class="d-flex">
                  <button
                    [attr.data-testcafe]="'sideTaskIndiv-button-newDate'"
                    mat-stroked-button
                    color="primary"
                    (click)="onDatepickUpdate()"
                  >
                    <span>{{ appointmentDateString }}</span>
                  </button>

                  <button
                    class="ml-2"
                    [attr.data-testcafe]="'sideTaskIndiv-button-close'"
                    (click)="onDatepickDeleted()"
                    aria-label="Fälligkeitsdatum löschen"
                    kfTooltip="Fälligkeitsdatum löschen"
                    kfTooltipPosition="after"
                    kfTooltipShow="hover_focus"
                    mat-icon-button
                  >
                    <kf-icon name="ic_loeschen_24"></kf-icon>
                  </button>
                </div>
                <div *ngIf="isInvalidDate" class="d-flex mt-2 align-items-center vr-color-error">
                  <kf-icon name="ic_warnschild_24" class="mr-2"></kf-icon>
                  Datum muss in der Zukunft liegen.
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="!appointmentDate || appointmentDate === null">
              <div class="w-100 d-flex justify-content-end">
                <button
                  class="mt-2"
                  [attr.data-testcafe]="'sideTaskIndiv-button-date'"
                  mat-stroked-button
                  color="primary"
                  (click)="onDatepickSelected()"
                >
                  <kf-icon name="ic_pluszeichen_24"></kf-icon>
                  <span>Fälligkeit festlegen</span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- ------ EMAIL SELECTION ------ -->
        <div class="d-flex font-weight-bold mt-3">
          <mat-checkbox
            kfFocus
            #mailCheckbox
            [checked]="notifyMailStatus"
            [attr.data-testcafe]="'sendMail'"
            (change)="onEmailNotificationSelected(mailCheckbox.checked)"
            >Hinweis per E-Mail versenden
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>
  <!-- ------ FINAL SELECTION ------ -->
  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content flex-md-nowrap">
        <button
          [attr.data-testcafe]="getButtonLabel ? 'sideTaskIndiv-button-changeTask' : 'sideTaskIndiv-button-createTask'"
          [disabled]="buttonDisabled"
          mat-flat-button
          color="primary"
          (click)="onFinalizeTask()"
        >
          <span>{{ getButtonLabel ? 'Maßnahme ändern' : 'Maßnahme anlegen' }}</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- --------------------------------------------- -->
<!-- ------------- DATEPICK TEMPLATE ------------- -->
<!-- --------------------------------------------- -->
<ng-template #datepick>
  <div class="d-flex flex-column side-container">
    <div class="d-flex flex-column">
      <div class="border appointment-details">
        <mat-calendar
          [selected]="appointmentDate"
          [minDate]="minimumDate"
          (selectedChange)="onDateSelect($event)"
          color="primary"
        ></mat-calendar>
      </div>
      <ng-template #timeCancelButton>
        <button class="time-button" [attr.data-testcafe]="'sideTaskIndiv-button-abort'">Abbrechen</button>
      </ng-template>
      <ng-template #timeConfirmButton>
        <button class="time-button" [attr.data-testcafe]="'sideTaskIndiv-button-confirm'">OK</button>
      </ng-template>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <div class="button-row-content flex-md-nowrap">
        <button
          [attr.data-testcafe]="'sideTaskIndiv-button-abortAppointment'"
          mat-stroked-button
          color="primary"
          (click)="onAbortAppointment()"
        >
          <span>Abbrechen</span>
        </button>

        <button
          [attr.data-testcafe]="'sideTask-button-suggestAppointment'"
          [disabled]="!this.appointmentDate"
          mat-flat-button
          color="primary"
          (click)="onAppointmentConfirmed()"
        >
          <span>Fälligkeit festlegen</span>
        </button>
      </div>
    </div>
  </footer>
</ng-template>

<!-- ------ NEW CONTACT ------ -->
<ng-template #contact>
  <div class="d-flex flex-column side-container">
    <div class="d-flex justify-content-between align-items-start">
      <div [formGroup]="contactForm" class="w-100 d-flex flex-column">
        <mat-form-field class="notranslate" class="w-100">
          <mat-label>Anrede</mat-label>
          <mat-select
            #select
            ngDefaultControl
            kfSelectMdc
            placeholder="Anrede auswählen"
            [formControl]="contactFormControls.formOfAddress"
          >
            <mat-option *ngFor="let option of contactsFormOfAddress" [value]="option.value">
              {{ option.label }}
            </mat-option>
          </mat-select>
          <mat-error>
            <kf-input-error
              *ngIf="contactFormControls.formOfAddress.invalid"
              [message]="getFieldErrorMessage(contactFormControls.formOfAddress, 'formOfAddress')"
            >
            </kf-input-error>
          </mat-error>
        </mat-form-field>
        <div class="d-flex justify-content-between">
          <div class="w-50 mr-3">
            <mat-form-field class="notranslate">
              <mat-label>Vorname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.firstName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.firstName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.firstName, 'firstName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="w-50 ml-3">
            <mat-form-field class="notranslate">
              <mat-label>Nachname</mat-label>
              <input ngDefaultControl [formControl]="contactFormControls.lastName" matInput type="text" />
              <mat-error>
                <kf-input-error
                  *ngIf="contactFormControls.lastName.invalid"
                  [message]="getFieldErrorMessage(contactFormControls.lastName, 'lastName')"
                >
                </kf-input-error>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div>
          <mat-form-field class="notranslate">
            <mat-label>E-Mail-Adresse</mat-label>
            <input
              ngDefaultControl
              [formControl]="contactFormControls.email"
              matInput
              type="text"
              placeholder="Gültige E-Mail-Adresse wie z.B. name.vorname@domain.de"
            />
            <mat-hint> Die E-Mail-Adresse wird z.B. für den Terminhinweis genutzt. </mat-hint>
            <mat-error>
              <kf-input-error
                *ngIf="contactFormControls.email.invalid"
                [message]="getFieldErrorMessage(contactFormControls.email, 'email')"
              >
              </kf-input-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <footer class="w-100 border-top align-items-center side-footer" [class.side-footer-margin]="useMargin">
    <div class="h-100 button-row">
      <button
        [attr.data-testcafe]="'sideTaskIndiv-button-abort'"
        mat-stroked-button
        color="primary"
        (click)="goToDefault()"
      >
        <span>Abbrechen</span>
      </button>

      <button
        [attr.data-testcafe]="'sideTaskIndiv-button-addContact'"
        [disabled]="!contactForm.valid"
        mat-flat-button
        color="primary"
        (click)="onContactAdded()"
      >
        <kf-icon name="ic_pluszeichen_24"></kf-icon>
        <span>Ansprechpartner hinzufügen</span>
      </button>
    </div>
  </footer>
</ng-template>

<ng-template #loading>
  <mat-progress-spinner [color]="'primary'" mode="indeterminate" class="m-auto" [diameter]="90"></mat-progress-spinner>
</ng-template>
