<ng-container *ngIf="transitionData?.length === 0 || !transitionData">
  <section class="result-transitions border h-100">
    <div class="pl-4 pr-3 pt-3">
      <div class="pl-2">
        <strong>Expertengespräche</strong>
        <p>Für diese Beratung wurden keine Expertengespräche terminiert.</p>
      </div>
    </div>
  </section>
</ng-container>

<section class="result-transitions border h-100" *ngIf="transitionData?.length > 0">
  <!-- outer panel -->
  <mat-expansion-panel
    class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
    collapsedHeight="*"
    expandedHeight="*"
    [hideToggle]="false"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [class.is--closed]="!panelOpenState"
    #transitionsPanel
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="text-nowrap" [attr.data-testcafe]="'screenResult-panel-transitionHeader'"
        ><strong class="font-weight-bold">Expertengespräche</strong></mat-panel-title
      >
    </mat-expansion-panel-header>

    <div>
      <div *ngFor="let transition of transitionData; index as i">
        <mat-expansion-panel
          class="w-100 mat-elevation-z0 rounded-0 kf-after-bg-grey-200"
          [expanded]="activeTransitionIndex === i"
        >
          <mat-expansion-panel-header (click)="closeDetailPanel(i)">
            <div class="d-flex flex-column w-100">
              <mat-panel-title class="row justify-content-between mt-2">
                <div class="col-sm-8 order-2 order-sm-1 mb-2">
                  <strong class="font-weight-bold">{{ transition.name }}</strong>
                </div>
                <!-- email sent -->
                <ng-container *ngIf="transition.mailSent">
                  <div class="col-sm-4 d-flex justify-content-sm-end order-1 order-sm-2">
                    <div class="tag-info tag-positive mr-3 mb-2">
                      <div class="tag-info-text">E-Mail versendet</div>
                    </div>
                  </div>
                </ng-container>
              </mat-panel-title>

              <div class="row align-items-center mt-2">
                <div class="col">
                  <!-- expert contact -->
                  <div
                    *ngIf="transition.expertContact"
                    class="d-flex align-items-start"
                    [attr.data-testcafe]="'screenResult-div-transition-expertContact'"
                  >
                    <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                    <span class="pl-2">
                      {{ transition.expertContact.formOfAddress }} {{ transition.expertContact.firstName }}
                      {{ transition.expertContact.lastName }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <!-- panel content -->

          <!-- appointment -->
          <ng-container *ngIf="transition.appointments">
            <div class="row">
              <div class="col">
                <p class="w-100 font-weight-bold mb-0" *ngIf="transition!.appointments.length > 0">
                  {{ transition!.appointments.length === 1 ? 'Terminvorschlag' : 'Terminvorschläge' }}
                </p>
                <div
                  class="d-flex w-100 mt-2 align-items-start"
                  *ngFor="let appointment of transition.appointments"
                  [attr.data-testcafe]="'screenResult-div-transitionAppointment'"
                >
                  <kf-icon name="ic_kalender_termin_24" class="flex-shrink-0"></kf-icon>
                  <span class="pl-2">
                    <span>{{ appointment.appointmentStart | date: 'dd. MMMM YYYY, HH:mm' }} Uhr</span>
                    -
                    <span>{{ appointment.appointmentEnd | date: 'dd. MMMM YYYY, HH:mm' }} Uhr</span>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- expert contact/ customer contact -->
          <ng-container *ngIf="transition.expertContact || transition.customerContact">
            <div class="row">
              <div *ngIf="transition.expertContact" class="col-12 col-sm-6 mt-3">
                <p class="w-100 font-weight-bold mb-0">Experte</p>
                <div class="w-100 d-flex align-items-start mt-2">
                  <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                  <span class="pl-2">
                    <ng-template *ngTemplateOutlet="person; context: { person: transition.expertContact }">
                    </ng-template>
                  </span>
                </div>
              </div>

              <!-- customer contact -->
              <div *ngIf="transition.customerContact" class="col-12 col-sm-6 mt-3">
                <p class="w-100 font-weight-bold mb-0">Ansprechpartner</p>
                <div class="w-100 d-flex align-items-start mt-2">
                  <kf-icon name="ic_person_mit_kreis_24" class="flex-shrink-0"></kf-icon>
                  <span class="pl-2">
                    <ng-template *ngTemplateOutlet="person; context: { person: transition.customerContact }">
                    </ng-template>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>

          <!-- individual notes -->
          <ng-container *ngIf="transition.individualNote">
            <div class="row mt-3">
              <div
                *ngIf="transition.individualNote"
                class="col"
                [attr.data-testcafe]="'screenResult-div-transitionNotes'"
              >
                <p class="w-100 font-weight-bold mb-0">Notizen zum Expertengespräch</p>
                <p>{{ transition.individualNote }}</p>
              </div>
            </div>
          </ng-container>

          <!-- datafield groups -->
          <ng-container *ngIf="transition.dataFieldGroups?.length > 0">
            <div class="row">
              <div class="col">
                <p class="w-100 font-weight-bold mb-0">Datengrundlage</p>
              </div>
            </div>
            <div *ngFor="let dataFieldGroup of transition.dataFieldGroups" class="row">
              <div class="col">
                <p class="w-100 font-weight-bold mb-0">{{ dataFieldGroup.name }}</p>
                <ng-container *ngFor="let dataField of dataFieldGroup.dataFields">
                  <dl class="mb-1 row">
                    <dt class="col-6 font-weight-normal dataField-term">
                      {{ dataField.name }}
                    </dt>
                    <dd class="col-6 mb-0 dataField-value" *ngIf="dataField.value">
                      {{ dataField.value }}
                    </dd>
                    <dd class="col-6 mb-0 dataField-value" *ngIf="!dataField.value">-</dd>
                  </dl>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <!-- attached documents -->
          <ng-container *ngIf="filterDocuments(transition.documents) > 0">
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                <p class="font-weight-bold mb-2">Dokumente zum Expertengespräch</p>
                <ul class="list-unstyled">
                  <li *ngFor="let document of filteredDocuments">
                    <kf-document-tile
                      [titletext]="document.name"
                      [documentType]="document.type"
                      [disabled]="loading"
                      (handleClick)="openDocument(document.url)"
                      [attr.data-testcafe]="'screenResult-button-transitionDocuments-' + getTestcafeId(document.name)"
                      size="small"
                    >
                      <kf-icon [name]="documentTypeAsIcon(document.type, 48)"></kf-icon>
                    </kf-document-tile>
                  </li>
                </ul>
              </div>
            </div>
          </ng-container>

          <!-- / panel content -->
        </mat-expansion-panel>
      </div>
    </div>
  </mat-expansion-panel>
  <!-- / outer panel -->
  <ul class="w-100 list-inline mb-0 pr-4 py-2 pl-4" *ngIf="!panelOpenState">
    <li *ngFor="let transition of transitionData; let last = last; index as i" class="list-inline-item">
      <div class="d-flex line-height-1">
        <button mat-button color="primary" (click)="openTransition(i); $event.preventDefault()">
          <span>{{ transition.name }}</span>
        </button>
      </div>
    </li>
  </ul>
</section>

<!-- person template for customer and expert-->
<ng-template #person let-person="person">
  <div class="d-flex flex-column">
    <span class="mb-1">
      {{ person.formOfAddress }}
      {{ person.firstName }}
      {{ person.lastName }}
    </span>
    <div *ngIf="person.email">
      E-Mail: <a [href]="'mailto:' + person.email">{{ person.email }}</a>
    </div>
    <span *ngIf="person.department">Abteilung: {{ person.department }}</span>
    <span *ngIf="person.position">Position: {{ person.position }}</span>
  </div>
</ng-template>
