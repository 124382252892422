<ng-container *ngIf="!!src">
  <img
    *ngIf="!isKfImage"
    [src]="src"
    [alt]="alt"
    [attr.width]="width || null"
    [attr.height]="height || null"
    (load)="onImageLoad($event)"
  />
  <kf-img
    *ngIf="isKfImage"
    [src]="kfImageUrl"
    [width]="width"
    [height]="height"
    [selfHosted]="environment.isLocal"
  ></kf-img>
</ng-container>

<ng-container *ngIf="!src && imageMulti.length === 0 && image?.url && !imageError">
  <button
    #element
    tabindex="0"
    class="question-logo-small mb-4 position-relative"
    [ngStyle]="!logoExpanded && { width: width, height: 'auto' }"
    [class.question-logo-big]="logoExpanded"
    [disabled]="!resizable"
    (keyup)="handleKeyup($event, element)"
    (click)="onResizeClick($event)"
  >
    <div *ngIf="imageLoaded">
      <div
        *ngIf="resizable"
        [ngClass]="{ expanded: logoExpanded }"
        class="image-button position-absolute image-logo-wrapper"
      >
        <div class="image-logo-container justify-content-center align-items-center">
          <kf-icon name="ic_fenster_vergroessern_24"></kf-icon>
        </div>
      </div>
    </div>
    <img
      *ngIf="!isKfImage"
      class="question-logo-content"
      [src]="singleImageUrl"
      [alt]="image?.altText || ''"
      (load)="onImageLoad($event)"
      (error)="onImageError($event)"
    />
    <ng-container *ngIf="isKfImage">
      <kf-img class="question-logo-content" [src]="kfImageUrl" [selfHosted]="environment.isLocal"></kf-img>
    </ng-container>
  </button>
</ng-container>

<ng-container *ngIf="!src && imageMulti.length > 0">
  <div class="w-100" [style.height]="enlarged && 'auto'">
    <div
      class="d-flex caroussel-container position-relative"
      [style.width]="!enlarged ? width : enlargeOptions.endWidth"
      [style.height]="!enlarged ? height : enlargeOptions.endHeight"
    >
      <button
        #element
        tabindex="{{ enlarged && -1 }}"
        [class.enlarged]="enlarged"
        (click)="changeActiveIndex(false)"
        (keyup)="handleKeyup($event, element)"
        class="d-flex align-items-center chevron-area justify-content-around left"
        kfTooltip="Vorheriges Bild"
        kfTooltipPosition="after"
        kfTooltipShow="hover_focus"
      >
        <kf-icon name="ic_chevron_links_24"></kf-icon>
      </button>
      <button
        #element
        class="images-container d-flex justify-content-center position-relative"
        [class.enlarged]="enlarged"
        [class.cursor-zoom-in]="enlargeable && !enlarged"
        [class.cursor-zoom-out]="enlargeable && enlarged"
        (click)="enlargeable && (enlarged = !enlarged)"
        (keyup)="handleKeyup($event, element)"
        [kfTooltip]="enlarged ? 'Verkleinern' : 'Vergrößern'"
        kfTooltipPosition="after"
        kfTooltipShow="hover_focus"
      >
        <div
          *ngIf="showIcon"
          [class.expanded]="enlargeable && enlarged"
          class="image-logo-container multi justify-content-center align-items-center image-logo-wrapper position-absolute"
        >
          <kf-icon name="ic_fenster_vergroessern_24"></kf-icon>
        </div>

        <div
          *ngIf="showIcon"
          [class.expanded]="enlargeable && !enlarged"
          class="image-logo-container multi justify-content-center align-items-center image-logo-wrapper position-absolute"
        >
          <kf-icon name="ic_fenster_verkleinern_24"></kf-icon>
        </div>

        <!-- Full-width images with number and caption text -->
        <div
          *ngIf="!isKfImage"
          [@simpleSlideAnimation]="animationIndex"
          [@simpleFadeAnimation]="animationIndex"
          class="mySlides"
          [style.backgroundImage]="'url(' + multiImageUrl(slideIndex) + ')'"
        ></div>
        <ng-container *ngIf="isKfImage">
          <kf-img
            [@simpleSlideAnimation]="animationIndex"
            [@simpleFadeAnimation]="animationIndex"
            class="mySlides"
            [src]="kfImageUrl"
            [selfHosted]="environment.isLocal"
          ></kf-img>
        </ng-container>
        <!-- Next and previous buttons -->
      </button>
      <button
        #element
        tabindex="{{ enlarged && -1 }}"
        [class.enlarged]="enlarged"
        (click)="changeActiveIndex(true)"
        (keyup)="handleKeyup($event, element)"
        class="d-flex align-items-center chevron-area justify-content-around right"
        kfTooltip="Nächstes Bild"
        kfTooltipPosition="before"
        kfTooltipShow="hover_focus"
      >
        <kf-icon name="ic_chevron_rechts_24"></kf-icon>
      </button>
    </div>
  </div>
</ng-container>

<ng-template #noImage>
  <div>Es wurde keine gültige Bild-URL angegeben.</div>
</ng-template>
